import { DaDataAddressSuggestion } from "react-dadata";
import { transformToAddressInputType } from "common/helpers/transformDaData";
import { CandidateManualInputType, Source } from "graphql/types/types";
export interface CreateCandidateForm {
  phone: string;
  secondName?: string;
  firstName: string;
  patronymic?: string;
  actualAddress: DaDataAddressSuggestion | undefined;
  positions?: CandidateManualInputType["positions"];
  source: Source;
}

export function createCandidatePayload(data: CreateCandidateForm) {
  return {
    phone: data.phone,
    secondName: data.secondName,
    firstName: data.firstName,
    patronymic: data.patronymic,
    actualAddress: transformToAddressInputType(data.actualAddress),
    positions: data.positions,
    source: data.source,
  };
}

import { isVacancyPage, RouteValue } from "common/helpers/pages";
import { parseQuery } from "common/utils/query";
import { ROUTE } from "routes";

export const getPathnameWithoutId = () => {
  return window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf("/")
  );
};

export const getIsSpecificPage = () => {
  const path = window.location.pathname;
  const regex =
    /^\/(vacancies|replies|claims|control|candidates|vacancy|vacancies-view|reports|replies-view|admin-setting)(\/\d+)?$/;

  return regex.test(path);
};

type RouteGroups = {
  [key in RouteValue]?: RouteValue[];
};

const ROUTE_GROUPS: RouteGroups = {
  [ROUTE.CANDIDATES]: [ROUTE.CANDIDATES, ROUTE.CANDIDATE, ROUTE.RECOMMENDED],
  [ROUTE.VACANCIES]: [ROUTE.VACANCIES, ROUTE.VACANCY],
  [ROUTE.REPLIES]: [ROUTE.REPLIES, ROUTE.REPLY, ROUTE.CLAIM],
  [ROUTE.ADAPTATION_LIST]: [ROUTE.ADAPTATION_LIST, ROUTE.ADAPTATION],
  [ROUTE.COLD_SEARCH]: [ROUTE.COLD_SEARCH_VACANCY, ROUTE.COLD_SEARCH],
  [ROUTE.REACTIVATION_VACANCIES]: [
    ROUTE.REACTIVATION_VACANCIES,
    ROUTE.REACTIVATION_VACANCY,
  ],
};

export const getActiveMenuKey = (
  pathname: string,
  search: string
): RouteValue | string => {
  const { fromReactivationVacancies } = parseQuery(search);

  if (fromReactivationVacancies && isVacancyPage(pathname as RouteValue)) {
    return ROUTE.REACTIVATION_VACANCIES;
  }

  const foundEntry = Object.entries(ROUTE_GROUPS).find(([_, routes]) =>
    routes?.some((route) => pathname.startsWith(route))
  );

  return foundEntry ? foundEntry[0] : pathname;
};

import * as Yup from "yup";

export const schema = Yup.object().shape({
  password: Yup.string()
    .required("Необходимо ввести пароль")
    .min(6, "Длина пароля должна быть не меньше 6"),
  confirmPassword: Yup.string()
    .required("Необходимо ввести пароль")
    .min(6, "Длина пароля должна быть не меньше 6")
    .test("passwords-match", "Пароли должны совпадать", function (value) {
      return this.parent.password === value;
    }),
});

import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { useAppDispatch } from "services/store/store";
import { ColdSearch } from "components/redezign/ColdSearch";
import { fetchDailyPlanList } from "services/store/modules/vacanciesList/actions";

export const ColdSearchPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDailyPlanList({ date: new Date() }));
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: <ColdSearch />,
      }}
    />
  );
};

import React from "react";
import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IUser } from "services/store/modules/user/types";
import { ROLE } from "common/const/role";
import { Title } from "components/UI/Title";
import { getFullName } from "common/helpers/string";
import { ProfileField } from "./helpers";

import "./styles.scss";

interface Props {
  user: IUser;
}

export const ProfileCard: React.FC<Props> = ({ user }) => {
  const fields = [
    { label: "ID:", value: user.id || "Не указан" },
    { label: "Компания:", value: user.company?.name || "Не указана" },
    { label: "Департамент:", value: user.department?.name || "Не указан" },
    {
      label: "Телефон:",
      value: <a href={`tel:${user.phone}`}>{user.phone}</a>,
    },
    {
      label: "Эл.почта:",
      value: <a href={`mailto:${user.email}`}>{user.email}</a>,
    },
    { label: "Город:", value: user.city },
  ];
  return (
    <Card className="profile-card">
      <div className="profile-card-avatar">
        <Avatar size={100} icon={<UserOutlined />} />
      </div>
      <Title type="h2">{getFullName(user)}</Title>
      <p className="profile-card-role">{ROLE[user.role]}</p>
      <div className="profile-card-fields">
        {fields.map((field, index) => (
          <ProfileField key={index} label={field.label}>
            {field.value}
          </ProfileField>
        ))}
      </div>
    </Card>
  );
};

import { ReplyNewClientType, VacancyType } from "graphql/types/types";
import { IUserByID } from "services/store/modules/user/types";
import { DisabledVacancies } from "services/store/modules/vacanciesList/types";

type Props = {
  id: VacancyType["id"] | ReplyNewClientType["id"];
  disabledList: DisabledVacancies;
  ownerId: IUserByID["ID"];
};
export const getTableRowDisabled = ({ id, disabledList, ownerId }: Props) => {
  const disabledListById = disabledList[id];

  return disabledListById ? disabledListById.ownerId !== ownerId : false;
};

import React, { useMemo, useState, useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames/dedupe";

import { updateCandidate } from "services/store/modules/candidate/actions";
import { useAppDispatch } from "services/store/store";
import RowLayout from "layouts/RowLayout";
import { Button } from "components/UI/Button";
import { Edit } from "components/UI/icons";
import { FormSaveBar } from "components/UI/Form/FormSaveBar";
import { Modal } from "components/UI/Modal";
import { Form } from "components/UI/Form";
import toaster from "components/UI/Notifications/Notification";
import { useBeforeunloadRouteConfirm } from "common/hooks/useBeforeunloadRouteConfirm";
import { CandidateType, CandidateUpdateType } from "graphql/types/types";
import { setCandidate } from "services/store/modules/candidate";
import { updateCandidateInReplyNew } from "services/store/modules/replies";

import CandidateDetails from "./CandidateDetails";
import CandidateContacts from "./CandidateContacts";
import CandidatePosition from "./CandidatePosition";
import CandidateInfo from "./CandidateInfo";
import CandidatePhone from "./CandidatePhone";
import { getFormPayload, getInitialStateForm } from "./helpers";
import { schema } from "./validation";
import { FiltersFormState } from "./types";

import "./styles.scss";

type Props = {
  className?: string;
  disabled?: boolean;
  initialDisabledForm?: boolean;
  candidate?: CandidateType | null;
};

export const FormCandidate: React.FC<Props> = ({
  className,
  candidate,
  disabled = false,
  initialDisabledForm = true,
}) => {
  const dispatch = useAppDispatch();

  const [disabledForm, setDisabledForm] = useState(initialDisabledForm);

  const initialState = useMemo<FiltersFormState>(
    () => getInitialStateForm(candidate),
    [candidate]
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
    setValue,
  } = useForm<FiltersFormState>({
    defaultValues: initialState,
    // @ts-expect-error
    resolver: yupResolver(schema),
  });

  const { isVisible: isModalVisible, closeAndNavigate } =
    useBeforeunloadRouteConfirm({ isFactor: isDirty });

  const changeCandidate = useCallback(
    async (form: CandidateUpdateType, candidateId: string) => {
      await dispatch(updateCandidate({ form, id: candidateId }))
        .unwrap()
        .then((result) => {
          if (!result?.updateCandidate) {
            return toaster.error({ title: "Ошибка сохранения кандидата" });
          }

          dispatch(setCandidate(result.updateCandidate));
          dispatch(updateCandidateInReplyNew(result.updateCandidate));
          toaster.success({ title: "Данные успешно сохранены" });
          closeAndNavigate();
        })
        .catch(() => toaster.error({ title: "Ошибка сохранения кандидата" }))
        .finally(() => setDisabledForm(true));
    },
    [closeAndNavigate, dispatch]
  );

  const createCandidate = async (formData: FiltersFormState) => {
    return Promise.resolve(formData);
  };

  const updateCandidateForm: SubmitHandler<FiltersFormState> = useCallback(
    async (formData) => {
      const form = getFormPayload({
        formData,
      });

      if (candidate) {
        return changeCandidate(form, candidate.id);
      }

      createCandidate(formData);
    },
    [candidate, changeCandidate]
  );

  const cancelCandidateForm = () => {
    reset();
    closeAndNavigate();
    setDisabledForm(true);
  };

  return (
    <Form
      className={cn("form-candidate", className)}
      initialValues={initialState}
      disabled={disabledForm}
    >
      <>
        {disabledForm && (
          <Button
            variant="primary"
            className="form-candidate-btn"
            onClick={() => setDisabledForm(!disabledForm)}
            rightIcon={<Edit width={20} height={20} />}
          >
            Редактировать
          </Button>
        )}
      </>
      <CandidatePhone
        control={control}
        disabledForm={disabledForm || disabled}
        errors={errors}
        setValue={setValue}
      />
      <CandidateInfo
        badge={candidate?.badge ?? "не указан"}
        control={control}
        disabledForm={disabledForm || disabled}
      />
      <CandidateContacts
        control={control}
        disabledForm={disabledForm || disabled}
        errors={errors}
        setValue={setValue}
      />
      <CandidatePosition
        candidate={candidate}
        control={control}
        disabledForm={disabledForm || disabled}
      />
      <CandidateDetails
        control={control}
        disabledForm={disabledForm || disabled}
      />
      {!disabledForm && (
        <FormSaveBar
          className="form-candidate-save-bar"
          onSave={handleSubmit(updateCandidateForm)}
          onCancel={cancelCandidateForm}
        />
      )}
      {!disabledForm && isDirty && (
        <Modal
          title="Вы уверены, что хотите закрыть карточку кандидата?"
          okText="Сохранить"
          cancelText="Не сохронять"
          open={isModalVisible}
          onCancel={cancelCandidateForm}
          size="small"
          footer={
            <RowLayout
              justify="start"
              leftSpan={10}
              leftComponent={
                <Button
                  style={{ width: "150px" }}
                  variant="primary"
                  onClick={cancelCandidateForm}
                >
                  Не сохранять
                </Button>
              }
              centerComponent={
                <Button
                  style={{ width: "150px" }}
                  onClick={handleSubmit(updateCandidateForm)}
                >
                  Сохранить
                </Button>
              }
            />
          }
        >
          <p>Внесенные изменения не сохранены.</p>
        </Modal>
      )}
    </Form>
  );
};

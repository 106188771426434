import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Input from "../Input";
import { ChevronBack, ChevronForward } from "../icons";

import "./styles.scss";

export interface Props {
  className?: string;
  total?: number;
  limit?: number;
  offset?: number; // номер страницы
  onChange?: (pagination: PaginationPayload) => void;
}

export type PaginationPayload = {
  offset: number;
  limit: number;
};

export const Pagination: React.FC<Props> = ({
  className,
  total = 0,
  limit = 0,
  offset = 0,
  onChange,
}) => {
  const paginationClassNames = classNames("ui-pagination", className);
  const pages = Math.ceil(total / limit);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState("1");

  const getPaginationPayload = (page: number) => ({
    offset: page * limit,
    limit,
  });

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.persist();

    if (e.key === "Enter") {
      onChange?.(getPaginationPayload(page));

      if (Number(value) <= 0 || value === "-") {
        setValue("1");
      }
    }
  };

  const changePage = (page: number) => {
    setPage(page);
    setValue(String(page + 1));
    onChange?.(getPaginationPayload(page));
  };

  const handleChange = (str: string) => {
    if (Number(str) > pages || Number(str) < 0) {
      return;
    }

    setValue(str);

    if (Number(str) > 0) {
      setPage(Number(str) - 1);
    } else if (Number(str) === 0) {
      setPage(Number(str));
    }
  };

  useEffect(() => {
    if (offset === 0) {
      setValue(String(offset + 1));
      setPage(offset);
    } else {
      setValue(String(offset / limit + 1));
      setPage(offset / limit);
    }
  }, [offset, limit]);

  if (!Boolean(total)) {
    return null;
  }

  return (
    <div className={paginationClassNames}>
      <div className="ui-pagination-container">
        <div
          className={classNames("ui-pagination-icon", {
            "ui-pagination-icon_disabled": page <= 0,
          })}
          onClick={() => changePage(page - 1)}
        >
          <ChevronBack />
        </div>
        <div className="ui-pagination-name">Страница</div>
        <Input
          className="ui-pagination-input"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyPress}
          style={{
            width: value.length > 2 ? `${value.length * 15}px` : "40px",
          }}
        />
        <div className="ui-pagination-suf">из</div>
        <div className="ui-pagination-total">{pages}</div>
        <div
          className={classNames("ui-pagination-icon", {
            "ui-pagination-icon_disabled": page + 1 >= pages,
          })}
          onClick={() => changePage(page + 1)}
        >
          <ChevronForward />
        </div>
      </div>
    </div>
  );
};

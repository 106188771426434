import React, { useMemo } from "react";
import { Button } from "components/UI/Button";
import { IStatus } from "services/store/modules/directory/types";
import {
  ReplyNewClientType,
  ReplyNewStatuses,
  ReplyNewViewStatuses,
  VacancyType,
} from "graphql/types/types";
import { useAppSelector } from "services/store/store";
import { selectRefusalReasons } from "services/store/modules/directory";
import Text from "components/UI/Text";
import { getCandidateStatus } from "common/helpers/status";
import { buildItemsById } from "services/store/helpers";
import { ReplyStatusSelect } from "components/ReplyStatusSelect";

import "./styles.scss";

interface Props {
  vacancyId: VacancyType["id"];
  replyId: ReplyNewClientType["id"];
  statuses: IStatus[];
  status: ReplyNewViewStatuses | ReplyNewStatuses;
  isDisabled?: boolean;
  onVisibilityChange: () => void;
  candidateStatus: string;
}

export const ReplyActionPanel: React.FC<Props> = ({
  statuses,
  status,
  isDisabled,
  onVisibilityChange,
  vacancyId,
  replyId,
  candidateStatus,
}) => {
  const refusalDirectory = useAppSelector(selectRefusalReasons);

  const candidateRefusalItems = useMemo(
    () => buildItemsById(refusalDirectory, "key"),
    [refusalDirectory]
  );

  return (
    <div className="action-panel-container">
      <Button
        className="action-panel-container-btn"
        disabled={isDisabled}
        onClick={onVisibilityChange}
      >
        Взять в работу
      </Button>

      <ReplyStatusSelect
        status={status}
        statuses={statuses}
        vacancyId={vacancyId}
        replyId={replyId}
      />

      {status === ReplyNewStatuses.Processed && (
        <Text
          text={
            <p>
              Статус кандидата:{" "}
              <span className="action-panel-container-candidate-status-color">
                {getCandidateStatus(candidateStatus, candidateRefusalItems)}
              </span>
            </p>
          }
          className="action-panel-container-candidate-status"
        />
      )}
    </div>
  );
};

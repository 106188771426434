import React from "react";
import { useHistory } from "react-router-dom";

import { DICTIONARY_DATA, AREAS } from "common/const/dictionary";
import { ROUTE } from "routes";
import { Card } from "components/UI/Card";
import { Report } from "components/UI/icons";

import "./styles.scss";

export const SystemSetting: React.FC = () => {
  const history = useHistory();

  const onCardDictionaryClick = (type: string) => {
    if (type === AREAS.POSITIONS_MAP) {
      return history.push(ROUTE.SETTINGS_POSITIONS_MAP);
    }
    history.push(`/settings/dictionary/${type}`);
  };

  return (
    <div className="system-setting">
      {DICTIONARY_DATA.map(({ key, name, type }) => (
        <Card
          key={key}
          mode="grid"
          withBorder={true}
          title={name}
          icon={<Report />}
          onClick={() => onCardDictionaryClick(type)}
        />
      ))}
    </div>
  );
};

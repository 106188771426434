import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { ColdSearchVacancy } from "components/redezign/ColdSearchVacancy";
import { RouteParams } from "common/commonTypes/types";
import { fetchVacancy } from "services/store/modules/vacancy/actions";
import {
  selectVacancy,
  selectVacancyData,
} from "services/store/modules/vacancy/selectors";
import { authInfo } from "services/store/modules/auth";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";

export const ColdSearchVacancyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: vacancyId } = useParams<RouteParams>();

  const vacancy = useAppSelector(selectVacancyData);
  const { loading, error } = useAppSelector(selectVacancy);
  const userData = useAppSelector(authInfo);

  useChangeTitle({ title: vacancy?.name || null });

  useEffect(() => {
    dispatch(fetchVacancy({ vacancyId }));
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {vacancy && userData ? (
              <ColdSearchVacancy vacancy={vacancy} userData={userData} />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Вакансия не найдена"
              />
            )}
          </>
        ),
      }}
    />
  );
};

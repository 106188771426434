import { createAsyncThunk } from "@reduxjs/toolkit";

import { client as apollo } from "graphql/apollo";
import { CANDIDATE } from "graphql/requests/query/candidates";
import {
  ChangeCandidateStatusResponse,
  DictionariesResponse,
  UpdateCandidateResponse,
  FetchCandidateResponse,
  ActualStatusesCandidatesResponse,
  CheckCandidateByPhoneResponse,
  CreateCandidateResponse,
} from "./types";
import { AsyncThunkConfig } from "services/store/types";
import {
  CandidateType,
  MutationChangeCandidateStatusArgs,
  MutationCreateCandidateArgs,
  MutationSetupCallArgs,
  MutationUpdateCandidateArgs,
  QueryCheckCandidateByPhoneArgs,
} from "graphql/types/types";
import { CHANGE_CANDIDATE_STATUS } from "graphql/requests/mutation/changeCandidateStatus";
import { UPDATE_CANDIDATE } from "graphql/requests/mutation/updateCandidate";
import { CandidateHistoryResponse, fetchHistoryCandidate } from "api/history";
import { SETUP_CALL } from "graphql/requests/mutation/setupCall";
import { CANDIDATES_DICTIONARIES } from "graphql/requests/query/candidatesDictionaries";
import { ACTUAL_STATUSES_CANDIDATES } from "graphql/requests/query/actualStatusesCandidates";
import { CHECK_CANDIDATE_BY_PHONE } from "graphql/requests/query/checkCandidateByPhone";
import { CREATE_CANDIDATE } from "graphql/requests/mutation/createCandidate";

export const fetchCandidateById = createAsyncThunk<
  FetchCandidateResponse | null | undefined,
  string,
  AsyncThunkConfig
>("candidates/fetchCandidateById", async (id, { rejectWithValue }) => {
  try {
    const res = await apollo.query<FetchCandidateResponse>({
      query: CANDIDATE,
      variables: { id },
    });
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeCandidateStatus = createAsyncThunk<
  ChangeCandidateStatusResponse | null | undefined,
  MutationChangeCandidateStatusArgs,
  AsyncThunkConfig
>("candidate/changeStatus", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<ChangeCandidateStatusResponse>({
      mutation: CHANGE_CANDIDATE_STATUS,
      variables: payload,
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const setupCallCandidate = createAsyncThunk<
  CandidateType,
  MutationSetupCallArgs,
  AsyncThunkConfig
>("candidate/setupCallCandidate", async (payload, thunkAPI) => {
  try {
    const response = await apollo.mutate({
      mutation: SETUP_CALL,
      variables: payload,
    });
    return response.data.setupCall;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateCandidate = createAsyncThunk<
  UpdateCandidateResponse | null | undefined,
  MutationUpdateCandidateArgs,
  AsyncThunkConfig
>("candidate/updateCandidate", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<UpdateCandidateResponse>({
      mutation: UPDATE_CANDIDATE,
      variables: payload,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getCandidateHistory = createAsyncThunk<
  CandidateHistoryResponse,
  string,
  AsyncThunkConfig
>("candidate/getCandidateHistory", async (payload, { rejectWithValue }) => {
  try {
    const response = await fetchHistoryCandidate<CandidateHistoryResponse>(
      payload
    );

    return response.data;
  } catch (err) {
    return rejectWithValue("Ошибка получения отчета");
  }
});

export const fetchCandidateDictionaries = createAsyncThunk<
  DictionariesResponse,
  void,
  AsyncThunkConfig
>("candidates/fetchDictionaries", async (_, { rejectWithValue }) => {
  try {
    const res = await apollo.query<DictionariesResponse>({
      query: CANDIDATES_DICTIONARIES,
    });
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const fetchActualStatusesCandidates = createAsyncThunk<
  ActualStatusesCandidatesResponse,
  void,
  AsyncThunkConfig
>(
  "candidates/fetchActualStatusesCandidates",
  async (_, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ActualStatusesCandidatesResponse>({
        query: ACTUAL_STATUSES_CANDIDATES,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const checkCandidateByPhone = createAsyncThunk<
  CheckCandidateByPhoneResponse | null | undefined,
  QueryCheckCandidateByPhoneArgs,
  AsyncThunkConfig
>("candidate/checkCandidateByPhone", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.query<CheckCandidateByPhoneResponse>({
      query: CHECK_CANDIDATE_BY_PHONE,
      variables: payload,
    });
    return res.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createCandidate = createAsyncThunk<
  CreateCandidateResponse | null | undefined,
  MutationCreateCandidateArgs,
  AsyncThunkConfig
>("candidate/createCandidate", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<CreateCandidateResponse>({
      mutation: CREATE_CANDIDATE,
      variables: payload,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

import React from "react";
import { IconType } from "common/commonTypes/types";

export const Delete: React.FC<IconType> = ({ color = "#000" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 6.66663V15C4.16667 16.8409 5.65905 18.3333 7.5 18.3333H12.5C14.3409 18.3333 15.8333 16.8409 15.8333 15V6.66663M11.6667 9.16663V14.1666M8.33333 9.16663L8.33333 14.1666M13.3333 4.16663L12.1614 2.40879C11.8523 1.94513 11.3319 1.66663 10.7747 1.66663H9.22531C8.66805 1.66663 8.14767 1.94513 7.83856 2.40879L6.66667 4.16663M13.3333 4.16663H6.66667M13.3333 4.16663H17.5M6.66667 4.16663H2.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

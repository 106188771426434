import { VacancyFiltersState } from ".";

export const getDirtFieldLength = (values: VacancyFiltersState) => {
  return Object.values(values || {}).reduce((acc, item) => {
    if (Array.isArray(item) && item.length && item.toString() !== ",") {
      acc = acc + 1;
    }

    if (typeof item === "number" && item) {
      acc = acc + 1;
    }

    if (typeof item === "string" && item) {
      acc = acc + 1;
    }

    return acc;
  }, 0);
};

import React from "react";

import {
  CallPayload,
  ModalCallResult,
} from "components/Modals/ModalCallResult";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  currentHotCallDataSelector,
  isActiveCallSelector,
} from "services/store/modules/sockets/selectors";
import {
  sendResultCall,
  setCurrentCallClaim,
  setModalResult,
} from "services/store/modules/calls";
import { useBeforeUnload } from "common/hooks/useBeforeUnload";
import { IUpdateCallPayload } from "services/store/modules/calls/types";
import { endCallOktell } from "services/store/modules/sockets/actions";

type Props = {
  isModalOpen: boolean;
};

export const Call: React.FC<Props> = ({ isModalOpen }) => {
  const dispatch = useAppDispatch();

  useBeforeUnload((evt) => {
    evt.preventDefault();
    dispatch(endCallOktell(""));
  });

  const isActiveCall = useAppSelector(isActiveCallSelector);
  const currentHotCallData = useAppSelector(currentHotCallDataSelector);

  const closeModal = () => {
    dispatch(setModalResult(false));
    dispatch(setCurrentCallClaim(undefined));
  };

  const onSubmit = async ({
    result,
    status,
    notThroughReason,
    sourceId,
  }: CallPayload) => {
    dispatch(
      sendResultCall({
        form: {
          result,
          status: currentHotCallData?.status,
          notThroughReason,
        },
        id: currentHotCallData?.id,
        sourceStatus: status,
        sourceId,
      } as IUpdateCallPayload)
    );
  };

  return (
    <ModalCallResult
      visible={isModalOpen}
      title={isActiveCall ? "Звонок" : "Результат звонка"}
      onCancel={closeModal}
      onSubmit={onSubmit}
      closable={false}
    />
  );
};

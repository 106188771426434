import React from "react";
import { useHistory } from "react-router-dom";

import {
  CandidateAdaptationType,
  CandidateStatus as CandidateStatusType,
} from "graphql/types/types";
import { Table, TableProps } from "components/UI/Table";
import { ROUTE } from "routes";
import { getDateFormat } from "common/utils/date";
import { CandidateStatus } from "components/CandidateStatus";

type Props = {
  candidatesForAdaptation: CandidateAdaptationType[];
  className?: string;
};

export const CandidateForAdaptationTable: React.FC<Props> = ({
  candidatesForAdaptation,
}) => {
  const history = useHistory();

  const columns: TableProps<CandidateAdaptationType>["columns"] = [
    {
      title: "ID Вакансии",
      dataIndex: "vacancyId",
      key: "vacancyId",
    },
    {
      title: "Дата перевода",
      dataIndex: "dateTransfer",
      key: "dateTransfer",
      render: (dateTransfer) => getDateFormat(dateTransfer, "DD.MM.YY HH:mm"),
    },
    {
      title: "ФИО МП",
      dataIndex: "fullNameMpp",
      key: "fullNameMpp",
    },
    {
      title: "ФИО МА",
      dataIndex: "fullNameMa",
      key: "fullNameMa",
    },
    {
      title: "Регион вакансии",
      dataIndex: "cityVacancy",
      key: "cityVacancy",
    },
    {
      title: "ФИО Кандидата",
      dataIndex: "fullNameCandidate",
      key: "fullNameCandidate",
    },
    {
      title: "Статус кандидата",
      dataIndex: "status",
      key: "status",
      render: (status: CandidateStatusType) => (
        <CandidateStatus status={status} />
      ),
    },
    {
      title: "Телефон кандидата",
      dataIndex: "phoneCandidate",
      key: "phoneCandidate",
    },
  ];

  const handleRowClick = (candidateId?: string | null) => {
    if (!candidateId) return;

    history.push({
      pathname: `${ROUTE.ADAPTATION}/${candidateId}`,
    });
  };

  return (
    <Table
      tableLayout="auto"
      dataSource={candidatesForAdaptation}
      columns={columns}
      onRow={(candidate) => ({
        onClick: () => handleRowClick(candidate.candidateId),
      })}
    />
  );
};

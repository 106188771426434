import React from "react";
import { Form, TreeSelectProps } from "antd";
import cn from "classnames";

import { TreeSelect } from "../../TreeSelect";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";

import "./styles.scss";

interface Props extends TreeSelectProps {
  label: string;
  required?: boolean;
  requiredText?: string;
  className?: string;
  error?: string;
}

export const FormTreeSelect: React.FC<Props> = ({
  label,
  required,
  className,
  error,
  requiredText,
  ...props
}) => {
  return (
    <Form.Item
      label={
        <FormLabel
          label={label}
          required={required}
          requiredText={requiredText}
        />
      }
      className={cn("ui-form-tree-select", className)}
      rules={[{ required, message: `Пожалуйста выберите ${label}` }]}
    >
      <TreeSelect error={error} {...props} />
      {error && <FormError error={error} />}
    </Form.Item>
  );
};

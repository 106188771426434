import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { PAGINATION } from "common/const/pagination";

const meta = PAGINATION.CANDIDATES;

export const selectCandidatesForAdaptationRoot = (state: RootState) =>
  state.candidatesForAdaptation;

export const selectCandidatesForAdaptationList = createSelector(
  selectCandidatesForAdaptationRoot,
  (state) => state.list
);

export const selectCandidatesForAdaptationListData = createSelector(
  selectCandidatesForAdaptationList,
  (state) => state.data || []
);

export const selectCandidatesForAdaptationListMeta = createSelector(
  selectCandidatesForAdaptationList,
  (state) => {
    const {
      limit = meta.limit,
      offset = meta.offset,
      total = 0,
    } = state.meta || {};

    return { limit, offset, total };
  }
);

export const selectCandidateForAdaptation = createSelector(
  selectCandidatesForAdaptationRoot,
  (state) => state.candidate
);

export const selectCandidateForAdaptationData = createSelector(
  selectCandidateForAdaptation,
  (state) => state.data
);

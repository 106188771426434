import React from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { CandidateForAdaptationList } from "components/redezign/CandidateForAdaptationList";

export const CandidateForAdaptationListPage: React.FC = () => {
  return (
    <ContentLayout
      centralColumn={{ content: <CandidateForAdaptationList /> }}
    />
  );
};

import { Action } from "redux";
import { Channel } from "./types";
import { addVacancy, removeVacancy } from "../vacanciesList";
import { setVacancyManagersIds } from "../vacancy";
import { publicationMessageByState } from "common/const/avito";
import { JbVacancyState } from "graphql/types/types";

type ActionsByChannelPayload = {
  vacancyId?: number;
  replyId?: number;
  managersIds: number[];
  owner: number;
};

type ActionMap = {
  addAction?: Action<"disabledVacancyList/addVacancy"> | null;
  removeAction?: Action<"disabledVacancyList/removeVacancy"> | null;
  setVacancyManagersIds?: Action<"vacancy/setVacancyManagersIds">;
};

type Result = Record<Channel, ActionMap>;

export const getActionsByChannel = ({
  vacancyId,
  replyId,
  managersIds,
  owner,
}: ActionsByChannelPayload): Result => ({
  [Channel.EnableVacancy]: {
    addAction: vacancyId
      ? addVacancy({ vacancyId, ownerId: Number(owner) })
      : null,
    removeAction: vacancyId ? removeVacancy({ vacancyId }) : null,
    setVacancyManagersIds: setVacancyManagersIds({
      vacancyId: vacancyId ?? 0,
      managersIds,
    }),
  },
});

export const getAvitoMessage = (
  status: JbVacancyState,
  content?: string | null,
  urlAvito?: string | null
) => {
  const serviceContentMessage =
    content ?? `Служебная информация от avito: ${content}`;
  const publicationStatusMessage =
    publicationMessageByState[status].toLocaleLowerCase();
  const avitoUrlMessage = urlAvito ? `Ссылка публикации ${urlAvito}` : "";

  return `Вакансия ${publicationStatusMessage}. ${avitoUrlMessage}\n${serviceContentMessage}`;
};

import {
  CandidateStatus,
  ReplyStatus,
  VacancyStatus,
  VacancyRelevance,
  ClaimStatus,
  ReplyNewViewStatuses,
  ReplyNewStatuses,
} from "graphql/types/types";

export enum ReplyStatusInClient {
  TransferredToCc = "TRANSFERRED_TO_CC",
}

export const REPLY_STATUS_TYPE: Record<
  ReplyStatus & ReplyStatusInClient,
  string
> = {
  [ReplyStatus.New]: "Новый", // новый МПП
  [ReplyStatus.Interview]: "Cобеседование", //собеседование
  [ReplyStatus.Candidate]: "Кандидат", // кандидат МПП
  [ReplyStatus.Denied]: "Отказано", // Отказано МПП
  [ReplyStatus.Expired]: "Просрочен", //FIXME Просрочен - autogenerated - удалить с бэка, вычислять с фронта
  [ReplyStatus.Decorated]: "Оформлен", // Оформлен
  [ReplyStatus.RefusalCandidate]: "Отказ кандидата", // Отказ кандидата
  [ReplyStatus.DoesntGetTouch]: "Не выходит на связь",
  [ReplyStatus.Callback]: "Нужно перезвонить", // Нужно перезвонить
  [ReplyStatus.NotThrough]: "Не дозвонились", // Не дозвонились
  [ReplyStatus.Reserve]: "Резерв", // Резерв
  [ReplyStatusInClient.TransferredToCc]: "Передан в КЦ",
  [ReplyStatus.TransferredToHrFromCc]: "Передано МПП от КЦ", // Передано МПП от КЦ
  [ReplyStatus.NotProcessed]: "Не обработан",
};

export const EDIT_REPLY_STATUS_MODERATOR = [
  ReplyStatus.Candidate,
  ReplyStatus.NotThrough,
  ReplyStatus.RefusalCandidate,
  ReplyStatus.Reserve,
  ReplyStatus.Denied,
  ReplyStatus.TransferredToHrFromCc,
];

export const CANDIDATE_STATUS_TYPE: Record<CandidateStatus, string> = {
  [CandidateStatus.New]: "Новый",
  [CandidateStatus.NotThrough]: "Не дозвонились",
  [CandidateStatus.Callback]: "Нужно перезвонить",
  [CandidateStatus.DoesntGetTouch]: "Не выходит на связь",
  [CandidateStatus.Candidate]: "Кандидат",
  [CandidateStatus.Denied]: "Отказано",
  [CandidateStatus.RefusalCandidate]: "Отказ кандидата",
  [CandidateStatus.NotLookingForJob]: "Не в поиске работы",
  [CandidateStatus.WithHigherPosition]: "Кандидат выше по должности",
  [CandidateStatus.SelfSearch]: "Самостоятельный поиск",
  [CandidateStatus.TransferredMa]: "Передан МА",
};

export const VACANCY_STATUS_TYPE: Record<VacancyStatus, string> = {
  [VacancyStatus.Draft]: "Черновик",
  [VacancyStatus.Moderation]: "На модерации",
  [VacancyStatus.Published]: "Опубликована",
  [VacancyStatus.Refused]: "Заблокирована",
  [VacancyStatus.Archived]: "В архиве",
  [VacancyStatus.InWork]: "В работе",
};

export const VACANCY_RELEVANCE: Record<VacancyRelevance, string> = {
  [VacancyRelevance.Relevant]: "Актуальна",
  [VacancyRelevance.NotRelevant]: "Не актуальна",
};

export const CLAIM_STATUS_TYPE: Record<ClaimStatus, string> = {
  [ClaimStatus.Callback]: "Нужно перезвонить",
  [ClaimStatus.Denied]: "Отказано",
  [ClaimStatus.DoesntGetTouch]: "Не выходит на связь",
  [ClaimStatus.New]: "Новый",
  [ClaimStatus.NotThrough]: "Не дозвонились",
  [ClaimStatus.OnDistribution]: "На распределении",
  [ClaimStatus.Other]: "Другое",
  [ClaimStatus.RefusalCandidate]: "Отказ кандидата",
  [ClaimStatus.TransferredToBusiness]: "Передано в бизнес",
  [ClaimStatus.TransferredToReplies]: "Передано в отклики",
};

export const REPLY_NEW_STATUS_TYPE: Record<
  ReplyNewStatuses & ReplyNewViewStatuses,
  string
> = {
  [ReplyNewStatuses.New]: "Новый",
  [ReplyNewStatuses.Callback]: "Нужно перезвонить",
  [ReplyNewStatuses.Processed]: "Обработан",
  [ReplyNewStatuses.InWork]: "В работе",
  [ReplyNewViewStatuses.Candidate]: "Кандидат",
  [ReplyNewViewStatuses.Denied]: "Отказано",
  [ReplyNewViewStatuses.NotLookingForJob]: "Не в поиске работы",
  [ReplyNewViewStatuses.NotThrough]: "Не дозвонились",
  [ReplyNewViewStatuses.RefusalCandidate]: "Отказ кандидата",
  [ReplyNewViewStatuses.SelfSearch]: "Самостоятельный поиск",
  [ReplyNewViewStatuses.WithHigherPosition]: "Кандидат выше по должности",
};
export enum CombinedReplyStatus {
  // Значения из ReplyNewStatuses
  Callback = "CALLBACK",
  InWork = "IN_WORK",
  New = "NEW",
  NotThrough = "NOT_THROUGH",
  Processed = "PROCESSED",

  // Значения из ReplyNewViewStatuses
  Candidate = "CANDIDATE",
  Denied = "DENIED",
  NotLookingForJob = "NOT_LOOKING_FOR_JOB",
  RefusalCandidate = "REFUSAL_CANDIDATE",
  SelfSearch = "SELF_SEARCH",
  WithHigherPosition = "WITH_HIGHER_POSITION",
}

export const REPLY_TRANSFERABLE_STATUSES = [
  ReplyNewStatuses.InWork,
  ReplyNewStatuses.NotThrough,
  ReplyNewStatuses.Processed,
];

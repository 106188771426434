import React from "react";

export const CheckVacancy: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_62_297)">
        <path
          d="M2.625 4.125L3.75 5.25L5.625 3.375"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.625 8.625L3.75 9.75L5.625 7.875"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.625 13.125L3.75 14.25L5.625 12.375"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 4.5H15"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 9H15"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 13.5H15"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_297">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";

export const CreateCandidate: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_62_359)">
        <path
          d="M10.6667 14V12.6667C10.6667 11.9594 10.3857 11.2811 9.88562 10.781C9.38552 10.281 8.70724 10 8 10H3.33333C2.62609 10 1.94781 10.281 1.44771 10.781C0.947618 11.2811 0.666666 11.9594 0.666666 12.6667V14"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3333 5.33331V9.33331"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3333 7.33331H11.3333"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_359">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import { Form } from "antd";
import { InputMask, Props as InputMaskProps } from "components/UI/InputMask";
import cn from "classnames";

import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";

import "./styles.scss";

interface Props extends InputMaskProps {
  label: string;
  error?: string;
  mask: InputMaskProps["mask"];
}
export const FormInputMask: React.FC<Props> = ({
  className,
  required,
  label,
  error,
  ...props
}) => {
  return (
    <Form.Item
      label={<FormLabel label={label} required={required} />}
      className={cn("ui-form--input-mask", className)}
      rules={[{ required, message: `${label}` }]}
    >
      <InputMask {...props} />
      {error && <FormError error={error} />}
    </Form.Item>
  );
};

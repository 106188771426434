export const TZ_LIST = [
  {
    key: "Europe/Kaliningrad",
    name: "Калининград +02:00",
    value: "+2",
    timeZone: "Eastern European Time",
    abbr: "EET",
  },
  {
    key: "Europe/Moscow",
    name: "Москва +03:00",
    value: "+3",
    timeZone: "Moscow Standard Time",
    abbr: "MSK",
  },
  {
    key: "Europe/Astrakhan",
    name: "Астрахань +04:00",
    value: "+4",
    timeZone: "Astrakhan Time",
    abbr: "AST",
  },
  {
    key: "Europe/Samara",
    name: "Самара +04:00",
    value: "+4",
    timeZone: "Samara Time",
    abbr: "SAMT",
  },
  {
    key: "Europe/Saratov",
    name: "Саратов +04:00",
    value: "+4",
    timeZone: "Saratov Time",
    abbr: "SART",
  },
  {
    key: "Asia/Yekaterinburg",
    name: "Екатеринбург +05:00",
    value: "+5",
    timeZone: "Yekaterinburg Time",
    abbr: "YEKT",
  },
  {
    key: "Asia/Omsk",
    name: "Омск +06:00",
    value: "+6",
    timeZone: "Omsk Standard Time",
    abbr: "OMST",
  },
  {
    key: "Asia/Krasnoyarsk",
    name: "Красноярск +07:00",
    value: "+7",
    timeZone: "Krasnoyarsk Time",
    abbr: "KRAT",
  },
  {
    key: "Asia/Novosibirsk",
    name: "Новосибирск +07:00",
    value: "+7",
    timeZone: "Novosibirsk Time",
    abbr: "NOVT",
  },
  {
    key: "Asia/Irkutsk",
    name: "Иркутск +08:00",
    value: "+8",
    timeZone: "Irkutsk Time",
    abbr: "IRKT",
  },
  {
    key: "Asia/Chita",
    name: "Чита +09:00",
    value: "+9",
    timeZone: "Yakutsk Time",
    abbr: "YAKT",
  },
  {
    key: "Asia/Vladivostok",
    name: "Владивосток +10:00",
    value: "+10",
    timeZone: "Vladivostok Time",
    abbr: "VLAT",
  },
  {
    key: "Asia/Magadan",
    name: "Магадан +11:00",
    value: "+11",
    timeZone: "Magadan Time",
    abbr: "MAGT",
  },
  {
    key: "Asia/Srednekolymsk",
    name: "Среднеколымск +11:00",
    value: "+11",
    timeZone: "Srednekolymsk Time",
    abbr: "SRET",
  },
  {
    key: "Asia/Sakhalin",
    name: "Сахалин +11:00",
    value: "+11",
    timeZone: "Sakhalin Time",
    abbr: "SAKT",
  },
  {
    key: "Asia/Kamchatka",
    name: "Камчатка +12:00",
    value: "+12",
    timeZone: "Kamchatka Time",
    abbr: "PETT",
  },
  {
    key: "Asia/Anadyr",
    name: "Анадырь +12:00",
    value: "+12",
    timeZone: "Anadyr Time",
    abbr: "ANAT",
  },
  {
    key: "Asia/Bangkok",
    name: "Красноярск +7:00",
    value: "+7",
    timeZone: "Krasnoyarsk Time",
    abbr: "KRAT",
  },
];

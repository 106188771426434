import { getDateFormat } from "common/utils/date";
import { ReplyFiltersInitialState } from "services/store/modules/replies/types";
import moment, { Moment } from "moment";

export const prepareFilter = ({
  creationDateRange: [startDate, endDate],
  status,
  departmentId,
  userId,
}: ReplyFiltersInitialState) => ({
  userId: userId || null,
  startCreatedAt: startDate ? getDateFormat(startDate, "YYYY-MM-DD") : null,
  endCreatedAt: endDate ? getDateFormat(endDate, "YYYY-MM-DD") : null,
  status: Array.isArray(status) ? status : [status],
  departmentId: departmentId || null,
});

export const parseReplyFilters = (raw): ReplyFiltersInitialState => {
  if (!raw.creationDateRange) {
    return {
      ...raw,
      creationDateRange: [null, null],
    };
  }

  return {
    ...raw,
    creationDateRange: raw.creationDateRange.map((d: string | null) =>
      d ? moment(d) : null
    ) as [Moment | null, Moment | null],
  };
};

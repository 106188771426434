import { gql } from "@apollo/client";

export const ACTUAL_STATUSES_CANDIDATES = gql`
  query actualStatusesCandidates {
    actualStatusesCandidates {
      ... on ActualStatusesCandidatesSuccess {
        data {
          key
          name
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

import { Button, Modal } from "antd";
import { ModalProps } from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BaseTextArea } from "../BaseTextArea";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/dedupe";
import { currentCallClaim } from "../../services/store/modules/calls";
import {
  currentHotCallDataSelector,
  currentOktellCallDataSelector,
  isActiveCallSelector,
} from "../../services/store/modules/sockets/selectors";
import { endCallOktell } from "../../services/store/modules/sockets/actions";
import "./Modal.scss";
import errorCall from "../../assets/icons/error-call.svg";
import { Timer } from "../Timer";
import { Spin } from "antd/es";

import { setStyleOverflow } from "common/utils/document";

const ModalContent = styled.div`
  padding: 0.5rem 0;
`;

const EndCallIcon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;

const ModalUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserName = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
`;

const UserPhone = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const SpinWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type CallPayload = {
  result: string;
  status?: string;
  notThroughReason?: string;
  sourceId?: number | string;
};

export interface IModalCallResultProps extends ModalProps {
  visible: boolean;
  title: string;
  onCancel: () => void;
  onSubmit: (payload: CallPayload) => void;
  closable?: boolean;
}

export const ModalCallResult: React.FC<IModalCallResultProps> = (
  props: IModalCallResultProps
) => {
  const [resultText, setResultText] = useState<string>("");
  const [currentItemStatus, setCurrentItemStatus] = useState<string>("");
  const [collapse, setCollapse] = useState(false);

  const currentCallClaimSelector = useSelector(currentCallClaim);
  const isActiveCall = useSelector(isActiveCallSelector);
  const currentOktellCallData = useSelector(currentOktellCallDataSelector);
  const dispatch = useDispatch();
  const hotCallData = useSelector(currentHotCallDataSelector);
  const [status, setStatus] = useState<string>("");

  const onChangeResult = (e: string) => {
    setResultText(e);
  };

  const onClose = () => {
    setResultText("");
    props.onCancel();
  };

  const endCall = () => {
    dispatch(endCallOktell(status));
    if (!hotCallData?.status) {
      onClose();
    }
  };

  const computedTitle = () => {
    const phone = currentCallClaimSelector?.contacts?.phone;
    const fullName = currentCallClaimSelector?.contacts?.fullName;
    return {
      phone,
      fullName,
    };
  };

  useEffect(() => {
    if (collapse) {
      setStyleOverflow("auto");
    }

    return () => setStyleOverflow();
  }, [collapse]);

  return (
    <Modal
      title={
        <div>
          {props.title}
          <span
            className="modal-call-toggle"
            onClick={() => setCollapse((prevState) => !prevState)}
          >
            {collapse ? "Развернуть" : "Свернуть"}
          </span>
        </div>
      }
      centered
      visible={props.visible}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      mask={!collapse}
      closable={props.closable}
      destroyOnClose={true}
      wrapClassName={classNames({ "modal-call-collapse": collapse })}
      footer={
        <ModalFooter>
          <div>
            {isActiveCall && (
              <Button
                icon={<EndCallIcon src={errorCall} alt="end" />}
                onClick={endCall}
              >
                Завершить звонок
              </Button>
            )}
          </div>
        </ModalFooter>
      }
    >
      {!collapse && (
        <ModalContent>
          {hotCallData?.status ? (
            <div>
              <ModalUserInfo>
                <UserName>{computedTitle().fullName || ""}</UserName>
                <UserPhone>{computedTitle().phone || "-"}</UserPhone>
                {currentOktellCallData || isActiveCall ? <Timer /> : null}
              </ModalUserInfo>
              <BaseTextArea
                required={currentItemStatus !== "NOT_THROUGHT"}
                value={resultText}
                label={"Введите результат звонка"}
                autoSize={{ minRows: 4, maxRows: 6 }}
                onChange={(event) => {
                  onChangeResult(event.currentTarget.value);
                }}
              />
            </div>
          ) : (
            <SpinWrapper>
              <Spin />
            </SpinWrapper>
          )}
        </ModalContent>
      )}
    </Modal>
  );
};

import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DaDataAddressSuggestion } from "react-dadata";
import toaster from "components/UI/Notifications/Notification";
import { IUser } from "services/store/modules/user/types";
import { MutationUpdateUserArgs } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { TZ_LIST } from "common/const/timezones";
import { createEvent } from "api/clickStream";
import { getDateTimezone } from "common/helpers/getDateTimezone";
import { authInfoThunk } from "services/store/modules/auth";
import { updateUser } from "services/store/modules/user/action";
import { useAppDispatch } from "services/store/store";
import { FormInput } from "components/UI/Form/FormInput";
import { FormInputMask } from "components/UI/Form/FormInputMask";
import { PHONE_MASK } from "common/const/masks";
import { FormSelect } from "components/UI/Form/FormSelect";
import { getSelectOptions } from "components/UI/Select/helpers";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import { Form } from "components/UI/Form";
import { Title } from "components/UI/Title";
import { getInitialState, getPayload } from "./helpers";
import { schema } from "./validation";

import "./styles.scss";

const SAVE_PROFILE_BUTTON_ID = "save-profile-button";

export interface ProfileState {
  firstName: string;
  secondName: string;
  email: string;
  phone: string;
  city: DaDataAddressSuggestion;
  tz: string;
}

interface Props {
  user: IUser;
}

export const ProfileForm: React.FC<Props> = ({ user }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const initialState = useMemo<ProfileState>(
    () => getInitialState(user),
    [user]
  );
  const { control, handleSubmit } = useForm<ProfileState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const onSubmit: SubmitHandler<ProfileState> = async (formData) => {
    setLoading(true);
    const payload: MutationUpdateUserArgs = getPayload(formData, user);
    try {
      await dispatch(updateUser(payload));
      dispatch(authInfoThunk());
      toaster.success({ title: "Данные пользователя изменены" });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }

    createEvent({
      userId: user.id,
      elementId: SAVE_PROFILE_BUTTON_ID,
      url: location.pathname,
      timestamp: getDateTimezone("Europe/Moscow"),
    }).catch(() => null);
  };

  return (
    <div className="profile-form" data-qa="user-edit-form">
      <div className="profile-form-card">
        <Title type={"h3"}> Редактирование профиля</Title>
        <Form onFinish={handleSubmit(onSubmit)}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "Имя обязательно" }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Имя"
                required
                error={error?.message}
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="secondName"
            control={control}
            rules={{ required: "Фамилия обязательна" }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Фамилия"
                required
                error={error?.message}
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: "Эл. почта обязательна" }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Эл. почта"
                required
                error={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{ required: "Телефон обязателен" }}
            render={({ field, fieldState: { error } }) => (
              <FormInputMask
                label="Телефон"
                mask={PHONE_MASK}
                required
                error={error?.message}
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            rules={{ required: "Город обязателен" }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormSelectDadata
                  label="Город"
                  required
                  error={error?.message}
                  filterFromBound="city"
                  filterToBound="city"
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="tz"
            control={control}
            rules={{ required: "Часовой пояс обязателен" }}
            render={({ field, fieldState: { error } }) => (
              <FormSelect
                label="Часовой пояс"
                required
                error={error?.message}
                options={getSelectOptions(TZ_LIST, true)}
                {...field}
              />
            )}
          />
          <div className="profile-form-buttons">
            <Button
              id={SAVE_PROFILE_BUTTON_ID}
              type="submit"
              disabled={loading}
              loading={loading}
            >
              Применить
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

import React from "react";

/**
 * Универсальный хелпер для "нормализации" вставки телефонного номера:
 *  - Блокирует дефолтную вставку
 *  - Убирает все нецифры
 *  - Если первая цифра — 7 или 8, отрезает её (для формата +7 ...)
 *  - Вставляет результат через setRangeText
 *  - Тригерит событие 'input', чтобы react-input-mask сразу применил маску
 */
export function handlePhonePaste(e: React.ClipboardEvent<HTMLInputElement>) {
  // 1) Предотвращаем стандартное поведение вставки,
  //    чтобы react-input-mask не обрабатывал вставляемый текст посимвольно.
  e.preventDefault();

  // 2) Из буфера обмена берём текст (если его нет — пустая строка).
  let pasted = e.clipboardData.getData("text") ?? "";

  // 3) Удаляем все символы, кроме цифр.
  let digits = pasted.replace(/\D/g, "");

  // 4) Если строка начинается с '7' или '8', убираем первую цифру.
  //    Таким образом, если пользователь вставляет 7 или 8, мы нормализуем
  //    номер под формат +7.
  if (digits.startsWith("7") || digits.startsWith("8")) {
    digits = digits.slice(1);
  }

  // 5) Получаем ссылку на сам <input>.
  const input = e.target as HTMLInputElement;

  // 6) Извлекаем текущее положение курсора или выделенный диапазон.
  const { selectionStart, selectionEnd } = input;

  // 7) Если пользователь что-то выделил, заменяем этот фрагмент
  //    нормализованными цифрами. Параметр "end" ставит курсор
  //    в конец вставленных данных.
  if (selectionStart !== null && selectionEnd !== null) {
    input.setRangeText(digits, selectionStart, selectionEnd, "end");
  } else {
    // Если текста не выделено, добавляем цифры в конец.
    input.value += digits;
  }

  // 8) Создаём и диспатчим нативное событие "input" (с "bubbles: true"),
  //    чтобы react-input-mask сразу же отследил изменение и применил маску.
  const ev2 = new Event("input", { bubbles: true });
  input.dispatchEvent(ev2);
}

import { apiRequest } from "../apiRequest";

export const getInfo = <T>(vacancyId: number, userId: number) => {
  return apiRequest<T>(
    "HH",
    "get",
    `/vacancies/${vacancyId}/info?user_id=${userId}`
  );
};

export const getProfile = <T>(userId: number) => {
  return apiRequest<T>("HH", "get", `/user/${userId}`);
};

export const logoutProfile = <T>(userId: number) => {
  return apiRequest<T>("HH", "get", `/auth/logout?user_id=${userId}`);
};

export const publishVacancy = <T>(
  vacancyId: number,
  userId: number,
  payload: Record<string, any>
) => {
  return apiRequest<T>(
    "HH",
    "post",
    `/vacancies/publish?vacancy_id=${vacancyId}&user_id=${userId}`,
    payload
  );
};

export const updateVacancy = <T>(
  vacancyId: number,
  userId: number,
  payload: Record<string, any>
) => {
  return apiRequest<T>(
    "HH",
    "put",
    `/vacancies/update?vacancy_id=${vacancyId}&user_id=${userId}`,
    payload
  );
};

export const stopPublication = <T>(vacancyId: number, userId: number) => {
  return apiRequest<T>(
    "HH",
    "post",
    `/vacancies/${vacancyId}/stop_publication?user_id=${userId}`
  );
};

export const removeVacancy = <T>(vacancyId: number) => {
  return apiRequest<T>("HH", "delete", `/vacancies/${vacancyId}`);
};

import { gql } from "@apollo/client";

export const TRANSFER_REPLY_LIST = gql`
  mutation transferNewReplyList($ids: [String!]!, $newUserId: Int!) {
    transferNewReplyList(ids: $ids, newUserId: $newUserId) {
      ... on BoolSuccess {
        result
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;

import { CityType, PositionType, RefusalReasonType } from "graphql/types/types";

export const validationKey = (
  key: string,
  list: RefusalReasonType[] | PositionType[] | CityType[]
) => {
  const reasons = new Set(list.map((item) => item.key.toLocaleLowerCase()));

  return !reasons.has(key.toLocaleLowerCase());
};

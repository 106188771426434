import { ROUTE } from "routes";

export type RouteValue = (typeof ROUTE)[keyof typeof ROUTE] | string;

export const isReactivationVacanciesPage = (pathname: RouteValue) =>
  pathname === "/reactivation-vacancies";
export const isVacanciesPage = (pathname: RouteValue) =>
  pathname === "/vacancies-view";
export const isVacancyPage = (pathname: RouteValue) =>
  pathname.includes("/vacancy-view");
export const isReplyPage = (pathname: RouteValue) =>
  pathname.includes("/new-reply");

import React from "react";

export const ColdSearch: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_62_345)">
        <path
          d="M8.25 11.25C8.25 12.0456 8.56607 12.8087 9.12868 13.3713C9.69129 13.9339 10.4544 14.25 11.25 14.25C12.0456 14.25 12.8087 13.9339 13.3713 13.3713C13.9339 12.8087 14.25 12.0456 14.25 11.25C14.25 10.4544 13.9339 9.69129 13.3713 9.12868C12.8087 8.56607 12.0456 8.25 11.25 8.25C10.4544 8.25 9.69129 8.56607 9.12868 9.12868C8.56607 9.69129 8.25 10.4544 8.25 11.25Z"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.875 13.875L15.75 15.75"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 4.5H15"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9H6"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 13.5H6"
          stroke="#0057AC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_62_345">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import cn from "classnames";

import { Close, Filters as FiltersIcon } from "../icons";

import "./styles.scss";

type Props = {
  text?: string;
  icon?: React.ReactNode;
  count?: number;
  className?: string;
  onOpen?: () => void;
  onReset?: () => void;
};

export const FiltersCount: React.FC<Props> = ({
  count,
  text = "Фильтры",
  icon,
  className,
  onOpen,
  onReset,
}) => {
  return (
    <div className={cn(className, "filters-count")}>
      <div className="filters-count-content" onClick={onOpen}>
        <span className="filters-count-icon">
          {icon ? icon : <FiltersIcon />}
        </span>
        <span className="filters-count-text">{text}</span>
      </div>

      {count ? (
        <div className="filters-count-item" onClick={onReset}>
          <span>{count}</span>
          <span>
            <Close width={14} height={14} color="#A6ACB9" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

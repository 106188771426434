import * as Yup from "yup";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import { VALIDATION_MESSAGE } from "common/const/messages";

export const schema = Yup.object({
  positionId: Yup.number().required("Необходимо указать название"),
  employmentId: Yup.number()
    .moreThan(0, "Необходимо указать тип занятости")
    .required("Необходимо указать тип занятости"),
  shortDescription: Yup.string()
    .required("Необходимо заполнить краткое описание")
    .max(
      VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION,
      VALIDATION_MESSAGE.MAX_LIMIT
    ),
  description: Yup.string()
    .required("Необходимо заполнить обязанности")
    .max(VACANCY_CHARACTER_LIMITS.DESCRIPTION, VALIDATION_MESSAGE.MAX_LIMIT),
  claims: Yup.string()
    .required("Необходимо заполнить требования")
    .max(VACANCY_CHARACTER_LIMITS.CLAIMS, VALIDATION_MESSAGE.MAX_LIMIT),
  terms: Yup.string()
    .required("Необходимо заполнить условия")
    .max(VACANCY_CHARACTER_LIMITS.TERMS, VALIDATION_MESSAGE.MAX_LIMIT),
  platformTags: Yup.string()
    .notRequired()
    .max(VACANCY_CHARACTER_LIMITS.PLATFORM_TAGS, VALIDATION_MESSAGE.MAX_LIMIT),
  callCenterInfoProject: Yup.string().required("Необходимо указать проект"),
  callCenterInfoDesc: Yup.string().required(
    "Необходимо указать описание компании"
  ),
  address: Yup.object().shape({
    value: Yup.string().required("Необходимо указать адрес"),
    data: Yup.object().shape({
      house: Yup.string()
        .required(VALIDATION_MESSAGE.FULL_ADDRESS)
        .nullable(true),
    }),
  }),
  salaryFrom: Yup.string().required("Необходимо указать доход"),
  salaryTo: Yup.string().required("Необходимо указать доход"),
}).required();

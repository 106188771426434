import {
  PositionRestrictions,
  ReplyNewStatuses,
  ReplyStatusesType,
} from "graphql/types/types";

export const PLATFORM_OPTIONS = [
  { label: "Rabotut.ru", value: "rabotut", disabled: true },
  { label: "Headhunter", value: "headhunter" },
  { label: "Авито работа", value: "avito" },
];

export const BOOLEAN_OPTIONS = [
  { label: "Да", value: "true" },
  { label: "Нет", value: "false" },
];

export const POSITION_RESTRICTIONS_OPTIONS = [
  { label: "Только женщины", value: PositionRestrictions.OnlyFemale },
  { label: "Только мужчины", value: PositionRestrictions.OnlyMale },
  { label: "Выбрать исключение", value: null },
];

export const COUNT_CANDIDATES_OPTIONS = [
  { label: "<=10", value: 10 },
  { label: "<=20", value: 20 },
  { label: "<=30", value: 30 },
  { label: "<=50", value: 50 },
  { label: "<=100", value: 100 },
];

export const REPLY_STATUSES_DICTIONARY: ReplyStatusesType[] = [
  { key: ReplyNewStatuses.New, name: "Новый" },
  { key: ReplyNewStatuses.InWork, name: "В работе" },
  { key: ReplyNewStatuses.Callback, name: "Нужно перезвонить" },
  { key: ReplyNewStatuses.Processed, name: "Обработан" },
  { key: ReplyNewStatuses.NotThrough, name: "Не дозвонились" },
];

export const OPTION_GENDER = [
  { label: "Мужской", value: "MALE" },
  { label: "Женский", value: "FEMALE" },
];

export const OPTION_CITIZENSHIP = [
  { label: "РФ", value: "true" },
  { label: "Не гражданин РФ", value: "false" },
];

export const OPTION_CAR = [
  { label: "Да", value: "true" },
  { label: "Нет", value: "false" },
];

export const PLATFORM_VACANCIES_OPTIONS = [
  { label: "Rabotut.ru", value: "RABOTUT" },
  { label: "Headhunter", value: "HH" },
  { label: "Авито работа", value: "AVITO" },
];

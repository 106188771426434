import React, { useMemo } from "react";
import cn from "classnames/dedupe";

import {
  CandidateType,
  ContactType,
  HotCallInputType,
  SourceHotCall,
} from "graphql/types/types";
import { Button } from "components/UI/Button";
import { PhoneCall } from "components/UI/icons";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { companySettings } from "services/store/modules/company";
import { isCallTimeValid } from "common/helpers/getDateTimezone";
import { Info } from "components/UI/icons/Info";
import { Tooltip } from "components/UI/Tooltip";
import { authInfo } from "services/store/modules/auth";
import { selectCallsLoading } from "services/store/modules/callsMango/selectors";
import { fetchCall } from "services/store/modules/callsMango/action";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  contact: ContactType["contact"];
  className?: string;
}
export const CallButton: React.FC<Props> = ({
  contact,
  candidate,
  className,
}) => {
  const company = useAppSelector(companySettings);
  const dispatch = useAppDispatch();
  const authData = useAppSelector(authInfo);
  const loading = useAppSelector(selectCallsLoading);

  const timezone = candidate?.timezone ?? "";
  const callTimeStart = company?.callTimeStart ?? "";
  const callTimeEnd = company?.callTimeEnd ?? "";
  const isCallButtonEnabled = useMemo(() => {
    if (!timezone) {
      return true;
    }
    return isCallTimeValid(timezone, callTimeStart, callTimeEnd);
  }, [timezone, callTimeStart, callTimeEnd]);

  const handleClickMango = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!authData) {
      return;
    }
    const payload: HotCallInputType = {
      contacts: {
        phone: contact,
        fullName: candidate?.fullName,
      },
      isMango: true,
      managerId: String(authData?.id),
      //эти поля не нужны для манго, отправлям т.к. обязательные для oktell
      sourceType: SourceHotCall.Candidate,
      sourceId: candidate.id,
      status: "START",
      managerRemoteId: "",
    };
    dispatch(fetchCall(payload));
    e.stopPropagation();
  };
  return (
    <div className="call-button-container">
      <Button
        icon={
          isCallButtonEnabled ? (
            <PhoneCall color={"#fff"} />
          ) : (
            <Tooltip
              placement="bottomRight"
              title={`Звонок кандидату доступен с ${callTimeStart} до ${callTimeEnd} по его часовому поясу`}
            >
              <span className="tooltip-icon-wrapper">
                <Info color="#0057AC" height={20} width={20} />
              </span>
            </Tooltip>
          )
        }
        loading={loading}
        className={cn(className)}
        onClick={handleClickMango}
        disabled={!isCallButtonEnabled || !authData?.mangoId}
      >
        Позвонить
      </Button>
    </div>
  );
};

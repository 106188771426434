import {
  PayloadAction,
  createSlice,
  isAnyOf,
  combineReducers,
} from "@reduxjs/toolkit";

import { CandidateType } from "graphql/types/types";
import {
  fetchCandidateList,
  fetchCandidateSearchList,
  fetchCandidateListByVacancy,
  fetchCandidateSuitableList,
  fetchCandidateReactivationList,
} from "./action";
import { getInitialState } from "../../helpers";
import { MetaType } from "services/store/types";

const candidatesListSlice = createSlice({
  name: "candidates",
  initialState: getInitialState<CandidateType[]>(),
  reducers: {
    updateCandidateInList: (
      state,
      { payload }: PayloadAction<CandidateType>
    ) => {
      if (state.data) {
        state.data = state.data.map((candidate) => {
          return candidate.id === payload.id ? payload : candidate;
        });
      }
    },
    removeCandidate: (
      state,
      { payload }: PayloadAction<{ candidateId: CandidateType["id"] }>
    ) => {
      if (state.data) {
        state.data = state.data.filter(
          (candidate) => candidate.id !== payload.candidateId
        );
      }
    },
    clearCandidateList: (state) => {
      state.data = null;
      state.meta = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateListByVacancy.fulfilled, (state, { payload }) => {
        state.data = payload.candidateListByVacancy.data || null;
        state.meta = payload.candidateListByVacancy.meta;
        state.loading = false;
      })
      .addCase(fetchCandidateList.fulfilled, (state, { payload }) => {
        state.data = payload.candidateList.data || null;
        state.meta = payload.candidateList.meta;
        state.loading = false;
      })
      .addCase(fetchCandidateSuitableList.fulfilled, (state, { payload }) => {
        state.data = [
          ...(state.data || []),
          ...(payload.candidateSuitableList.data || []),
        ];
        state.meta = payload.candidateSuitableList.meta;
        state.loading = false;
      })
      .addCase(
        fetchCandidateReactivationList.fulfilled,
        (state, { payload }) => {
          state.data = [
            ...(state.data || []),
            ...(payload?.candidateReactivationList.data || []),
          ];
          state.meta = payload?.candidateReactivationList.meta as MetaType;
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCandidateList.rejected,
          fetchCandidateListByVacancy.rejected,
          fetchCandidateSuitableList.rejected,
          fetchCandidateReactivationList.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message as string;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCandidateList.pending,
          fetchCandidateListByVacancy.pending,
          fetchCandidateSuitableList.pending,
          fetchCandidateReactivationList.pending
        ),
        (state) => {
          state.loading = true;
        }
      );
  },
});

const candidatesSearchSlice = createSlice({
  name: "candidatesSearch",
  initialState: getInitialState<CandidateType[]>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateSearchList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidateSearchList.fulfilled, (state, { payload }) => {
        state.data = payload?.candidateList.data;
        state.meta = payload?.candidateList.meta;
        state.loading = false;
      })
      .addCase(fetchCandidateSearchList.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export const { updateCandidateInList, removeCandidate, clearCandidateList } =
  candidatesListSlice.actions;

export const candidatesReducer = combineReducers({
  list: candidatesListSlice.reducer,
  search: candidatesSearchSlice.reducer,
});

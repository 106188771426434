import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Candidate } from "components/redezign/Candidate";
import { CandidateActions } from "components/CandidateActions";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { RouteParams } from "common/commonTypes/types";
import { selectCandidate } from "services/store/modules/candidate/selectors";
import {
  fetchCandidateById,
  getCandidateHistory,
} from "services/store/modules/candidate/actions";
import { parseQuery } from "common/utils/query";

export const CandidatePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { id } = useParams<RouteParams>();

  const { data: candidate, loading } = useAppSelector(selectCandidate);

  const { fromReply } = parseQuery(location.search);

  useEffect(() => {
    Promise.all([
      dispatch(fetchCandidateById(id)),
      dispatch(getCandidateHistory(id)),
    ]);
  }, [dispatch, id]);

  return (
    <ContentLayout
      centralColumn={{
        content: <Candidate candidate={candidate} loading={loading} />,
        width: 18,
      }}
      rightColumn={{
        width: 6,
        content: candidate ? (
          <CandidateActions
            fromReply={Boolean(fromReply)}
            candidateId={id}
            candidate={candidate}
          />
        ) : null,
      }}
    />
  );
};

import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import { ReplyFiltersState } from "../../types";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { DepartmentType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";
import { DateController } from "../DateController";

type Props = {
  control: Control<ReplyFiltersState>;
  departmentList: DepartmentType[];
  managerList: IUser[];
  errors: FieldErrors<ReplyFiltersState>;
  setError: UseFormSetError<ReplyFiltersState>;
  clearErrors: UseFormClearErrors<ReplyFiltersState>;
};

export const ReplyFilters: React.FC<Props> = ({
  control,
  departmentList,
  managerList,
  errors,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <DateController
        control={control}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
      />
      <Controller
        name="departamentId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <FormSelect
            label={"Департамент"}
            placeholder="Выберите департамент"
            options={getSelectOptions(departmentList)}
            {...field}
          />
        )}
      />
      <Controller
        name="managerId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="ФИО менеджера"
            placeholder="Выберите менеджера"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={getSelectOptions(managerList)}
            {...field}
          />
        )}
      />
    </>
  );
};

import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Recommended } from "components/Recommended";
import { RouteParams } from "common/commonTypes/types";
import { fetchCandidateById } from "services/store/modules/candidate/actions";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectCandidateError,
  selectCandidateLoading,
} from "services/store/modules/candidate/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { selectSavedVacancyData } from "services/store/modules/vacancy/selectors";
import { getUrlParams } from "common/utils/routes";
import { fetchVacancy } from "services/store/modules/vacancy/actions";
import { saveVacancy } from "services/store/modules/vacancy";
import { selectCandidateData } from "services/store/modules/candidate/selectors";

export const RecommendedPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const error = useAppSelector(selectCandidateError);
  const candidate = useAppSelector(selectCandidateData);
  const loading = useAppSelector(selectCandidateLoading);

  const vacancy = useAppSelector(selectSavedVacancyData);
  const { fromVacancyInWork } = getUrlParams();
  const { id: candidateId } = useParams<RouteParams>();

  const fetchVacancyData = useCallback(async () => {
    if (!fromVacancyInWork) return;
    const { privateVacancy } = await dispatch(
      fetchVacancy({ vacancyId: fromVacancyInWork })
    ).unwrap();
    dispatch(saveVacancy(privateVacancy));
  }, [dispatch, fromVacancyInWork]);

  useEffect(() => {
    if (!vacancy) {
      fetchVacancyData();
    }
  }, [vacancy, fetchVacancyData]);

  useEffect(() => {
    dispatch(fetchCandidateById(candidateId));
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {candidate?.id && !loading ? (
              <Recommended candidate={candidate} vacancyId={vacancy?.id} />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Ошибка загрузки кандидата"
              />
            )}
          </>
        ),
      }}
    />
  );
};

import React, { useMemo, useRef, useState } from "react";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import { getSelectOptions } from "components/UI/Select/helpers";
import Select from "components/UI/Select";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { IconStatus, Status } from "components/UI/iconStatus";

import { getCategoryById } from "services/store/modules/directory/getters";
import { selectPositionCategories } from "services/store/modules/vacanciesList/selectors";
import { PositionsRelationsType } from "graphql/types/types";

type Props = {
  categoryId: PositionsRelationsType["categoryId"];
  positionsRelations: PositionsRelationsType;
  className?: string;
  updatePositionsRelations: (
    positionsValue: PositionsRelationsType
  ) => Promise<void>;
};

export const FieldCategory: React.FC<Props> = ({
  categoryId,
  positionsRelations,
  className,
  updatePositionsRelations,
}) => {
  const categories = useAppSelector(selectPositionCategories);

  const categoryRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(categoryRef);

  const [isEdit, toggleIsEdit] = useToggle(false);

  const [localCategoryIds, setLocalCategoryIds] = useState(() => categoryId);

  const [status, setStatus] = useState<Status | null>(null);
  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const categoryName = useMemo(() => {
    return getCategoryById(categories, Number(categoryId))?.name ?? "";
  }, [categories, categoryId]);

  const handleSave = async () => {
    setStatus("pending");
    try {
      await updatePositionsRelations({
        ...positionsRelations,
        categoryId: localCategoryIds,
      });
      setStatus("success");
    } catch (error) {
      setStatus("error");
    } finally {
      toggleIsEdit();
      debounceStatus(null);
    }
  };

  const handleChangeEditClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    toggleIsEdit();
  };

  const saveIcon = (
    <span
      className="field-position-icon"
      onClick={(e) => {
        e.stopPropagation();
        handleSave();
      }}
    >
      <CornerUpRight />
    </span>
  );

  return (
    <div className={cn("field-position", className)} ref={categoryRef}>
      {isEdit ? (
        <Select
          className="field-position-select"
          value={localCategoryIds}
          options={getSelectOptions(categories)}
          suffixIcon={saveIcon}
          showArrow
          onChange={(value) => setLocalCategoryIds(value)}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <div className="field-position-short" title={categoryName}>
          {categoryName}
        </div>
      )}

      {(isHover || isEdit) && !status && (
        <span className="field-position-icon" onClick={handleChangeEditClick}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <Edit width={16} height={16} />
          )}
        </span>
      )}

      {status && (
        <span className="field-category-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { PlanTable } from "components/PlanTable";
import {
  selectActiveCategory,
  selectDailyPlanListVacancy,
  selectPositionCategories,
  selectVacanciesListByCategoryData,
  selectVacanciesListByCategoryMeta,
} from "services/store/modules/vacanciesList/selectors";
import { setActiveCategory } from "services/store/modules/vacanciesList";
import { VacanciesTable } from "components/VacanciesTable";
import { cities, positions } from "services/store/modules/directory";
import { PaginationPayload } from "components/UI/Pagination";
import { PAGINATION } from "common/const/pagination";
import { fetchVacancyListByCategory } from "services/store/modules/vacanciesList/actions";
import { QueryVacancyListByCategoryArgs } from "graphql/types/types";
import { prepareVacanciesPayload } from "../Vacancies/helpers";
import { ROUTE } from "routes";
import { getColumns } from "./helpers";

import "./styles.scss";

export const ColdSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const dailyPlanList = useAppSelector(selectDailyPlanListVacancy);
  const positionCategories = useAppSelector(selectPositionCategories);
  const { activeCategory } = useAppSelector(selectActiveCategory);
  const vacancies = useAppSelector(selectVacanciesListByCategoryData);
  const meta = useAppSelector(selectVacanciesListByCategoryMeta);
  const vacancyPositions = useAppSelector(positions);
  const citiesList = useAppSelector(cities);

  const getVacancies = useCallback(
    (payload: QueryVacancyListByCategoryArgs) => {
      dispatch(fetchVacancyListByCategory(payload));
    },
    [dispatch]
  );
  const handleCategoryClick = useCallback(
    (
      categoryId: number,
      pagination: PaginationPayload = PAGINATION.VACANCIES
    ) => {
      dispatch(setActiveCategory(categoryId));
      getVacancies(
        prepareVacanciesPayload({ pagination, categoryId, citiesList })
      );
    },
    [citiesList, dispatch, getVacancies]
  );

  return (
    <div className="cold-search">
      <PlanTable
        dailyPlanList={dailyPlanList}
        positionCategories={positionCategories}
        activeCategory={activeCategory}
        onClick={handleCategoryClick}
      />

      <VacanciesTable
        vacancies={vacancies}
        columns={getColumns({ positionCategories, vacancyPositions })}
        meta={meta}
        onRowClick={(vacancyId) =>
          history.push(`${ROUTE.COLD_SEARCH_VACANCY}/${vacancyId}`)
        }
      />
    </div>
  );
};

import React, { useState } from "react";
import { Modal } from "components/UI/Modal";
import { UserClientType } from "graphql/types/types";
import { useAppSelector } from "services/store/store";
import { selectUserListData } from "services/store/modules/user/selectors";
import { filterOption } from "components/UI/Select/helpers";
import { FormSelect } from "components/UI/Form/FormSelect";
import { Button } from "components/UI/Button";

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (newUserId: number) => void;
}

export const ReplyTransferListModal: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
}) => {
  const [user, setUser] = useState<UserClientType["id"] | null>(null);
  const userList = useAppSelector(selectUserListData);

  const updateUserForm = (newUser: UserClientType["id"] | null) => {
    setUser(newUser);
  };

  const handleOk = () => {
    if (!user) {
      return;
    }
    onSubmit(user);
    onClose();
  };

  return (
    <Modal
      title="Передача откликов"
      visible={visible}
      footer={false}
      onCancel={onClose}
    >
      <p>Выберите менеджера для передачи откликов.</p>
      <FormSelect
        label={"Менеджер"}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        options={userList?.map(({ firstName, secondName, id }) => ({
          label: `${firstName} ${secondName}`,
          value: id,
        }))}
        onChange={updateUserForm}
      />
      <Button className="" onClick={handleOk} disabled={!user}>
        Передать отклики
      </Button>
    </Modal>
  );
};

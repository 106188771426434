import React, { useState } from "react";

import { FormInput } from "components/UI/Form/FormInput";
import { MB, KB } from "common/const/file";
import { Button } from "components/UI/Button";
import {
  Upload,
  type Props as UploadProps,
  UploadFile,
} from "components/UI/Upload";

import "./styles.scss";

const MAX_SIZES_MB = MB * 15;

export const SearchDownload: React.FC = () => {
  const [file, setFile] = useState<UploadFile | null>(null);
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleFileChange: UploadProps["onChange"] = ({ file }) => {
    setFile(file);
  };

  const beforeUpload = (file: File) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      setError("Файл должен быть в pdf формате");
    }
    const isSize = file.size / KB / KB < MAX_SIZES_MB;
    if (!isSize) {
      setError("Размер файла должен быть не более 15MB");
    }
    return isPdf && isSize;
  };

  return (
    <div className="search-download">
      <FormInput
        className="search-download-input"
        label="Укажите ссылку на резюме"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button>Загрузить</Button>

      <Upload
        maxCount={1}
        showUploadList={false}
        onChange={handleFileChange}
        beforeUpload={beforeUpload}
      >
        <Button variant="primary">Загрузить из файла</Button>
      </Upload>
    </div>
  );
};

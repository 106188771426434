import React, { useState } from "react";

import { FormInput } from "components/UI/Form/FormInput";
import { Button } from "components/UI/Button";
import { useAppDispatch } from "services/store/store";
import {
  createRefusal,
  createPosition,
} from "services/store/modules/directory";
import { transliterate } from "common/utils";
import {
  PositionType,
  RefusalReasonType,
  RefusalType,
} from "graphql/types/types";
import toaster from "components/UI/Notifications/Notification";
import { AREAS } from "common/const/dictionary";
import { validationKey } from "../../helpers";

type Props = {
  list: RefusalReasonType[] | PositionType[];
  type: RefusalType | typeof AREAS.POSITIONS;
};

export const DictionaryForm: React.FC<Props> = ({ type, list }) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleChange = (value: string) => {
    setName(value);
    setKey(transliterate(value));
  };

  const onClear = () => {
    setName("");
    setKey("");
  };

  const getCreateAction = () => {
    if (type === AREAS.POSITIONS) {
      return dispatch(createPosition({ form: { key, name } }));
    }

    return dispatch(
      createRefusal({ form: { type, key, name, isRepeated: false } })
    );
  };

  const handleClick = () => {
    setError(null);

    if (!validationKey(key, list)) {
      return setError("Дубликат ключа");
    }

    getCreateAction()
      .then(() => {
        onClear();
        toaster.success({ title: "Изменения успешно сохранены" });
      })
      .catch(() => {
        toaster.error({ title: "Ошибка изменения" });
      });
  };

  return (
    <div>
      <FormInput
        label="Название"
        value={name}
        onChange={(e) => handleChange(e.target.value)}
      />
      <FormInput
        label="Ключ"
        value={key}
        error={error}
        onChange={(e) => handleChange(e.target.value)}
      />

      <Button disabled={!key.length} onClick={handleClick}>
        Добавить
      </Button>
    </div>
  );
};

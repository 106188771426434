import React, { useCallback, useState } from "react";

import { FormSelect } from "components/UI/Form/FormSelect";
import { IStatus } from "services/store/modules/directory/types";
import {
  RefusalReasonType,
  RefusalType,
  ReplyNewClientType,
  ReplyNewStatuses,
  ReplyNewViewStatuses,
  VacancyType,
} from "graphql/types/types";
import { getSelectOptions } from "components/UI/Select/helpers";
import { REPLY_NEW_STATUS_TYPE } from "common/const/status";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectRefusalReasonsByType } from "services/store/modules/directory";
import { useReplyStatus } from "common/hooks/reply/useReplyStatus";
import { getReplyStatusType } from "./helpers";
import { setChangeStatus } from "services/store/modules/replies";

interface Props {
  vacancyId: VacancyType["id"];
  replyId: ReplyNewClientType["id"];
  statuses: IStatus[];
  status: ReplyNewViewStatuses | ReplyNewStatuses;
  className?: string;
}

export const ReplyStatusSelect: React.FC<Props> = ({
  statuses,
  status,
  vacancyId,
  replyId,
  className,
}) => {
  const dispatch = useAppDispatch();
  const {
    reason,
    denied,
    notThrough,
    type,
    setReason,
    setDenied,
    setNotThrough,
    setType,
    updateReplyStatus,
  } = useReplyStatus({ isShowToaster: true });

  const [currentStatus, setCurrentStatus] =
    useState<ReplyNewViewStatuses | null>(null);
  const [loading, setLoading] = useState(false);

  const refusalReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateRefusal)
  );
  const deniedReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateDenied)
  );
  const notThroughReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateNotThrough)
  );

  const updateStatus = useCallback(
    async (
      status: ReplyNewViewStatuses,
      reason?: RefusalReasonType["id"] | null
    ) => {
      setLoading(true);

      await updateReplyStatus({
        replyId,
        status,
        refusalId: reason ?? undefined,
        vacancyId,
      }).then((result) => {
        result?.changeStatusNewReply &&
          dispatch(
            setChangeStatus({
              status: result.changeStatusNewReply.status,
              ...(result.changeStatusNewReply.candidateStatus
                ? {
                    candidateStatus:
                      result.changeStatusNewReply.candidateStatus,
                  }
                : {}),
            })
          );
      });

      setType(null);
      setLoading(false);
    },
    [updateReplyStatus, replyId, vacancyId, setType, dispatch]
  );

  const handleStatusChange = async (status: ReplyNewViewStatuses) => {
    setCurrentStatus(status);

    const type = getReplyStatusType(status);

    if (type) {
      return setType(type);
    }

    updateStatus(status);
  };

  const handleRefusalChange = (
    value: number,
    reasonType: "reason" | "denied" | "notThrough"
  ) => {
    const setStateMap = {
      reason: setReason,
      denied: setDenied,
      notThrough: setNotThrough,
    };

    setStateMap[reasonType](value);
    if (currentStatus) {
      updateStatus(currentStatus, value);
    }
  };

  const typeConfig = {
    reason: {
      options: getSelectOptions(refusalReasons),
      value: reason,
      onChange: (value: number) => handleRefusalChange(value, "reason"),
      label: "Причина отказа",
    },
    denied: {
      options: getSelectOptions(deniedReasons),
      value: denied,
      onChange: (value: number) => handleRefusalChange(value, "denied"),
      label: "Причина отказа кандидата",
    },
    notThrough: {
      options: getSelectOptions(notThroughReasons),
      value: notThrough,
      onChange: (value: number) => handleRefusalChange(value, "notThrough"),
      label: "Причина недозвона",
    },
  };

  const currentConfig = type ? typeConfig[type] : null;

  return (
    <div className={className}>
      <FormSelect
        label={`Статус отклика: ${REPLY_NEW_STATUS_TYPE[status]}`}
        value={currentStatus}
        placeholder="Выберете статус"
        style={{ width: "100%" }}
        options={getSelectOptions(statuses)}
        onChange={handleStatusChange}
        disabled={status !== ReplyNewStatuses.InWork}
      />
      {currentConfig && (
        <FormSelect
          label={currentConfig.label}
          defaultValue="Укажите причину"
          value={currentConfig.value}
          style={{ width: "100%" }}
          loading={loading}
          options={currentConfig.options}
          onChange={currentConfig.onChange}
        />
      )}
    </div>
  );
};

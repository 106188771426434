import React from "react";
import { toast, ToastOptions } from "react-toastify";
import cn from "classnames";

import { Check, Close, Warning } from "components/UI/icons";
import { Spinner } from "components/UI/Spinner";
import { Title } from "components/UI/Title";
import { Info } from "../../icons/Info";

import "./styles.scss";

type Props = {
  title?: React.ReactNode;
  type?: Type;
  className?: string;
  hasIcon?: boolean;
  size?: Size;
};

export type Type = "success" | "error" | "warning" | "info";
export type Size = "small" | "normal";

const iconByType: Record<Type, React.ReactElement> = {
  success: <Check />,
  error: <Close color="#FF0000" />,
  warning: <Warning />,
  info: <Info color="#0057AC" height={16} width={16} />,
};

export const Notification: React.FC<Props> = ({
  title,
  type,
  hasIcon = true,
  size = "normal",
  className,
}) => {
  return (
    <div
      className={cn("notification", className, {
        [`notification--${type}`]: true,
        "notification--small": size === "small",
        "notification--normal": size === "normal",
      })}
    >
      {hasIcon && type ? iconByType[type] : undefined}
      {title && <div className="notification-title">{title}</div>}
    </div>
  );
};

type NotificationStatusProps = {
  loading: boolean;
  error: string | null;
  notFoundText?: string;
  isCondition?: boolean;
  className?: string;
};

export const NotificationStatus: React.FC<NotificationStatusProps> = ({
  loading,
  error,
  isCondition,
  notFoundText,
}) => {
  const type = (loading && "loading") || (error && "error") || "notFound";

  const notificationByType: Record<typeof type, React.ReactElement> = {
    loading: <Spinner className="spinner" loading={loading} />,
    error: <Notification title={error} type="error" />,
    notFound: (
      <>
        {isCondition && notFoundText ? (
          <Title type="h4">{notFoundText}</Title>
        ) : null}
      </>
    ),
  };

  return notificationByType[type];
};

const getDefaultToastProps = (type: Type) => {
  const className = cn("notification", {
    [`notification--${type}`]: true,
  });

  return {
    position: "bottom-right" as const,
    closeButton: <Close width={18} height={18} />,
    hideProgressBar: true,
    className,
    bodyClassName: "notification-body",
  };
};

const toaster = (props: Props, toastProps?: ToastOptions) =>
  toast(<Notification {...props} />, toastProps);

toaster.success = (props: Props, toastProps?: ToastOptions) =>
  toast.success(<Notification {...{ ...props, type: "success" }} />, {
    ...{ ...toastProps, ...getDefaultToastProps("success") },
  });
toaster.error = (props: Props, toastProps?: ToastOptions) =>
  toast.error(<Notification {...{ ...props, type: "error" }} />, {
    ...{ ...toastProps, ...getDefaultToastProps("error") },
  });
toaster.warning = (props: Props, toastProps?: ToastOptions) =>
  toast.warning(<Notification {...{ ...props, type: "warning" }} />, {
    ...{ ...toastProps, ...getDefaultToastProps("warning") },
  });

toaster.info = (props: Props, toastProps?: ToastOptions) =>
  toast.info(<Notification {...{ ...props, type: "info" }} />, {
    ...{ ...toastProps, ...getDefaultToastProps("info") },
  });

export default toaster;

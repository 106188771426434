import React from "react";
import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import RowLayout from "layouts/RowLayout";

interface Props {
  title?: string;
  message: string;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const CommonErrorModal: React.FC<Props> = ({
  title = "Ошибка",
  message,
  open,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      size="normal"
      footer={
        <RowLayout
          justify="start"
          leftSpan={10}
          leftComponent={
            <Button variant="primary" onClick={onOk}>
              OK
            </Button>
          }
        />
      }
    >
      {message}
    </Modal>
  );
};

import { useCallback } from "react";

import { useAppDispatch } from "services/store/store";
import { authInfoThunk } from "services/store/modules/auth";
import { useFetchUsers } from "./users/useFetchUsers";
import {
  fetchActualStatusesCandidates,
  fetchCandidateDictionaries,
} from "services/store/modules/candidate/actions";
import { fetchVacanciesDictionaries } from "services/store/modules/vacanciesList/actions";
import { fetchCompanySettings } from "services/store/modules/company";

export const useInitialActions = () => {
  const dispatch = useAppDispatch();
  const { fetchUsers } = useFetchUsers();

  const fetchInitialActions = useCallback(() => {
    Promise.all([
      dispatch(authInfoThunk()),
      fetchUsers(),
      dispatch(fetchVacanciesDictionaries()),
      dispatch(fetchCandidateDictionaries()),
      dispatch(fetchActualStatusesCandidates()),
      dispatch(fetchCompanySettings()),
    ]);
  }, [dispatch, fetchUsers]);

  return { fetchInitialActions };
};

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { CandidateForAdaptation } from "components/redezign/CandidateForAdaptation";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { fetchCandidateForAdaptation } from "services/store/modules/candidatesForAdaptation/actions";
import { RouteParams } from "common/commonTypes/types";
import {
  selectCandidateForAdaptation,
  selectCandidateForAdaptationData,
} from "services/store/modules/candidatesForAdaptation/selectors";
import { authInfo } from "services/store/modules/auth";
import { NotificationStatus } from "components/UI/Notifications/Notification";

export const CandidateForAdaptationPage: React.FC = () => {
  const { id: candidateId } = useParams<RouteParams>();
  const dispatch = useAppDispatch();

  const { error, loading } = useAppSelector(selectCandidateForAdaptation);
  const candidateForAdaptation = useAppSelector(
    selectCandidateForAdaptationData
  );
  const authData = useAppSelector(authInfo);

  useEffect(() => {
    dispatch(fetchCandidateForAdaptation({ candidateId }));
  }, []);

  return (
    <ContentLayout
      centralColumn={{
        content:
          candidateForAdaptation && authData && !loading ? (
            <CandidateForAdaptation
              candidateId={candidateId}
              authData={authData}
              candidateForAdaptation={candidateForAdaptation}
            />
          ) : (
            <NotificationStatus
              loading={loading}
              error={error}
              notFoundText="Ошибка получения кандидата для адаптации"
            />
          ),
      }}
    />
  );
};

import { getDateFormat } from "common/utils/date";
import { FiltersState } from "./components/AdaptationFilters";

export const preparePayload = (payload: FiltersState) => ({
  filter: {
    ...payload,
    vacancyId: payload.vacancyId ? Number(payload.vacancyId) : null,
    dateTransfer: payload.dateTransfer
      ? getDateFormat(payload.dateTransfer, "YYYY-MM-DD")
      : null,
  },
  pagination: {},
});

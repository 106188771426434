import {
  MutationChangeVisibleVacancyArgs,
  ReplyType,
  VacancyType,
} from "graphql/types/types";
import { useCallback } from "react";
import { Channel } from "services/store/modules/sockets/types";
import { changeVacancyVisible } from "services/store/modules/vacancy/actions";
import { useAppDispatch } from "services/store/store";

type Props = {
  id: VacancyType["id"] | ReplyType["id"];
};

export const useChangeVisible = ({ id }: Props) => {
  const dispatch = useAppDispatch();

  const onChangeVacancyVisible = useCallback(
    (
      payload: Pick<MutationChangeVisibleVacancyArgs, "inBlocked" | "inWork">
    ) => {
      return dispatch(
        changeVacancyVisible({
          channel: Channel.EnableVacancy,
          id,
          ...payload,
        })
      );
    },
    [dispatch, id]
  );

  return { onChangeVacancyVisible };
};

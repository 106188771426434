import React from "react";

import { useAppSelector } from "services/store/store";
import { CandidateForAdaptationTable } from "components/redezign/CandidateForAdaptationList/components/CandidateForAdaptationTable";
import { ColumnLayout } from "layouts/ColumnLayout";
import { AdaptationFilters } from "./components/AdaptationFilters";
import { selectFilteredCandidateStatuses } from "services/store/modules/candidate/selectors";
import { selectAreas } from "services/store/modules/directory";
import {
  selectCandidatesForAdaptationList,
  selectCandidatesForAdaptationListData,
} from "services/store/modules/candidatesForAdaptation/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { CandidateStatus } from "graphql/types/types";

import "./styles.scss";

export const CandidateForAdaptationList: React.FC = () => {
  const candidatesForAdaptationList = useAppSelector(
    selectCandidatesForAdaptationListData
  );
  const { error, loading } = useAppSelector(selectCandidatesForAdaptationList);
  const candidateStatuses = useAppSelector((state) =>
    selectFilteredCandidateStatuses(state, [
      CandidateStatus.Candidate,
      CandidateStatus.TransferredMa,
    ])
  );
  const areasOptions = useAppSelector(selectAreas);

  return (
    <ColumnLayout
      className="adaptation-list"
      headerClassName="adaptation-list-header"
      header={
        <AdaptationFilters
          loading={loading}
          candidateStatuses={candidateStatuses}
          areasOptions={areasOptions}
        />
      }
    >
      {candidatesForAdaptationList.length && !loading ? (
        <CandidateForAdaptationTable
          candidatesForAdaptation={candidatesForAdaptationList}
        />
      ) : (
        <NotificationStatus
          loading={loading}
          error={error}
          notFoundText="Ошибка получения списка"
        />
      )}
    </ColumnLayout>
  );
};

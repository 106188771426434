import { useRef } from "react";
import { Table as AntTable } from "antd";
import type { TableProps } from "antd/es/table";
import cn from "classnames/dedupe";
import useDraggableScroll from "common/hooks/useDraggableScroll";

import "./styles.scss";

export type { TableProps };

export type Props<T extends object> = TableProps<T> & {
  draggableScroll?: boolean;
};

export function Table<T extends object>({
  bordered = true,
  draggableScroll = false,
  className,
  onRow,
  ...props
}: Props<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { onMouseDown, isDragging } = useDraggableScroll(
    draggableScroll ? containerRef : null
  );

  const handleOnRow: TableProps<T>["onRow"] = (record, index) => {
    const rowProps = onRow?.(record, index) || {};
    const originalOnClick = rowProps.onClick;

    return {
      ...rowProps,
      onClick: (event) => {
        if (!isDragging) {
          originalOnClick?.(event);
        }
      },
    };
  };

  return (
    <div
      ref={containerRef}
      onMouseDown={draggableScroll ? onMouseDown : undefined}
      className="ui-table-container global-scrollbar"
    >
      <AntTable<T>
        className={cn("ui-table", className)}
        rowClassName="ui-table-row"
        bordered={bordered}
        pagination={false}
        onRow={handleOnRow}
        {...props}
      />
    </div>
  );
}

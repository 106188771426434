import React from "react";

import { VacancyType } from "graphql/types/types";
import { VacancyInfo } from "components/VacancyInfo";
import { IUser } from "services/store/modules/user/types";

import { ColdSearchVacancyContent } from "./components/ColdSearchVacancyContent";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  userData: IUser;
}

export const ColdSearchVacancy: React.FC<Props> = ({ vacancy, userData }) => {
  return (
    <div className="cold-vacancy">
      <VacancyInfo
        vacancy={vacancy}
        userData={userData}
        className="cold-vacancy-info"
        withPublish={false}
        withStayInWork={false}
        withPeopleCount={false}
      />

      <ColdSearchVacancyContent />
    </div>
  );
};

import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import cn from "classnames/dedupe";

import { useLocalStorage } from "common/hooks/useLocalStorage";
import { useAppSelector } from "services/store/store";
import { useAppDispatch } from "services/store/store";
import { Title } from "components/UI/Title";
import { Props as TableProps } from "components/UI/Table";
import { Spinner } from "components/UI/Spinner";
import { VacancyType } from "graphql/types/types";
import {
  selectPositionCategories,
  selectCategoryById,
  selectVacanciesListByCategory,
  selectActiveVacancyById,
  selectDisabledVacancyList,
  selectDailyPlanListLoading,
  selectDailyPlanListVacancy,
  selectActiveCategory,
  selectVacanciesListByCategoryData,
  selectVacanciesListByCategoryMeta,
} from "services/store/modules/vacanciesList/selectors";
import {
  fetchVacancyListByCategory,
  fetchVacancyListByCategoryReactivation,
} from "services/store/modules/vacanciesList/actions";
import { PlanTable } from "../../PlanTable";
import {
  cities,
  department,
  positions,
  vacancyStatuses,
} from "services/store/modules/directory";
import { PaginationPayload } from "components/UI/Pagination";
import {
  setActiveCategory,
  setActiveVacancyId,
} from "services/store/modules/vacanciesList";
import { getVacancyPositionsName } from "services/store/modules/vacanciesList/getters";
import { ROUTE } from "routes";
import { Copy, Question } from "components/UI/icons";
import { Tooltip } from "components/UI/Tooltip";
import { VacanciesTable } from "components/VacanciesTable";
import { VacancyStatus } from "components/VacancyStatus";
import { CANDIDATE_COUNT_TOOLTIP } from "./constants";
import {
  VacancyFilters,
  VacancyFiltersState,
} from "./components/VacancyFilters";
import { PrepareVacanciesPayload, prepareVacanciesPayload } from "./helpers";
import { ContentLayout } from "layouts/ContentLayout";
import { authInfo } from "services/store/modules/auth";
import { VacancyModals } from "./components/VacancyModals";
import { getTrueName } from "common/helpers/string";
import { PAGINATION } from "common/const/pagination";
import { hasAcceptAccess } from "common/helpers/role";
import { INITIAL_STATE_FILTERS_VACANCY } from "common/const/filters";
import { isReactivationVacanciesPage, RouteValue } from "common/helpers/pages";
import { stringifyQuery } from "common/utils/query";
import { getDateFormat } from "common/utils/date";
import { useCopyToClipboard } from "common/hooks/useCopyToClipboard";

import "./styles.scss";

type Props = {
  className?: string;
};

const Trigger: React.FC<{ trigger: boolean }> = ({ trigger }) => (
  <span>{getTrueName(trigger)}</span>
);

export const Vacancies: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { pathname } = useLocation();
  const [, copy] = useCopyToClipboard();

  const loading = useAppSelector(selectDailyPlanListLoading);
  const dailyPlanList = useAppSelector(selectDailyPlanListVacancy);
  const { activeCategory } = useAppSelector(selectActiveCategory);
  const vacancyPositions = useAppSelector(positions);
  const departmentList = useAppSelector(department);
  const citiesList = useAppSelector(cities);
  const vacancyStatus = useAppSelector(vacancyStatuses);
  const currentCategoryById = useAppSelector(selectCategoryById);
  const positionCategories = useAppSelector(selectPositionCategories);
  const activeVacancy = useAppSelector(selectActiveVacancyById);
  const { loading: vacanciesListLoading } = useAppSelector(
    selectVacanciesListByCategory
  );
  const vacancies = useAppSelector(selectVacanciesListByCategoryData);
  const meta = useAppSelector(selectVacanciesListByCategoryMeta);
  const disabledVacancyList = useAppSelector(selectDisabledVacancyList);
  const userData = useAppSelector(authInfo);

  const isReactivation = isReactivationVacanciesPage(pathname as RouteValue);

  const [vacancyFilters, setVacancyFilters] =
    useLocalStorage<VacancyFiltersState>(
      "vacancyFilters",
      INITIAL_STATE_FILTERS_VACANCY
    );

  const [activeCategoryIdStorage, setActiveCategoryIdStorage] = useLocalStorage<
    number | null
  >("activeCategory", null);

  const { handleSubmit, control, watch, reset, formState } =
    useForm<VacancyFiltersState>({
      defaultValues: vacancyFilters,
    });

  const filters = watch();
  const columns: TableProps<VacancyType>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Tooltip
          placement="bottomLeft"
          title={
            <div
              className="vacancy-container-copyTooltip"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="vacancy-container-copy" onClick={() => copy(id)}>
                <Copy />
              </div>
              {id}
            </div>
          }
        >
          <div className="vacancy-container-short">{id}</div>
        </Tooltip>
      ),
    },
    {
      title: "Вакансия",
      dataIndex: "positionId",
      key: "positionId",
      width: 200,
      render: (positionId, vacancy) => (
        <div
          className="nowrap vacancies-container-category-name"
          title={getVacancyPositionsName(vacancyPositions, positionId)}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setActiveVacancyId(vacancy.id));
          }}
        >
          {getVacancyPositionsName(vacancyPositions, positionId) ??
            vacancy.position?.name}
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status) => <VacancyStatus status={status} className="nowrap" />,
    },
    {
      title: "Торговая точка",
      dataIndex: "callCenterInfo",
      key: "callCenterInfo",
      width: 120,
      render: (callCenterInfo) => {
        const { project = "" } = callCenterInfo || {};
        return (
          <div
            className={cn("nowrap", "vacancies-container-table-callCenter")}
            title={project}
          >
            {project}
          </div>
        );
      },
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 240,
      render: (address) => (
        <div
          className={cn("nowrap", "replies-table-column-address")}
          title={address.value}
        >
          {address.value}
        </div>
      ),
    },
    {
      title: "Зарплата",
      dataIndex: "salary",
      key: "salary",
      render: (salary) => <span>{salary.to ? `${salary.to} ₽` : ""}</span>,
    },
    {
      title: (
        <div
          className="vacancies-container-candidateCount"
          title="Кол-во кандидатов"
        >
          <div className="nowrap">Кол-во кандидатов</div>
          <Tooltip placement="bottomLeft" title={CANDIDATE_COUNT_TOOLTIP}>
            <div>
              <Question color="#a6acb9" />
            </div>
          </Tooltip>
        </div>
      ),
      dataIndex: "candidateCount",
      key: "candidateCount",
    },
    {
      title: "Потребность",
      dataIndex: "peopleCountOpen",
      key: "peopleCountOpen",
      render: (peopleCountOpen) => {
        return (
          <div className="nowrap" title={peopleCountOpen}>
            {peopleCountOpen}
          </div>
        );
      },
    },
    {
      title: "Закрыто",
      dataIndex: "peopleCountClose",
      key: "peopleCountClose",
    },
    {
      title: "Департамент",
      dataIndex: "departmentId",
      key: "departmentId",
      render: (departmentId) => (
        <span className="vacancies-container-status">
          {departmentList.find((dep) => dep.id === Number(departmentId))
            ?.name ?? ""}
        </span>
      ),
    },
    {
      title: "Дата публикации",
      dataIndex: "publishedAt",
      key: "publishedAt",
      render: (publishedAt) =>
        publishedAt ? getDateFormat(publishedAt, "DD.MM.YY HH:mm") : null,
    },
    {
      title: "Работут",
      dataIndex: "publishedOnRabotut",
      key: "publishedOnRabotut",
      render: (publishedOnRabotut) => <Trigger trigger={publishedOnRabotut} />,
    },
    {
      title: "Авито",
      dataIndex: "publishedOnAvito",
      key: "publishedOnAvito",
      render: (publishedOnAvito) => <Trigger trigger={publishedOnAvito} />,
    },
    {
      title: "HeadHunter",
      dataIndex: "publishedOnHh",
      key: "publishedOnHh",
      render: (publishedOnHh) => <Trigger trigger={publishedOnHh} />,
    },
    ...(isReactivation
      ? [
          {
            title: "В работе по реактивации",
            dataIndex: "inReactivation",
            key: "inReactivation",
            render: (inReactivation) => <Trigger trigger={inReactivation} />,
          },
        ]
      : []),
  ];

  const getVacancies = useCallback(
    (payload: PrepareVacanciesPayload) => {
      const preparedPayload = prepareVacanciesPayload(payload);

      if (isReactivation) {
        return dispatch(
          fetchVacancyListByCategoryReactivation(preparedPayload)
        );
      }

      dispatch(fetchVacancyListByCategory(preparedPayload));
    },
    [isReactivation, dispatch]
  );

  const handleFilter = (filtersData: VacancyFiltersState) => {
    setVacancyFilters(filtersData);
    getVacancies({
      filters: filtersData,
      pagination: PAGINATION.VACANCIES,
      categoryId: activeCategory ?? 0,
      citiesList,
    });
  };

  const handleClick = useCallback(
    (
      categoryId: number,
      pagination: PaginationPayload = PAGINATION.VACANCIES
    ) => {
      const isNewCategory = categoryId !== activeCategory;

      if (isNewCategory) {
        reset(INITIAL_STATE_FILTERS_VACANCY);
      }

      dispatch(setActiveCategory(categoryId));
      setActiveCategoryIdStorage(categoryId);
      getVacancies({
        filters: isNewCategory ? INITIAL_STATE_FILTERS_VACANCY : vacancyFilters,
        pagination,
        categoryId,
        citiesList,
      });
    },
    [
      activeCategory,
      dispatch,
      setActiveCategoryIdStorage,
      getVacancies,
      reset,
      vacancyFilters,
      citiesList,
    ]
  );

  const handleRowClick = (vacancyId: number) => {
    history.push({
      pathname: `${ROUTE.VACANCY}/${vacancyId}`,
      search: isReactivation
        ? stringifyQuery({ fromReactivationVacancies: 1 })
        : undefined,
    });
  };

  useEffect(() => {
    if (activeCategoryIdStorage || (activeCategory && meta)) {
      getVacancies({
        filters,
        categoryId: activeCategoryIdStorage || activeCategory || 0,
        pagination: {
          offset: meta?.offset || PAGINATION.VACANCIES.offset,
          limit: meta?.limit || PAGINATION.VACANCIES.limit,
        },
        citiesList,
      });
    }
  }, []);

  useEffect(() => {
    if (activeCategoryIdStorage) {
      dispatch(setActiveCategory(activeCategoryIdStorage));
    }
  }, []);

  return (
    <div className="vacancies-container">
      {loading ? (
        <Spinner className="vacancies-container-spinner" loading={loading} />
      ) : (
        <>
          <div className="vacancies-container-plan-table">
            <PlanTable
              title="Персональный план и факт на день"
              dailyPlanList={dailyPlanList}
              positionCategories={positionCategories}
              activeCategory={activeCategory}
              onClick={handleClick}
            />
          </div>

          {currentCategoryById && (
            <div className="vacancies-container-list">
              <ContentLayout
                centralColumn={{
                  content: (
                    <>
                      <div className="vacancies-container-list-head">
                        <Title type="h4">{`Вакансии «${
                          currentCategoryById?.name ?? ""
                        }»`}</Title>
                        {userData && hasAcceptAccess(userData.role) && (
                          <VacancyFilters
                            className="vacancies-container-list-head-filters"
                            isReactivation={isReactivation}
                            loading={loading}
                            vacancyStatuses={vacancyStatus}
                            department={departmentList}
                            cities={citiesList}
                            control={control}
                            formState={formState}
                            onFilter={handleFilter}
                            handleSubmit={handleSubmit}
                            reset={reset}
                          />
                        )}
                      </div>
                      {vacanciesListLoading ? (
                        <Spinner
                          className="vacancies-container-list-spinner"
                          loading={vacanciesListLoading}
                        />
                      ) : (
                        <VacanciesTable
                          vacancies={vacancies}
                          columns={columns}
                          meta={meta}
                          disabledVacancyList={disabledVacancyList}
                          userId={userData?.id}
                          onRowClick={handleRowClick}
                          onPaginationClick={(pagination) =>
                            handleClick(Number(activeCategory), pagination)
                          }
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>
          )}

          {activeVacancy && userData && (
            <VacancyModals
              vacancy={activeVacancy}
              disabledList={disabledVacancyList}
              user={userData}
              open={Boolean(activeVacancy)}
              onCancel={() => dispatch(setActiveVacancyId(null))}
            />
          )}
        </>
      )}
    </div>
  );
};

import { PositionCategoryType, PositionType } from "graphql/types/types";

export const getPositionCategoryName = (
  positionCategories: PositionCategoryType[],
  position: PositionType
) => {
  return (
    positionCategories.find((item) => item.id === position.categoryId)?.name ??
    ""
  );
};

export const getVacancyPositionsName = (
  vacancyPositions: PositionType[],
  positionId: number
) => {
  return vacancyPositions.find((item) => item.id === positionId)?.name ?? "";
};

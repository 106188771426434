import { SpecializationType } from "graphql/types/types";

export const filterUniqueSpecializations = (
  specializations: SpecializationType[]
): SpecializationType[] => {
  const seenIds = new Set<string>();
  return specializations.filter((specialization) => {
    if (specialization.level === 1 || seenIds.has(specialization.id)) {
      return false;
    }
    seenIds.add(specialization.id);
    return true;
  });
};

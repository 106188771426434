import { transformToDaDataAddressSuggestion } from "common/helpers/transformDaData";
import { ProfileState } from "./index";
import { IUser } from "services/store/modules/user/types";

export const getInitialState = (user: IUser): ProfileState => {
  return {
    firstName: user?.firstName || "",
    secondName: user?.secondName || "",
    email: user?.email || "",
    phone: user?.phone || "",
    city: transformToDaDataAddressSuggestion({
      city: user.city,
      value: user.city,
    }),
    tz: user?.tz || "",
  };
};

export const getPayload = (formData: ProfileState, user: IUser) => {
  return {
    id: user?.id,
    form: {
      city: formData.city.value,
      firstName: formData.firstName,
      secondName: formData.secondName,
      phone: formData.phone,
      email: formData.email,
      tz: formData.tz,
      companyId: user.companyId,
      departmentId: user.departmentId,
      mangoId: user.mangoId,
      role: user.role,
    },
  };
};

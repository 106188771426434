import React from "react";
import "antd/dist/antd.min.css";
import { Popover, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import phone from "../assets/icons/phone-icon.svg";
import mail from "../assets/icons/mail-icon.svg";
import styled from "styled-components";
import { ROLE } from "../common/const/role";
import { IUser } from "../services/store/modules/user/types";
import { maskPhone } from "../common/utils";

interface AvatarPopoverProps {
  userDate?: IUser;
  id?: string;
  isGray?: boolean;
}

const NoneManager = styled.span`
  padding-left: 12px;
`;
const AvatarPopoverItem = styled.div`
  width: fit-content;
`;

export const AvatarPopover = ({ userDate, isGray }: AvatarPopoverProps) => {
  const content = (
    <>
      {userDate && Object.keys(userDate) && (
        <div className="popover-content">
          <div className="popover-info">
            <div>
              <div>
                <span className="popover-info__name">
                  {userDate?.firstName}
                </span>
                {userDate?.secondName && (
                  <span className="popover-info__name">
                    {" " + userDate?.secondName}
                  </span>
                )}
                {!!userDate?.role && (
                  <span
                    className={
                      !isGray ? "popover-info__role" : "popover-info__gray"
                    }
                  >
                    {ROLE?.[userDate?.role || ""] || "Не указана"}
                  </span>
                )}
              </div>
              {userDate?.departmentId ? (
                <span className="popover-info__department">
                  {userDate?.department?.name || "Департамент не указан"}
                </span>
              ) : (
                <span className="popover-info__department">
                  Департамент не указан
                </span>
              )}
            </div>
            <Avatar size="large" icon={<UserOutlined />} />
          </div>
          <div className="popover-actions">
            <a
              href={`mailto:${userDate?.email}`}
              className="popover-actions__email"
            >
              <img
                className="popover-actions__icons-email"
                src={mail}
                alt="mailIcon"
              />
              {userDate?.email || "Не указан"}
            </a>
            <a
              href={`tel:+${userDate?.phone}`}
              className="popover-actions__phone"
            >
              <img
                className="popover-actions__icons-phone"
                src={phone}
                alt="phoneIcon"
              />
              {userDate?.phone ? maskPhone(+userDate?.phone) : "Не указан"}
            </a>
          </div>
        </div>
      )}
    </>
  );

  return (
    <AvatarPopoverItem
      className="avatar-popover"
      onClick={(event) => event.stopPropagation()}
    >
      {userDate ? (
        <Popover placement="bottomRight" content={content} trigger="click">
          <div>
            <div>
              <span className="popover-info__name">
                {userDate?.firstName || "Не указан"}
              </span>
              <span
                className={
                  !isGray ? "popover-info__role" : "popover-info__gray"
                }
              >
                {ROLE?.[userDate?.role || ""] || "Не указана"}
              </span>
            </div>
            {!!userDate?.department?.name ? (
              <span className="popover-info__department">
                {userDate?.department?.name || "Департамент не указан"}
              </span>
            ) : (
              <span className="popover-info__department">
                Департамент не указан
              </span>
            )}
          </div>
        </Popover>
      ) : (
        <NoneManager>Не указан</NoneManager>
      )}
    </AvatarPopoverItem>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BoolSuccess,
  MutationChangeStatusNewReplyArgs,
  MutationHireNewReplyArgs,
  MutationTransferNewReplyListArgs,
  QueryReplyNewArgs,
  QueryReplyNewListArgs,
} from "graphql/types/types";
import { AsyncThunkConfig } from "services/store/types";
import { client as apollo } from "graphql/apollo";
import {
  ChangeStatusReplyResponse,
  HireReplyResponse,
  RepliesListResponse,
  ReplyResponse,
} from "./types";
import { REPLY_LIST } from "graphql/requests/query/replyList";
import { REPLY_ITEM } from "graphql/requests/query/reply";
import { HIRE_REPLY } from "graphql/requests/mutation/hireReply";
import { CHANGE_STATUS_REPLY } from "graphql/requests/mutation/changeStatusReply";
import { TRANSFER_REPLY_LIST } from "graphql/requests/mutation/transferReplyList";

export const getReplyList = createAsyncThunk<
  RepliesListResponse | null,
  QueryReplyNewListArgs,
  AsyncThunkConfig
>("replies/replyList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<RepliesListResponse>({
      query: REPLY_LIST,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getReply = createAsyncThunk<
  ReplyResponse,
  QueryReplyNewArgs,
  AsyncThunkConfig
>("replies/getReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<ReplyResponse>({
      query: REPLY_ITEM,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const hireReply = createAsyncThunk<
  HireReplyResponse,
  MutationHireNewReplyArgs,
  AsyncThunkConfig
>("replies/hireReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: HIRE_REPLY,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.networkError);
  }
});

export const changeStatusReply = createAsyncThunk<
  ChangeStatusReplyResponse,
  MutationChangeStatusNewReplyArgs,
  AsyncThunkConfig
>("replies/changeStatusReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: CHANGE_STATUS_REPLY,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const transferReplyList = createAsyncThunk<
  BoolSuccess,
  MutationTransferNewReplyListArgs,
  AsyncThunkConfig
>("replies/transferReplyList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: TRANSFER_REPLY_LIST,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { useAppDispatch } from "services/store/store";
import { fetchPositionsRelations } from "services/store/modules/settings/actions";
import { Vacancies } from "components/redezign/Vacancies";
import { fetchDailyPlanList } from "services/store/modules/vacanciesList/actions";

export const ReactivationVacanciesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(fetchDailyPlanList({ date: new Date() })),
      dispatch(fetchPositionsRelations()),
    ]);
  }, [dispatch]);

  return <ContentLayout centralColumn={{ content: <Vacancies /> }} />;
};

import React, { useState } from "react";
import { DaDataAddressSuggestion } from "react-dadata";

import { Button } from "components/UI/Button";
import { useAppDispatch } from "services/store/store";
import { createCity } from "services/store/modules/directory";
import { CityType } from "graphql/types/types";
import toaster from "components/UI/Notifications/Notification";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import { validationKey } from "../../helpers";
import { transformToCityType } from "common/helpers/transformDaData";

type Props = {
  list: CityType[];
};

export const CitiesForm: React.FC<Props> = ({ list }) => {
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<DaDataAddressSuggestion | undefined>(
    undefined
  );
  const [error, setError] = useState<string | null>(null);

  const handleClick = () => {
    setError(null);

    if (!validationKey(value?.data.city || "", list)) {
      return setError("Дубликат ключа");
    }

    dispatch(createCity({ form: transformToCityType(value) }))
      .then(() => {
        setValue(undefined);
        toaster.success({ title: "Изменения успешно сохранены" });
      })
      .catch(() => {
        toaster.error({ title: "Ошибка изменения" });
      });
  };

  return (
    <div>
      <FormSelectDadata
        label="Населенный пункт"
        count={10}
        filterFromBound="city"
        filterToBound="city"
        value={value}
        error={error || undefined}
        onChange={(e) => setValue(e)}
      />

      <Button disabled={!value} onClick={handleClick}>
        Добавить
      </Button>
    </div>
  );
};

import React from "react";
import cn from "classnames";

import { CandidateStatus as CandidateStatusType } from "graphql/types/types";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { COLOR_BY_CANDIDATE_STATUS } from "common/const/colors";

type Props = {
  status: CandidateStatusType;
  className?: string;
};

export const CandidateStatus: React.FC<Props> = ({ status, className }) => {
  return (
    <div
      title={CANDIDATE_STATUS_TYPE[status]}
      className={cn(className)}
      style={{ color: COLOR_BY_CANDIDATE_STATUS[status] }}
    >
      {CANDIDATE_STATUS_TYPE[status]}
    </div>
  );
};

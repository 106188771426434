import { createSlice, combineReducers } from "@reduxjs/toolkit";

import {
  CandidateAdaptationType,
  CandidateWithVacancyType,
} from "graphql/types/types";
import {
  fetchCandidateForAdaptationList,
  fetchCandidateForAdaptation,
} from "./actions";
import { getInitialState } from "../../helpers";

const candidateForAdaptationListSlice = createSlice({
  name: "candidateForAdaptationList",
  initialState: getInitialState<CandidateAdaptationType[]>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateForAdaptationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCandidateForAdaptationList.fulfilled,
        (state, { payload }) => {
          state.data = payload.candidateForAdaptationList.data;
          state.meta = payload.candidateForAdaptationList.meta;
          state.loading = false;
        }
      )
      .addCase(fetchCandidateForAdaptationList.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

const candidateForAdaptationSlice = createSlice({
  name: "candidateForAdaptation",
  initialState: getInitialState<CandidateWithVacancyType>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateForAdaptation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidateForAdaptation.fulfilled, (state, { payload }) => {
        state.data = payload.candidateForAdaptation;
        state.loading = false;
      })
      .addCase(fetchCandidateForAdaptation.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export const candidatesForAdaptationReducer = combineReducers({
  list: candidateForAdaptationListSlice.reducer,
  candidate: candidateForAdaptationSlice.reducer,
});

import {
  PositionCategoryType,
  PositionType,
  EmploymentType,
  CityType,
} from "graphql/types/types";

export const getPositionById = (
  positions: PositionType[],
  positionId: number
) => {
  return positions.find(({ id }) => id === positionId);
};

export const getCategoryById = (
  categories: PositionCategoryType[],
  categoryId: number
) => {
  return categories.find(({ id }) => id === categoryId);
};
export const getEmploymentById = (
  employments: EmploymentType[],
  employmentId: number
) => {
  return employments.find(({ id }) => id === employmentId);
};

export const getCityByName = (cityId: number, citiesList: CityType[]) =>
  citiesList.find(({ id }) => id === cityId)?.key || null;

import React, { useState } from "react";
import { useHistory } from "react-router";
import RowLayout from "layouts/RowLayout";
import { VacancyInfo } from "components/VacancyInfo";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectNewReplyStatuses } from "services/store/modules/directory";
import {
  VacancyType,
  ReplyNewClientType,
  ReplyNewStatuses,
} from "graphql/types/types";
import { hireReply } from "services/store/modules/replies/actions";
import toaster from "components/UI/Notifications/Notification";
import { setInProgressBy } from "services/store/modules/replies";
import { ReplyActionPanel } from "../ReplyActionPanel";
import { setCandidate } from "services/store/modules/candidate";
import { ERR_RESPONSE_ALREADY_IN_PROGRESS } from "common/const/responseErrors";
import { CommonErrorModal } from "components/CommonErrorModal";
import { IUser } from "services/store/modules/user/types";
import { selectReplyLoading } from "services/store/modules/replies/selectors";

interface Props {
  vacancy: VacancyType;
  reply: ReplyNewClientType;
  authData: IUser;
  className?: string;
}

export const ReplyInfo: React.FC<Props> = ({
  vacancy,
  reply,
  authData,
  className,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const newReplyStatuses = useAppSelector(selectNewReplyStatuses);
  const replyLoading = useAppSelector(selectReplyLoading);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleReplyVisibleChange = async () => {
    try {
      const res = await dispatch(hireReply({ replyId: reply.id })).unwrap();

      res.hireNewReply.inProgressBy &&
        dispatch(
          setInProgressBy({
            inProgressBy: res.hireNewReply.inProgressBy,
            status: res.hireNewReply.status,
          })
        );
      dispatch(setCandidate(reply.candidate));

      toaster.success({ title: "Отклик в работе" });
    } catch (error) {
      if (error.errors.includes(ERR_RESPONSE_ALREADY_IN_PROGRESS)) {
        setErrorMessage(error.message);
        return;
      }
      toaster.error({ title: error.message });
    }
  };

  const handleOk = () => {
    setErrorMessage(null);
    history.goBack();
  };
  return (
    <>
      <RowLayout
        className={className}
        leftSpan={19}
        leftComponent={
          <VacancyInfo
            hiddenActions={true}
            vacancy={vacancy}
            mapLoading={replyLoading}
            userData={authData}
          />
        }
        centerSpan={5}
        centerComponent={
          <ReplyActionPanel
            replyId={reply.id}
            vacancyId={vacancy.id}
            statuses={newReplyStatuses}
            status={reply.status}
            candidateStatus={reply.candidateStatus ?? ""}
            isDisabled={
              reply.inProgressBy === authData.id ||
              [ReplyNewStatuses.InWork, ReplyNewStatuses.Processed].includes(
                reply.status
              )
            }
            onVisibilityChange={handleReplyVisibleChange}
          />
        }
      />
      <CommonErrorModal
        open={Boolean(errorMessage)}
        message={errorMessage || ""}
        onOk={handleOk}
        onCancel={() => setErrorMessage(null)}
      />
    </>
  );
};

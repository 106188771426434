import React from "react";
import classNames from "classnames/dedupe";

import "./styles.scss";

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  type: "h1" | "h2" | "h3" | "h4" | "h5";
  color?: Color;
  className?: string;
}

type Color = "black" | "blue" | "grey";

const rootClassName = "ui-title";

const colorByType: Record<Color, string> = {
  black: "#000000",
  blue: "#0057AC",
  grey: "#A6ACB9",
};

export const Title: React.FC<Props> = ({
  type: Tag,
  color = "black",
  className,
  children,
  ...props
}) => {
  const titleClassNames = classNames(className, rootClassName, {
    [`${rootClassName}--${Tag}`]: true,
  });

  return (
    <Tag
      className={titleClassNames}
      title={children as string}
      {...props}
      style={{ color: colorByType[color] }}
    >
      {children}
    </Tag>
  );
};

import { RefusalReasonType, ReplyStatus } from "graphql/types/types";
import { CANDIDATE_STATUS_TYPE } from "../const/status";

/**
 * Подмена статусов https://jira.mytechhelp.ru/browse/RABT-253
 */
const REPLACED_STATUSES: string[] = [
  ReplyStatus.New,
  ReplyStatus.NotThrough,
  ReplyStatus.Callback,
];

export const replyStatusResolver = (
  status?: string,
  isCreatedAtBusinessHours?: boolean
) => {
  if (
    !isCreatedAtBusinessHours &&
    status &&
    REPLACED_STATUSES.includes(status)
  ) {
    return "TRANSFERRED_TO_CC";
  }
  return status as string;
};

export const getCandidateStatus = (
  status: string,
  refusalReasonsByKey: Record<string, RefusalReasonType>
): string => {
  const list = status.split(":");

  return list.length
    ? `${CANDIDATE_STATUS_TYPE[list[0]] || ""} ${list.length > 1 ? "-" : ""} ${
        refusalReasonsByKey[list[1]]?.name || ""
      }`
    : "";
};

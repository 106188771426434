import { TableProps } from "antd";
import cn from "classnames/dedupe";

import { Candidate, Manager, Report } from "common/commonTypes/reports";
import { getUtcDateFormat } from "common/utils/date";
import {
  VacancyStatus as VacancyStatusType,
  Source,
  UtmType,
} from "graphql/types/types";
import { VacancyStatus } from "components/VacancyStatus";
import { CandidateStatus } from "components/CandidateStatus";
import { SOURCE_TYPE } from "common/const/source";
import { UtmDisplay } from "../UtmDisplay";

export const columnsCallCenter: TableProps<Report>["columns"] = [
  {
    title: "Департамент",
    dataIndex: "department",
    key: "department",
    render: (department) => {
      const departmentName = department?.name ?? "";
      return (
        <div className="nowrap" title={departmentName}>
          {departmentName}
        </div>
      );
    },
  },
  {
    title: "ФИО менеджера",
    dataIndex: "manager",
    key: "manager",
    render: (manager: Manager) => {
      const { firstName = "", secondName = "" } = manager ?? {};
      const name = `${firstName} ${secondName}`;
      return (
        <div className="report-table-column-name" title={name}>
          {name}
        </div>
      );
    },
  },
  {
    title: "ID вакансии",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Вакансия",
    dataIndex: "name",
    key: "name",
    render: (name) => (
      <div className="nowrap" title={name}>
        {name}
      </div>
    ),
  },
  {
    title: "Проект",
    dataIndex: "callCenterInfo",
    key: "callCenterInfo",
    render: (callCenterInfo) => {
      const { project = "" } = callCenterInfo || {};
      return (
        <div
          className={cn("nowrap", "report-table-column-info")}
          title={project}
        >
          {project}
        </div>
      );
    },
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
    render: (address) => (
      <div
        className={cn("nowrap", "report-table-column-address")}
        title={address?.value}
      >
        {address?.value ?? ""}
      </div>
    ),
  },
  {
    title: "Потребность",
    dataIndex: "peopleCount",
    key: "peopleCount",
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (status: VacancyStatusType) => (
      <VacancyStatus status={status} className="nowrap" />
    ),
  },
  {
    title: "Дата и время взятия в работу",
    dataIndex: "dateStartWork",
    key: "dateStartWork",
    render: (dateStartWork) =>
      dateStartWork ? getUtcDateFormat(dateStartWork, "DD.MM.YY HH:mm") : null,
  },
  {
    title: "Дата и время окончания работы с вакансией",
    dataIndex: "dateEndWork",
    key: "dateEndWork",
    render: (dateEndWork) =>
      dateEndWork ? getUtcDateFormat(dateEndWork, "DD.MM.YY HH:mm") : null,
  },
  {
    title: "Дата и время открытия вакансии без обработки",
    dataIndex: "managerStartWork",
    key: "managerStartWork",
    render: (managerStartWork) =>
      managerStartWork
        ? getUtcDateFormat(managerStartWork, "DD.MM.YY HH:mm")
        : null,
  },
  {
    title: "Длительность вакансии в работе",
    dataIndex: "dateRangeWork",
    key: "dateRangeWork",
    render: (dateRangeWork) => (dateRangeWork ? `${dateRangeWork} ч.` : null),
  },
  {
    title: "Подобрано кандидатов системой",
    dataIndex: "cntCandidatesFind",
    key: "cntCandidatesFind",
  },
  {
    title: "Обзвон кандидатов количество",
    dataIndex: "cntCandidatesCalls",
    key: "cntCandidatesCalls",
  },
  {
    title: "Направлено кандидатов",
    dataIndex: "cntCandidatesDirected",
    key: "cntCandidatesDirected",
  },
  {
    title: "ФИО направленных кандидатов",
    dataIndex: "fioCandidatesDirected",
    key: "fioCandidatesDirected",
  },
  {
    title: "Размещена Rabotut.ru",
    dataIndex: "extraRbt",
    key: "extraRbt",
    render: (extraRbt) =>
      extraRbt?.remoteUrl ? (
        <a target="_blank" rel="noopener noreferrer" href={extraRbt.remoteUrl}>
          Ссылка
        </a>
      ) : null,
  },
  {
    title: "Размещена Авито",
    dataIndex: "extraAvito",
    key: "extraAvito",
    render: (extraAvito) =>
      extraAvito?.remoteUrl ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={extraAvito.remoteUrl}
        >
          Ссылка
        </a>
      ) : null,
  },
  {
    title: "Размещена HH.ru",
    dataIndex: "extraHH",
    key: "extraHH",
    render: (extraHH) =>
      extraHH?.remoteUrl ? (
        <a target="_blank" rel="noopener noreferrer" href={extraHH.remoteUrl}>
          Ссылка
        </a>
      ) : null,
  },
];

export const columnsPilot: TableProps<Report>["columns"] = [
  {
    title: "ID вакансии",
    dataIndex: "vacancyId",
    key: "vacancyId",
  },
  {
    title: "Название вакансии",
    dataIndex: "positionName",
    key: "positionName",
    render: (positionName) => (
      <div className="nowrap" title={positionName}>
        {positionName}
      </div>
    ),
  },
  {
    title: "Потребность",
    dataIndex: "candidateCount",
    key: "candidateCount",
  },
  {
    title: "Проект",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Департамент",
    dataIndex: "departamentName",
    key: "departamentName",
  },
  {
    title: "ID менеджера КЦ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row  report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ managerId }) => (
        <div className="report-table-cell">{managerId}</div>
      )),
  },
  {
    title: "ФИО менеджера КЦ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ managerFullName }) => (
        <div className="report-table-cell nowrap">{managerFullName}</div>
      )),
  },
  {
    title: "ID кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateId }) => (
        <div className="report-table-cell nowrap">{candidateId}</div>
      )),
  },
  {
    title: "ФИО кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateFullName }) => (
        <div className="report-table-cell nowrap">{candidateFullName}</div>
      )),
  },
  {
    title: "Текущий статус кандидата",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateStatus }) => (
        <CandidateStatus
          className="report-table-cell nowrap"
          status={candidateStatus}
        />
      )),
  },
  {
    title: "Дата взятия вакансии в работу ",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ vacancyTakenDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {vacancyTakenDate
              ? getUtcDateFormat(vacancyTakenDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
  {
    title: "Дата установки статуса «Кандидат»",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ candidateStatusDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {candidateStatusDate
              ? getUtcDateFormat(candidateStatusDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
  {
    title: "Дата установки статуса «В архиве»",
    dataIndex: "candidates",
    key: "candidates",
    className: "report-table-row report-table-row--with",
    render: (candidates: Candidate[]) =>
      candidates.map(({ archivedStatusDate }) => {
        return (
          <div className="report-table-cell nowrap">
            {archivedStatusDate
              ? getUtcDateFormat(archivedStatusDate, "DD.MM.YY HH:mm")
              : null}
          </div>
        );
      }),
  },
];

export const columnsReply: TableProps<Report>["columns"] = [
  {
    title: "Дата и время поступления отклика(МСК)",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: Date) => (
      <div className="report-table-cell nowrap">
        {createdAt ? getUtcDateFormat(createdAt, "DD.MM.YY HH:mm") : ""}
      </div>
    ),
  },
  {
    title: "Дата и время поступления отклика(МПП)",
    dataIndex: "createdAtManagerTimezone",
    key: "createdAtManagerTimezone",
    render: (createdAtManagerTimezone: Date) => (
      <div className="report-table-cell nowrap">
        {createdAtManagerTimezone
          ? getUtcDateFormat(createdAtManagerTimezone, "DD.MM.YY HH:mm")
          : ""}
      </div>
    ),
  },
  {
    title: "Статус отклика",
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <div className="report-table-cell nowrap">{status}</div>
    ),
  },
  {
    title: "ID вакансии в CRM RBT",
    dataIndex: "vacancyId",
    key: "vacancyId",
  },
  {
    title: "ФИО МПП вакансии",
    dataIndex: "managerFio",
    key: "managerFio",
    render: (managerFio: string) => (
      <div className="nowrap" title={managerFio}>
        {managerFio}
      </div>
    ),
  },
  {
    title: "Номер телефона",
    dataIndex: "phone",
    key: "phone",
    render: (phone: string) => (
      <div className="nowrap" title={phone}>
        {phone}
      </div>
    ),
  },
  {
    title: "Департамент МПП вакансии",
    dataIndex: "departamentName",
    key: "departamentName",
    render: (departamentName: string) => (
      <div className="nowrap" title={departamentName}>
        {departamentName}
      </div>
    ),
  },
  {
    title: "Адрес вакансии",
    dataIndex: "vacancyAddress",
    key: "vacancyAddress",
    render: (vacancyAddress: string | null) => (
      <div className="nowrap" title={vacancyAddress || ""}>
        {vacancyAddress || ""}
      </div>
    ),
  },
  {
    title: "Торговая точка",
    dataIndex: "direction",
    key: "direction",
    render: (direction: string) => (
      <div className="nowrap" title={direction || ""}>
        {direction || ""}
      </div>
    ),
  },
  {
    title: "Должность",
    dataIndex: "positionName",
    key: "positionName",
    render: (positionName: string | null) => (
      <div className="nowrap" title={positionName || ""}>
        {positionName || ""}
      </div>
    ),
  },
  {
    title: "ФИО кандидата",
    dataIndex: "candidateFio",
    key: "candidateFio",
    render: (candidateFio: string) => (
      <div className="nowrap" title={candidateFio}>
        {candidateFio}
      </div>
    ),
  },
  {
    title: "Источник отклика",
    dataIndex: "replySource",
    key: "replySource",
    render: (replySource: Source) => (
      <div className="nowrap" title={replySource ? replySource.toString() : ""}>
        {SOURCE_TYPE[replySource]}
      </div>
    ),
  },
  {
    title: "UTM",
    dataIndex: "utm",
    key: "utm",
    render: (utm: UtmType) => <UtmDisplay utm={utm} />,
  },
  {
    title: "Менеджер который обрабатывал отклик",
    dataIndex: "replyManagerProcessFio",
    key: "replyManagerProcessFio",
    render: (replyManagerProcessFio: string) => (
      <div className="nowrap" title={replyManagerProcessFio}>
        {replyManagerProcessFio}
      </div>
    ),
  },
  {
    title: "Департамент менеджера, который обрабатывал отклик",
    dataIndex: "replyManagerProcessDepartament",
    key: "replyManagerProcessDepartament",
    render: (replyManagerProcessDepartament: string) => (
      <div className="nowrap" title={replyManagerProcessDepartament}>
        {replyManagerProcessDepartament}
      </div>
    ),
  },
  {
    title: "Дата и время взятия отклика в работу",
    dataIndex: "startWorkAt",
    key: "startWorkAt",
    render: (startWorkAt: Date) => (
      <div className="report-table-cell nowrap">
        {startWorkAt ? getUtcDateFormat(startWorkAt, "DD.MM.YY HH:mm") : ""}
      </div>
    ),
  },
  {
    title: "Дата и время окончания работы с откликом",
    dataIndex: "endWorkAt",
    key: "endWorkAt",
    render: (endWorkAt: Date) => (
      <div className="report-table-cell nowrap">
        {endWorkAt ? getUtcDateFormat(endWorkAt, "DD.MM.YY HH:mm") : ""}
      </div>
    ),
  },
  {
    title: "Статус кандидата после обработки отклика",
    dataIndex: "candidateStatus",
    key: "candidateStatus",
    render: (candidateStatus: string) => (
      <div className="report-table-cell nowrap">{candidateStatus}</div>
    ),
  },
  {
    title: "ID вакансии в CRM RBT, на которую направлен кандидат",
    dataIndex: "vacancyIdDirected",
    key: "vacancyIdDirected",
  },
];

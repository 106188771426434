import React from "react";
import { useHistory } from "react-router-dom";

import { ColumnLayout } from "layouts/ColumnLayout";
import { VacancyInfo } from "components/VacancyInfo";
import { CandidateType, CandidateWithVacancyType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";
import { CandidatesTable } from "components/CandidatesTable";
import { Title } from "components/UI/Title";
import { CandidateActions } from "components/CandidateActions";
import { ROUTE } from "routes";

import "./styles.scss";

type Props = {
  candidateId: string;
  candidateForAdaptation: CandidateWithVacancyType;
  authData: IUser;
};

export const CandidateForAdaptation: React.FC<Props> = ({
  candidateId,
  candidateForAdaptation,
  authData,
}) => {
  const history = useHistory();

  const candidate = candidateForAdaptation as CandidateType;

  const handleRowClick = () => {
    history.push({
      pathname: `${ROUTE.CANDIDATE}/${candidateId}`,
    });
  };

  return (
    <ColumnLayout className="candidate-reply">
      <div className="candidate-reply-header">
        {candidateForAdaptation.vacancy && (
          <VacancyInfo
            className="candidate-reply-vacancy"
            hiddenActions={true}
            vacancy={candidateForAdaptation.vacancy}
            userData={authData}
          />
        )}
        <CandidateActions
          className="candidate-reply-actions"
          candidateId={candidateId}
          vacancyId={candidateForAdaptation.vacancy?.id}
          candidate={candidate}
          withCallButton={false}
          withHistory={false}
          withRecommendation={false}
        />
      </div>

      <div className="candidate-reply-wrapper">
        <Title type="h4">Анкета кандидата</Title>

        <CandidatesTable
          mode="reply"
          candidatesList={[candidate]}
          handleRowClick={handleRowClick}
        />
      </div>
    </ColumnLayout>
  );
};

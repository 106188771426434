import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyState, UpdatePositionCountResponse } from "./types";
import { RootState } from "../../rootReducer";
import { client as apollo } from "../../../../graphql/apollo";
import { COMPANY } from "../../../../graphql/requests/query/company";
import {
  CompanyInputType,
  CompanySettingsInputType,
  CompanySettingsType,
  CompanyType,
  MutationUpdatePositionCountArgs,
} from "../../../../graphql/types/types";
import { UPDATE_COMPANY } from "../../../../graphql/requests/mutation/updateCompany";
import toaster from "components/UI/Notifications/Notification";
import { COMPANY_SETTINGS } from "../../../../graphql/requests/query/companySettings";
import { SET_COMPANY_SETTINGS } from "../../../../graphql/requests/mutation/setCompanySettings";
import { UPDATE_POSITION_COUNT } from "../../../../graphql/requests/mutation/updatePositionCount";
import { AsyncThunkConfig } from "services/store/types";

export const initialState: ICompanyState = {
  companyData: {
    id: -1,
    name: "",
    logo: "",
    blocked: false,
    description: "",
    email: "",
    phone: "",
    shortDescription: "",
    stats: {
      userCount: 0,
      vacancyCount: 0,
      replyCount: 0,
    },
    owner: {
      city: "",
      email: "",
      firstName: "",
      id: 0,
      phone: "",
      secondName: "",
    },
    ownerId: 0,
  },
  isCompanyDataLoaded: false,
  companySettings: undefined,
  isLoadingSettings: false,
};

export const companyModule = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany(state: ICompanyState, { payload }: PayloadAction<CompanyType>) {
      state.companyData = payload;
    },
    setCompanyDataLoaded(
      state: ICompanyState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isCompanyDataLoaded = payload;
    },
    setCompanySettings(
      state: ICompanyState,
      { payload }: PayloadAction<CompanySettingsType>
    ) {
      state.companySettings = payload;
    },
    setLoadingStateSettings(
      state: ICompanyState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isLoadingSettings = payload;
    },
  },
});
// * mutations
export const {
  setCompany,
  setCompanyDataLoaded,
  setCompanySettings,
  setLoadingStateSettings,
} = companyModule.actions;

// * getters
export const companyData = (state: RootState) => state.company.companyData;
export const isCompanyDataLoaded = (state: RootState) =>
  state.company.isCompanyDataLoaded;
export const companySettings = (state: RootState) =>
  state.company.companySettings;
export const isLoadingCompanySettings = (state: RootState) =>
  state.company.isLoadingSettings;

// * actions
export const loadCompany = createAsyncThunk(
  "company/load",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setCompanyDataLoaded(false));
    try {
      const res = await apollo.query({
        query: COMPANY,
      });
      dispatch(setCompany(res.data.company));
    } catch (err) {
      rejectWithValue(err.message);
    } finally {
      dispatch(setCompanyDataLoaded(true));
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async (
    payload: { form: CompanyInputType; id: number },
    { rejectWithValue }
  ) => {
    try {
      await apollo.mutate({
        mutation: UPDATE_COMPANY,
        variables: {
          form: payload.form,
          id: payload.id,
        },
      });
      toaster.success({ title: "Изменения успешно сохранены" });
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const fetchCompanySettings = createAsyncThunk(
  "company/fetchCompanySettings",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingStateSettings(true));

      const res = await apollo.query<{ companySettings: CompanySettingsType }>({
        query: COMPANY_SETTINGS,
      });
      if (res.data?.companySettings) {
        dispatch(setCompanySettings(res.data.companySettings));
      }
    } catch (err) {
      rejectWithValue(err.message);
    } finally {
      dispatch(setLoadingStateSettings(false));
    }
  }
);

export const updateCompanySetting = createAsyncThunk(
  "company/updateCompanySetting",
  async (payload: CompanySettingsInputType, { rejectWithValue }) => {
    try {
      await apollo.mutate({
        mutation: SET_COMPANY_SETTINGS,
        variables: {
          form: payload,
        },
      });
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);
export const updatePositionCount = createAsyncThunk<
  UpdatePositionCountResponse | null | undefined,
  MutationUpdatePositionCountArgs,
  AsyncThunkConfig
>("company/updatePositionCount", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<UpdatePositionCountResponse>({
      mutation: UPDATE_POSITION_COUNT,
      variables: payload,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export default companyModule.reducer;

import React, { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInput } from "components/UI/Form/FormInput";
import Input from "components/UI/Input";
import { FormSelect } from "components/UI/Form/FormSelect";
import { Button } from "components/UI/Button";
import { OPTION_GENDER } from "common/const/options";
import { schema } from "./validation";

import "./styles.scss";

interface Props {}

export type FiltersState = {
  position: string;
  age: string;
  city: string;
  salaryFrom: string;
  salaryTo: string;
  sex: string;
};

const initialState: FiltersState = {
  position: "",
  age: "",
  city: "",
  salaryFrom: "",
  salaryTo: "",
  sex: "",
};

export const SearchResumeFilters: React.FC<Props> = () => {
  const { handleSubmit, control } = useForm<FiltersState>({
    defaultValues: initialState,
    // @ts-expect-error
    resolver: yupResolver(schema),
  });

  const updateForm: SubmitHandler<FiltersState> = useCallback(
    async (formData) => {
      Promise.resolve(formData);
    },
    []
  );

  return (
    <div className="resume-filters">
      <Controller
        name="position"
        control={control}
        render={({ field }) => (
          <FormInput
            placeholder="Введите название"
            label="Специальность"
            style={{ width: "260px" }}
            {...field}
          />
        )}
      />
      <Controller
        name="salaryFrom"
        control={control}
        render={({ field }) => (
          <FormInput
            label="Ожидаемая ЗП"
            style={{ width: "160px" }}
            placeholder="От"
            type="number"
            {...field}
          />
        )}
      />
      <Controller
        name="salaryTo"
        control={control}
        render={({ field }) => (
          <Input
            placeholder="До"
            style={{ width: "160px" }}
            type="number"
            {...field}
          />
        )}
      />
      <Controller
        name="age"
        control={control}
        render={({ field }) => (
          <FormInput
            placeholder="Укажите возраст"
            label="Возраст"
            style={{ width: "160px" }}
            type="number"
            {...field}
          />
        )}
      />
      <Controller
        name="sex"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label={"Пол"}
            options={OPTION_GENDER}
            style={{ width: "160px" }}
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <FormInput
            placeholder="Введите название"
            label="Город"
            style={{ width: "260px" }}
            {...field}
          />
        )}
      />

      <Button style={{ width: "130px" }} onClick={handleSubmit(updateForm)}>
        Поиск
      </Button>
    </div>
  );
};

import React from "react";
import cn from "classnames/dedupe";

import "./styles.scss";

interface Props {
  label: string;
  className?: string;
  required?: boolean;
  requiredText?: string;
}

export const FormLabel: React.FC<Props> = ({
  label,
  required,
  requiredText,
  className,
}) => (
  <span className={cn("ui-form-label", className)}>
    <span className="ui-form-label-name">{label}</span>
    {required && (
      <span className="ui-form-label-required">
        *
        {requiredText && (
          <span className="ui-form-label-required-text"> {requiredText}</span>
        )}
      </span>
    )}
  </span>
);

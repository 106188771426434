import React, { FC } from "react";
import { UtmType } from "graphql/types/types";

type UtmDisplayProps = {
  utm: UtmType;
};

export const UtmDisplay: FC<UtmDisplayProps> = ({ utm }) => {
  const entries = Object.entries(utm);
  return (
    <>
      {entries.map(([key, value]) => {
        return (
          <div key={key}>
            {key}: {value || ""}
          </div>
        );
      })}
    </>
  );
};

import React from "react";

import { Spinner } from "components/UI/Spinner";
import { FormCandidate } from "components/FormCandidate";
import { CandidateType } from "graphql/types/types";
import { authInfo } from "services/store/modules/auth";
import { useAppSelector } from "services/store/store";
import { isOwner } from "common/helpers/role";

import "./styles.scss";

type Props = {
  candidate: CandidateType | null;
  loading: boolean;
};

export const Candidate: React.FC<Props> = ({ candidate, loading }) => {
  const { role } = useAppSelector(authInfo) || {};
  return (
    <div className="candidate-container">
      {loading ? (
        <Spinner className="candidate-container-spinner" loading={loading} />
      ) : (
        candidate?.id && (
          <FormCandidate
            className="form-candidate-scrollable global-scrollbar"
            candidate={candidate}
            disabled={role ? isOwner(role) : false}
          />
        )
      )}
    </div>
  );
};

import { JbVacancyState } from "graphql/types/types";

export const avitoBusinessAreaList = [
  {
    id: 1,
    name: "IT, интернет, телеком",
  },
  {
    id: 13,
    name: "Медицина, фармацевтика",
  },
  {
    id: 17,
    name: "Продажи",
  },
  {
    id: 19,
    name: "Страхование",
  },
  {
    id: 21,
    name: "Транспорт, логистика",
  },
  {
    id: 14,
    name: "Образование, наука",
  },
  {
    id: 20,
    name: "Строительство",
  },
  {
    id: 22,
    name: "Туризм, рестораны",
  },
  {
    id: 24,
    name: "Фитнес, салоны красоты",
  },
  {
    id: 15,
    name: "Без опыта, студенты",
  },
  {
    id: 7,
    name: "Автомобильный бизнес",
  },
  {
    id: 2,
    name: "Бухгалтерия, финансы",
  },
  {
    id: 9,
    name: "Высший менеджмент",
  },
  {
    id: 16,
    name: "Госслужба, НКО",
  },
  {
    id: 10124,
    name: "ЖКХ, эксплуатация",
  },
  {
    id: 11,
    name: "Искусство, развлечения",
  },
  {
    id: 12,
    name: "Консультирование",
  },
  {
    id: 3,
    name: "Маркетинг, реклама, PR",
  },
  {
    id: 8,
    name: "Охрана, безопасность",
  },
  {
    id: 6,
    name: "Управление персоналом",
  },
  {
    id: 23,
    name: "Юриспруденция",
  },
  {
    id: 4,
    name: "Административная работа",
  },
  {
    id: 5,
    name: "Банки, инвестиции",
  },
  {
    id: 18,
    name: "Производство, сырьё, с/х",
  },
  {
    id: 27,
    name: "Домашний персонал",
  },
];

export const avitoExperienceList = [
  {
    key: "noMatter",
    name: "Не важно",
  },
  {
    key: "moreThan1",
    name: "Более 1 года",
  },
  {
    key: "moreThan3",
    name: "Более 3 лет",
  },
  {
    key: "moreThan5",
    name: "Более 5 лет",
  },
  {
    key: "moreThan10",
    name: "Более 10 лет",
  },
];

export enum PublicationStatus {
  Created = "created",
  Activated = "activated",
  Archived = "archived",
  Blocked = "blocked",
  Closed = "closed",
  Expired = "expired",
  Rejected = "rejected",
  Unblocked = "unblocked",
  Process = "process",
  Error = "error",
  Old = "old",
  Removed = "removed",
  Active = "active",
  NotAuthorized = "NotAuthorized",
}

type PublicationStatuses = Exclude<
  PublicationStatus,
  PublicationStatus.Unblocked
>;

export const AVITO_LOGO =
  "https://storage.yandexcloud.net/rabotut-s3-images/logo_rabotut_avito_new.jpg";

export const ROOT_ERROR_STATUS =
  "Выгрузка невозможна, так как не авторизован корневой аккаунт вашего департамента в Авито. " +
  "Сохраните изменения в вакансии и запросите авторизацию у владельца корневого аккаунта.";

const getAuthLink = (isAvito: boolean, host: string) => {
  const platform = isAvito ? "profileAvito" : "profileHh";

  return `<a href="/profile/${platform}">https://${host}/profile/${platform}</a>`;
};

export const publicationStatuses = (
  isAvito: boolean,
  host: string,
  managerName: string
): Record<PublicationStatuses, string> => ({
  [PublicationStatus.Process]: "Вакансия в процессе публикации",
  [PublicationStatus.Active]: "Вакансия опубликована",
  [PublicationStatus.Created]: "Вакансия опубликована",
  [PublicationStatus.Error]: `Для выгрузки на ${
    isAvito ? "Авито" : "HeadHunter"
  } дождитесь одобрения модератором и смены статуса на "Опубликовано"`,
  [PublicationStatus.Rejected]: "Публикация вакансии отклонена",
  [PublicationStatus.Blocked]: "Публикация вакансии заблокирована",
  [PublicationStatus.Closed]: "Вакансия закрыта",
  [PublicationStatus.Archived]: "Вакансия в архиве",
  [PublicationStatus.Old]: "Вакансия в архиве",
  [PublicationStatus.Expired]: "Срок публикации истек",
  [PublicationStatus.Removed]: "Вакансия удалена",
  [PublicationStatus.Activated]: "Вакансия уже размещена",
  [PublicationStatus.NotAuthorized]:
    `Выгрузка невозможна, так как ${managerName} не авторизован/a в ${
      isAvito ? "Авито" : "HeadHunter"
    }. ` +
    `Сохраните изменения в вакансии и пройдите авторизацию здесь: ${getAuthLink(
      isAvito,
      host
    )}`,
});

export const ERROR_STATUSES = [
  PublicationStatus.Error,
  PublicationStatus.Blocked,
  PublicationStatus.Expired,
  PublicationStatus.Rejected,
  PublicationStatus.Archived,
  PublicationStatus.Removed,
  PublicationStatus.Old,
];

export const publicationMessageByState: Record<JbVacancyState, string> = {
  [JbVacancyState.Archived]: "Архивирована",
  [JbVacancyState.Archiving]: "В процессе архивации",
  [JbVacancyState.Blocked]: "Заблокирована",
  [JbVacancyState.Editing]: "В процессе обновления",
  [JbVacancyState.Published]: "Опубликована",
  [JbVacancyState.Publishing]: "В процессе публикации",
  [JbVacancyState.Rejected]: "Отклонена",
  [JbVacancyState.Created]: "В процессе публикации",
  [JbVacancyState.Error]: "Ошибка",
};

export const DEFAULT_AVITO_REGISTRATION_METHOD = [
  "gph_individual",
  "gph_self_employed",
];

export const DEFAULT_AVITO_BONUS = [
  "pitanie",
  "uniform",
  "skidki_v_kompanii",
  "podarki_detiam_na_prazdniki",
  "obuchenie",
];

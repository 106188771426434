import React from "react";
import type { UploadProps } from "antd";
import { Upload as UploadAntd, UploadFile as UploadFileAntd } from "antd";

export interface Props extends UploadProps<File> {}
export type UploadFile = UploadFileAntd<File>;

export const Upload: React.FC<Props> = ({ children, ...props }) => (
  <UploadAntd {...props}>{children}</UploadAntd>
);

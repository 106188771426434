import React, { useState } from "react";

import { Tabs, Props as TabsProps } from "components/UI/Tabs";
import { SearchResume } from "../SearchResume";
import { SearchCreate } from "../SearchCreate";
import { SearchDownload } from "../SearchDownload";

import "./styles.scss";

type ActiveKey = "search" | "create" | "download";

export const ColdSearchVacancyContent: React.FC = () => {
  const [activeKey, setActiveKey] = useState<ActiveKey>("search");

  const handleTabClick = (key: string) => {
    setActiveKey(key as ActiveKey);
  };
  const tabs: TabsProps["items"] = [
    {
      key: "search",
      label: "Поиск резюме",
      children: <SearchResume />,
    },
    {
      key: "download",
      label: "Загрузить",
      children: <SearchDownload />,
    },
    {
      key: "create",
      label: "Создать",
      children: <SearchCreate />,
    },
  ];

  return (
    <Tabs
      className="search-tabs"
      defaultActiveKey={"search"}
      activeKey={activeKey}
      onTabClick={handleTabClick}
      items={tabs}
    />
  );
};

import { useRef, useEffect, RefObject, useCallback, useState } from "react";

export default function useDraggableScroll(
  ref: RefObject<HTMLElement> | null,
  options: {
    direction?: "vertical" | "horizontal" | "both";
    onDragStart?: () => void;
    onDragEnd?: () => void;
  } = { direction: "both" }
) {
  const { direction, onDragStart, onDragEnd } = options;

  let initialPosition = useRef({
    scrollTop: 0,
    scrollLeft: 0,
    mouseX: 0,
    mouseY: 0,
  }).current;

  const [isDragging, setIsDragging] = useState(false);

  const mouseMoveHandler = useCallback(
    (event: MouseEvent) => {
      if (ref?.current) {
        const { mouseX, mouseY, scrollLeft, scrollTop } = initialPosition;
        const dx = event.clientX - mouseX;
        const dy = event.clientY - mouseY;
        if (direction !== "horizontal") ref.current.scrollTop = scrollTop - dy;
        if (direction !== "vertical") ref.current.scrollLeft = scrollLeft - dx;
        setIsDragging(true);
      }
    },
    [direction, initialPosition, ref]
  );

  const mouseUpHandler = useCallback(() => {
    if (ref?.current) {
      ref.current.style.cursor = "grab";
    }

    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    setTimeout(() => setIsDragging(false), 0);
    onDragEnd?.();
  }, [mouseMoveHandler, onDragEnd, ref]);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (ref?.current) {
        initialPosition.scrollLeft = ref.current.scrollLeft;
        initialPosition.scrollTop = ref.current.scrollTop;
        initialPosition.mouseX = event.clientX;
        initialPosition.mouseY = event.clientY;

        ref.current.style.cursor = "grabbing";
        ref.current.style.userSelect = "none";

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
        if (onDragStart) onDragStart();
      }
    },
    [initialPosition, mouseMoveHandler, mouseUpHandler, onDragStart, ref]
  );

  useEffect(() => {
    if (ref?.current) {
      ref.current.style.cursor = "grab";
    }
  }, [ref]);

  return { onMouseDown, isDragging };
}

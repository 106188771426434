import React, { useEffect } from "react";

import { PositionsMap } from "./components/PositionsMap";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { fetchPositionsRelations } from "services/store/modules/settings/actions";
import { selectPositionsRelations } from "services/store/modules/settings/selectors";

export const PositionsMapPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: positionsRelations,
    error,
    loading,
  } = useAppSelector(selectPositionsRelations);

  useEffect(() => {
    dispatch(fetchPositionsRelations());
  }, []);

  return (
    <>
      {positionsRelations?.length && !loading ? (
        <PositionsMap positionsRelations={positionsRelations} />
      ) : (
        <NotificationStatus
          loading={loading}
          error={error}
          notFoundText="Справочник должностей не найден"
        />
      )}
    </>
  );
};

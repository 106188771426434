import React, { useCallback } from "react";
import {
  Control,
  Controller,
  FormState,
  SubmitHandler,
  UseFormReset,
} from "react-hook-form";
import { FormSelect } from "components/UI/Form/FormSelect";
import { FormRangePicker } from "components/UI/Form/FormRangePicker";
import { Filters } from "components/Filters";
import { DepartmentType } from "graphql/types/types";
import { filterOption, getSelectOptions } from "components/UI/Select/helpers";
import { IUser } from "services/store/modules/user/types";
import { hasHrbpAccess, isAdmin } from "common/helpers/role";
import { ReplyFiltersInitialState } from "services/store/modules/replies/types";

import { REPLY_STATUSES_DICTIONARY } from "common/const/options";
import { INITIAL_STATE_FILTERS_REPlY } from "common/const/filters";

type Props = {
  loading: boolean;
  department: DepartmentType[];
  users: IUser[];
  onFilter: (filtersData: ReplyFiltersInitialState) => void;
  handleSubmit: (
    callback: SubmitHandler<ReplyFiltersInitialState>
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  reset: UseFormReset<ReplyFiltersInitialState>;
  control: Control<ReplyFiltersInitialState>;
  formState: FormState<ReplyFiltersInitialState>;
  userRole: IUser["role"];
  setReplyFilters: (filters: ReplyFiltersInitialState) => void;
};

export const ReplyFilters: React.FC<Props> = ({
  loading,
  department,
  onFilter,
  handleSubmit,
  reset,
  control,
  formState,
  userRole,
  users,
  setReplyFilters,
}) => {
  const onSubmit = useCallback<SubmitHandler<ReplyFiltersInitialState>>(
    (filtersData: ReplyFiltersInitialState) => {
      onFilter(filtersData);
    },
    [onFilter]
  );

  const handleReset = () => {
    setReplyFilters(INITIAL_STATE_FILTERS_REPlY);
    reset(INITIAL_STATE_FILTERS_REPlY);
    onFilter(INITIAL_STATE_FILTERS_REPlY);
  };

  return (
    <Filters
      isDirty={true}
      isError={Boolean(Object.values(formState.errors).length)}
      count={Object.values(formState.dirtyFields).length}
      loading={loading}
      onSubmit={handleSubmit(onSubmit)}
      onReset={handleReset}
    >
      <Controller
        name="creationDateRange"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormRangePicker
            label="Дата создания"
            placeholder={["c", "по"]}
            format="DD-MM-YYYY"
            style={{ width: "370px" }}
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Статус отклика"
            placeholder="Выберите статус(ы)"
            mode="multiple"
            options={getSelectOptions(REPLY_STATUSES_DICTIONARY)}
            {...field}
          />
        )}
      />

      {isAdmin(userRole) && (
        <Controller
          name="departmentId"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Департамент"
              placeholder="Выберите департамент"
              options={getSelectOptions(department)}
              {...field}
            />
          )}
        />
      )}
      {hasHrbpAccess(userRole) && (
        <Controller
          name="userId"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Менеджер"
              placeholder="Выберите менеджера"
              showSearch
              filterOption={filterOption}
              optionFilterProp="children"
              options={getSelectOptions(users)}
              {...field}
            />
          )}
        />
      )}
    </Filters>
  );
};

import {
  CandidateType,
  ManagerType,
  OwnerType,
  ReplyNewClientType,
  UserClientType,
  UserType,
} from "graphql/types/types";

export const getTrueName = (isTrue: boolean) => (isTrue ? "Да" : "Нет");

type GetFullNamePayload =
  | ManagerType
  | OwnerType
  | UserType
  | ReplyNewClientType
  | CandidateType
  | UserClientType;

type NamesType = {
  firstName?: string | null;
  secondName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
};

export const getFullName = (
  payload?: GetFullNamePayload & NamesType
): string => {
  if (!payload) return "";

  const { firstName = "", secondName, lastName, patronymic = "" } = payload;
  const secondOrLastName = secondName || lastName || "";

  return `${firstName} ${secondOrLastName} ${patronymic}`.trim();
};

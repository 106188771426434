import { IOktellCallData } from "common/helpers/oktellResponseParser";
import { ICall } from "../calls/types";
import {
  AvitoMessageType,
  HotCallType,
  SocketMessageType,
} from "graphql/types/types";

export interface SocketsState {
  statusSocket: string;
  statusWorker: string;
  workerClientId: string;
  oktellStatus: string;
  isActiveCall: boolean;
  currentOktellCallData?: IOktellCallData;
  oktellUserLogin: string;
  currentHotCallData?: ICall;
}

export interface IOktellAuthRequestPayload {
  id: number;
  form: {
    oktellId?: string;
    oktellLogin: string;
    oktellPassword: string;
  };
}

export type MessagesContent = {
  idVacancy?: number;
  idReply?: number;
  managersIds: number[];
  inWork: boolean;
};

export type MessagesResult = {
  messages: SocketMessageType;
};

export enum Channel {
  EnableVacancy = "enable-vacancy",
}

export type AvitoInfoResult = {
  avitoInfo: AvitoMessageType;
};

export type CallsResult = {
  calls: HotCallType;
};

export enum ReportName {
  ReportCallCenter = "report_call_center",
  ReportPilot = "report_pilot",
  ReportMango = "report_mango",
  ReportReply = "report_reply",
}

export const titleByReportName: Record<ReportName, string> = {
  [ReportName.ReportCallCenter]: "Проверка вакансий КЦ",
  [ReportName.ReportPilot]: "Отчетность по пилоту",
  [ReportName.ReportMango]: "Отчетность по манго",
  [ReportName.ReportReply]: "Отчет по откликам",
};

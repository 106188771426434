import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInitialState } from "../../helpers";
import {
  CandidateType,
  ReplyNewClientType,
  ReplyNewStatuses,
} from "graphql/types/types";
import { getReply, getReplyList } from "./actions";
import { ChangeStatusPayload, ReplyFiltersInitialState } from "./types";

const repliesListSlice = createSlice({
  name: "repliesNewList",
  initialState: getInitialState<ReplyNewClientType[]>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReplyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReplyList.fulfilled, (state, { payload }) => {
        state.data = payload?.replyNewList.data ?? [];
        state.meta = payload?.replyNewList.meta ?? null;
        state.loading = false;
      })
      .addCase(getReplyList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

const replySlice = createSlice({
  name: "replyNew",
  initialState: getInitialState<ReplyNewClientType>(),
  reducers: {
    setInProgressBy(
      state,
      {
        payload,
      }: PayloadAction<{ inProgressBy: number; status: ReplyNewStatuses }>
    ) {
      if (!state.data) return;
      state.data.inProgressBy = payload.inProgressBy;
      state.data.status = payload.status;
    },
    setChangeStatus(state, { payload }: PayloadAction<ChangeStatusPayload>) {
      if (!state.data) return;
      state.data.status = payload.status;
      state.data.candidateStatus = payload.candidateStatus;
    },
    updateCandidateInReplyNew(
      state,
      { payload }: PayloadAction<CandidateType>
    ) {
      if (!state.data) return;
      state.data.candidate = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReply.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReply.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.replyNew;
      })
      .addCase(getReply.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});
export const { setInProgressBy, setChangeStatus, updateCandidateInReplyNew } =
  replySlice.actions;
export const repliesReducer = combineReducers({
  repliesList: repliesListSlice.reducer,
  reply: replySlice.reducer,
});

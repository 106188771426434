import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

export const renderPasswordSuffix = (
  isVisible: boolean,
  toggle: () => void
): JSX.Element => {
  return isVisible ? (
    <EyeOutlined onClick={toggle} style={{ cursor: "pointer" }} />
  ) : (
    <EyeInvisibleOutlined onClick={toggle} style={{ cursor: "pointer" }} />
  );
};

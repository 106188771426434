import { getDateFormat } from "common/utils/date";
import { PAGINATION } from "common/const/pagination";
import {
  CityType,
  PaginationType,
  QueryVacancyListByCategoryArgs,
  QueryVacancyListByCategoryReactivationArgs,
} from "graphql/types/types";
import { VacancyFiltersState } from "./components/VacancyFilters";
import { getCityByName } from "services/store/modules/directory/getters";

const prepareFilters = (
  {
    vacancyId,
    vacancyName,
    creationDateRange,
    publishingDateRange,
    status,
    countCandidate,
    city,
    departmentId,
    platforms,
    managers,
  }: VacancyFiltersState,
  citiesList: CityType[]
) => {
  return {
    vacancyId: Number(vacancyId) || null,
    ...(vacancyName?.length ? { vacancyName } : {}),
    startCreatedAt: creationDateRange?.[0]
      ? getDateFormat(creationDateRange[0], "YYYY-MM-DD")
      : null,
    endCreatedAt: creationDateRange?.[1]
      ? getDateFormat(creationDateRange[1], "YYYY-MM-DD")
      : null,
    startPublishedAt: publishingDateRange?.[0]
      ? getDateFormat(publishingDateRange[0], "YYYY-MM-DD")
      : null,
    endPublishedAt: publishingDateRange?.[1]
      ? getDateFormat(publishingDateRange[1], "YYYY-MM-DD")
      : null,
    status: Array.isArray(status) ? status : [],
    ...(countCandidate ? { countCandidate: Number(countCandidate) } : {}),
    city: getCityByName(Number(city), citiesList),
    departmentId: departmentId ? Number(departmentId) : null,
    platforms,
    managers,
  };
};

export type PrepareVacanciesPayload = {
  pagination: PaginationType;
  categoryId: number;
  citiesList: CityType[];
  filters?: VacancyFiltersState;
};
export const prepareVacanciesPayload = ({
  pagination,
  categoryId,
  citiesList,
  filters,
}: PrepareVacanciesPayload):
  | QueryVacancyListByCategoryArgs
  | QueryVacancyListByCategoryReactivationArgs => {
  return {
    categoryId,
    pagination: {
      limit: pagination?.limit || PAGINATION.VACANCY.limit,
      offset: pagination?.offset || PAGINATION.VACANCY.offset,
    },
    filter: filters ? prepareFilters(filters, citiesList) : {},
  };
};

import axios from "axios";
import Cookies from "js-cookie";

const API_URLS: Record<API, string> = {
  API: process.env.REACT_APP_API_URL ?? "",
  AVITO: process.env.REACT_APP_AVITO_INTEGRATOR_URL ?? "",
  HH: process.env.REACT_APP_HH_INTEGRATOR_URL ?? "",
};

const API_TOKENS: Partial<Record<API, string>> = {
  HH: `Bearer ${Cookies.get("access_token")}`,
};

type Method = "get" | "put" | "post" | "delete";
type API = "API" | "AVITO" | "HH";

export const apiRequest = <Response, Params = {}>(
  type: API,
  method: Method,
  endpoint: string,
  params?: Params
) => {
  const token = API_TOKENS[type];
  const headers = token ? { Authorization: token } : undefined;

  return axios.request<Response>({
    url: `${API_URLS[type]}${endpoint}`,
    method,
    headers,
    params: method === "get" ? params : undefined,
    data: method !== "get" ? params : undefined,
  });
};

export const AREAS = {
  REPLY: "reply",
  VACANCY: "vacancy",
  CLAIM: "claim",
  POSITIONS: "positions",
  POSITIONS_MAP: "positions-map",
  CITIES: "cities",
  ALL: "all",
} as const;

export type Areas = (typeof AREAS)[keyof typeof AREAS];

export const AREAS_TITLE: Record<Areas, string> = {
  [AREAS.REPLY]: "Причины отказа откликов",
  [AREAS.VACANCY]: "Причины отказа вакансий",
  [AREAS.CLAIM]: "Причины отказа заявок",
  [AREAS.ALL]: "Общие причины отказа",
  [AREAS.POSITIONS]: "Список должностей",
  [AREAS.POSITIONS_MAP]: "Справочник должностей",
  [AREAS.CITIES]: "Список городов",
};

export const DECLINE_TYPES = {
  [AREAS.REPLY]: `decline-${AREAS.REPLY}`,
  [AREAS.VACANCY]: `decline-${AREAS.VACANCY}`,
  [AREAS.CLAIM]: `decline-${AREAS.CLAIM}`,
  [AREAS.ALL]: `decline-${AREAS.ALL}`,
} as const;

export type DeclineTypes = (typeof DECLINE_TYPES)[keyof typeof DECLINE_TYPES];

export const DICTIONARY_DATA = [
  {
    key: AREAS.REPLY,
    name: AREAS_TITLE[AREAS.REPLY],
    type: DECLINE_TYPES[AREAS.REPLY],
  },
  {
    key: AREAS.VACANCY,
    name: AREAS_TITLE[AREAS.VACANCY],
    type: DECLINE_TYPES[AREAS.VACANCY],
  },
  {
    key: AREAS.CLAIM,
    name: AREAS_TITLE[AREAS.CLAIM],
    type: DECLINE_TYPES[AREAS.CLAIM],
  },
  {
    key: AREAS.ALL,
    name: AREAS_TITLE[AREAS.ALL],
    type: DECLINE_TYPES[AREAS.ALL],
  },
  {
    key: AREAS.POSITIONS,
    name: AREAS_TITLE[AREAS.POSITIONS],
    type: AREAS.POSITIONS,
  },
  {
    key: AREAS.CITIES,
    name: AREAS_TITLE[AREAS.CITIES],
    type: AREAS.CITIES,
  },
  {
    key: AREAS.POSITIONS_MAP,
    name: AREAS_TITLE[AREAS.POSITIONS_MAP],
    type: AREAS.POSITIONS_MAP,
  },
];

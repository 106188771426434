import React from "react";

interface FieldProps {
  label: string;
  children: React.ReactNode;
}

export const ProfileField: React.FC<FieldProps> = ({ label, children }) => {
  return (
    <div className="profile-card-row-info">
      <span className="profile-card-field-info">{label}</span>
      <span className="profile-card-user-info">{children}</span>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import hhLogo from "assets/images/hh-logo.png";
import { useLocation } from "react-router-dom";
import { createEvent } from "api/clickStream";
import { getDateTimezone } from "common/helpers/getDateTimezone";
import { getProfile, logoutProfile } from "api/hh";
import { Button } from "components/UI/Button";
import { Title } from "components/UI/Title";
import toaster from "components/UI/Notifications/Notification";
import { UserType } from "graphql/types/types";
import { Close, Mail, UserCircle } from "components/UI/icons";
import { Spinner } from "components/UI/Spinner";
import { windowLocationReplace } from "common/utils/routes";

import "./styles.scss";

export interface Profilehh {
  hh_id: string;
  email: string;
  id: string;
  name: string;
  profile_url: string;
  rabotut_id: string;
}

type Props = {
  userId: UserType["id"];
};

const integratorHHUrl = process.env.REACT_APP_HH_INTEGRATOR_URL;
const appUrl = process.env.REACT_APP_APP_URL;
const AUTH_HH_BUTTON_ID = "auth-hh-button";
const LOGOUT_HH_BUTTON_ID = "logout-hh-button";

const EMPTY_PROFYLE: Profilehh = {
  email: "",
  hh_id: "",
  id: "",
  name: "",
  profile_url: "",
  rabotut_id: "",
};

export const ProfileHh = ({ userId }: Props) => {
  const location = useLocation();
  const [profile, setProfile] = useState<Profilehh>(EMPTY_PROFYLE);
  const [loading, setLoading] = useState<boolean>(false);

  const infoItems = [
    {
      key: "email",
      label: "Почта:",
      icon: <Mail height={24} width={24} color={"#A6ACB9"} />,
      value: profile?.email,
    },
    {
      key: "name",
      label: "Имя пользователя:",
      icon: <UserCircle />,
      value: profile?.name,
    },
  ];

  const redirectAuthLink = () => {
    try {
      windowLocationReplace(
        `${integratorHHUrl}/auth/link?user_id=${userId}&company_id=1&redirect_url=${appUrl}/profile/profileHh`
      );
    } catch (err) {
      toaster.error({ title: err.message });
    }

    createEvent({
      userId,
      elementId: AUTH_HH_BUTTON_ID,
      url: location.pathname,
      timestamp: getDateTimezone("Europe/Moscow"),
    }).catch(() => null);
  };

  const logoutHh = () => {
    logoutProfile(userId)
      .then(() => {
        setProfile(EMPTY_PROFYLE);
      })
      .catch((err) => {
        toaster.error({ title: err.message });
      });

    createEvent({
      userId,
      elementId: LOGOUT_HH_BUTTON_ID,
      url: location.pathname,
      timestamp: getDateTimezone("Europe/Moscow"),
    }).catch(() => null);
  };

  useEffect(() => {
    setLoading(true);
    getProfile<Profilehh>(userId)
      .then(({ data }) => {
        if (data) {
          setProfile(data);
        }
      })
      .catch((err) => {
        toaster.error({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);
  return (
    <>
      {!loading ? (
        <div className="profile-hh">
          {profile && profile.id && profile.id.length ? (
            <div className="block-item">
              <Title type={"h3"}>Подключенные сервисы:</Title>
              <div className="block-center">
                <img src={hhLogo} alt="hhLogo" className="hh-logo" />
                <span className="hh-text">HeadHunter</span>
                <Close
                  color={"red"}
                  id={LOGOUT_HH_BUTTON_ID}
                  className="close-icon"
                  onClick={logoutHh}
                />
              </div>
              <div className="user-info">
                {infoItems.map((item) => (
                  <div key={item.key} className="user-info-item">
                    <div className="user-info-item-label">
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                    <span>{item.value}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <Title type={"h3"}>Авторизация приложения HH</Title>
              <div className="profile-hh-entrance">
                <Button
                  className="button-hh"
                  id={AUTH_HH_BUTTON_ID}
                  onClick={redirectAuthLink}
                >
                  Войти
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="loading-wrapper">
          <Spinner loading={loading} size="small" />
        </div>
      )}
    </>
  );
};

import React from "react";

import { useAppSelector } from "services/store/store";
import { selectCandidateData } from "services/store/modules/candidate/selectors";
import { FormCandidate } from "components/FormCandidate";
import { CandidateActions } from "components/CandidateActions";

export const SearchCreate: React.FC = () => {
  const candidate = useAppSelector(selectCandidateData);

  return (
    <div>
      <FormCandidate candidate={candidate} initialDisabledForm={false} />
      {candidate && (
        <CandidateActions candidateId={candidate.id} candidate={candidate} />
      )}
    </div>
  );
};

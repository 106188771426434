import React, { useState } from "react";
import { authInfo } from "../../services/store/modules/auth";
import { ProfileHh } from "./components/ProfileHh";
import { useHistory, useLocation } from "react-router-dom";
import { ProfileAvito } from "./components/ProfileAvito";
import { ProfileForm } from "./components/ProfileForm";
import { Security } from "./components/Security";
import { Tabs } from "components/UI/Tabs";
import { ProfileCard } from "./components/ProfileCard";
import { useAppSelector } from "services/store/store";

import "./styles.scss";

export const ProfilePage = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useAppSelector(authInfo);

  const initialTab = location.pathname.split("/")[2] || "edit";
  const [activeKey, setActiveKey] = useState(initialTab);

  const handleTabClick = (key) => {
    setActiveKey(key);
    history.push(`/profile/${key}`);
  };

  if (!user) {
    return null;
  }

  const items = [
    {
      key: "edit",
      label: "Редактировать",
      children: <ProfileForm user={user} />,
    },
    {
      key: "security",
      label: "Безопасность",
      children: <Security userId={user.id} />,
    },
    {
      key: "profileHh",
      label: "Профиль HH",
      children: <ProfileHh userId={user.id} />,
    },
    {
      key: "profileAvito",
      label: "Профиль Avito",
      children: <ProfileAvito user={user} />,
    },
  ];

  return (
    <div className="profile-wrapper" data-qa="profile__section">
      <ProfileCard user={user} />

      <Tabs
        defaultActiveKey="edit"
        activeKey={activeKey}
        onTabClick={handleTabClick}
        items={items}
        className="profile-tabs"
        style={{ background: "white", padding: "1rem" }}
      />
    </div>
  );
};

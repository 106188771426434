import React, { useMemo, useRef } from "react";
import type { MenuProps } from "antd";

import { Dropdown } from "components/UI/Dropdown";
import { CandidateType, ContactType } from "graphql/types/types";
import { useToggle } from "common/hooks/useToggle";
import { getMainPhone } from "common/helpers/phone";
import { CallButton } from "components/Calls/CallButton";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  contacts?: ContactType[];
}

interface PropsItem {
  contact: ContactType["contact"];
  candidate: CandidateType;
  isMain: ContactType["isMain"];
  isAdditional: boolean;
}

const getFilteredContactItems = (
  contacts: ContactType[],
  candidate: CandidateType,
  isMain: boolean
) =>
  contacts
    .filter((contacts) => contacts.isMain === isMain)
    .map(({ id, contact }, i) => ({
      key: id ?? "",
      label: (
        <ContactItem
          isAdditional={isMain ? false : i === 0}
          contact={contact}
          isMain={isMain}
          candidate={candidate}
        />
      ),
    }));

export const ContactItem: React.FC<PropsItem> = ({
  contact,
  isMain,
  candidate,
  isAdditional,
}) => {
  const phoneItemRef = useRef<HTMLDivElement>(null);

  const additional = isAdditional ? "Дополнительный" : "";

  return (
    <div
      ref={phoneItemRef}
      className="field-phone-contact"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="field-phone-contact-title">
        {isMain ? "Основной" : additional}
      </div>

      <span className="field-phone-contact-phone">
        <span className="field-phone-contact-number">{contact}</span>
        <CallButton
          className="field-phone-contact-button"
          contact={contact}
          candidate={candidate}
        />
      </span>
    </div>
  );
};

export const FieldPhone: React.FC<Props> = ({ contacts = [], candidate }) => {
  const [open, toggleOpen] = useToggle(false);

  const items: MenuProps["items"] = useMemo(
    () => [
      ...getFilteredContactItems(contacts, candidate, true),
      ...getFilteredContactItems(contacts, candidate, false),
    ],
    [candidate, contacts]
  );

  return (
    <Dropdown
      className="field-phone"
      overlayClassName="field-phone-overlay"
      items={items}
      trigger={["click"]}
      placement="bottomLeft"
      open={open}
      onOpenChange={toggleOpen}
    >
      <div
        className="field-phone-contact-number"
        onClick={(e) => e.stopPropagation()}
      >
        {getMainPhone(contacts)?.contact ?? ""}
      </div>
    </Dropdown>
  );
};

import React from "react";
import { Row } from "antd";
import cn from "classnames";

import "./styles.scss";

type Props = {
  header?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
};

export const ColumnLayout: React.FC<Props> = ({
  className,
  header,
  children,
  headerClassName,
  contentClassName,
}) => (
  <>
    <Row className={cn("column-layout", className)}>
      {header && (
        <Row className={cn("column-layout-header", headerClassName)}>
          {header}
        </Row>
      )}
      <Row className={cn("column-layout-content", contentClassName)}>
        {children}
      </Row>
    </Row>
  </>
);

import React from "react";
import { Button } from "components/UI/Button";
import cn from "classnames/dedupe";

import "./styles.scss";

interface SaveCancelButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  saveText?: string;
  cancelText?: string;
  saveButtonProps?: React.ComponentProps<typeof Button>;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
  className?: string;
}

export const FormSaveBar: React.FC<SaveCancelButtonsProps> = ({
  onSave,
  onCancel,
  saveText = "Сохранить изменения",
  cancelText = "Отменить",
  saveButtonProps,
  cancelButtonProps,
  className,
}) => {
  return (
    <div className={cn("ui-form-save", className)}>
      <Button
        variant={"primary"}
        onClick={onCancel}
        style={{ width: "208px" }}
        {...cancelButtonProps}
      >
        {cancelText}
      </Button>
      <Button onClick={onSave} style={{ width: "208px" }} {...saveButtonProps}>
        {saveText}
      </Button>
    </div>
  );
};

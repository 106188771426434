import { ReportName, titleByReportName } from "common/const/reports";
import { Report } from "components/UI/icons";
import { UserRole } from "../../../graphql/types/types";
import { hasModerationAccess, isAdmin } from "../../../common/helpers/role";

type ReportCard = {
  title: string;
  icon: React.ReactElement;
  isVisible?: boolean;
  onClick: () => void;
};

export const getReportCards = (
  handleClick: (name: ReportName) => void,
  userRole: UserRole
): ReportCard[] => [
  {
    title: titleByReportName[ReportName.ReportCallCenter],
    icon: <Report />,
    isVisible: isAdmin(userRole),
    onClick: () => handleClick(ReportName.ReportCallCenter),
  },
  {
    title: titleByReportName[ReportName.ReportPilot],
    icon: <Report />,
    isVisible: isAdmin(userRole),
    onClick: () => handleClick(ReportName.ReportPilot),
  },
  {
    title: titleByReportName[ReportName.ReportMango],
    icon: <Report />,
    isVisible: isAdmin(userRole),
    onClick: () => handleClick(ReportName.ReportMango),
  },
  {
    title: titleByReportName[ReportName.ReportReply],
    icon: <Report />,
    isVisible: hasModerationAccess(userRole),
    onClick: () => handleClick(ReportName.ReportReply),
  },
];

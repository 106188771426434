export const KB = 1024;
export const MB = KB * 1024;
export const GB = MB * 1024;

export const ALLOWED_EXTENSIONS = [
  "bmp",
  "gif",
  "jpg",
  "jpeg",
  "png",
  "tff",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pdf",
  "txt",
  "xml",
  "7z",
  "zip",
  "rar",
];

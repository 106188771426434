type MessageType = "reply" | "last" | "default";

export const getTitleMessageByType = (
  peopleCountOpen: number
): Record<MessageType, string> => ({
  reply: "Кандидат назначен на вакансию",
  last: "Вы набрали необходимое количество кандидатов по данной вакансии",
  default: `Потребность в количестве ${peopleCountOpen} уменьшена`,
});

export const TEXT_MESSAGE_BY_TYPE: Record<MessageType, string> = {
  reply: "Вы будете переведены к списку откликов",
  last: "Перейдите к другим вакансиям",
  default: "Вы будете переведены к списку вакансии",
};

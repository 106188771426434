import React, { useState, useEffect } from "react";

import { UserType } from "graphql/types/types";
import { Title } from "components/UI/Title";
import { Button } from "components/UI/Button";
import { parseQuery } from "common/utils/query";
import { getAvitoAuthUrl } from "../../helpers";
import { getCookie, setCookie } from "common/utils/cookies";
import { randomString } from "common/utils/string";
import { windowHistoryReplaceState, windowOpen } from "common/utils/routes";
import { useAppDispatch } from "services/store/store";
import {
  getAvitoApp,
  authUserAvito,
  setAuthInfo,
} from "services/store/modules/auth";
import toaster from "components/UI/Notifications/Notification";

import "./styles.scss";

type Props = {
  user: UserType;
};

const AVITO_AUTH_STATE = "avito-auth-state";

export const ProfileAvito: React.FC<Props> = ({ user }) => {
  const { avitoAccountId, id } = user;

  const dispatch = useAppDispatch();

  const { code, state } = parseQuery(window.location.search);

  const [loading, setLoading] = useState(false);

  const goAvitoAuth = async () => {
    setLoading(true);

    await dispatch(getAvitoApp({ userId: id }))
      .unwrap()
      .then((result) => {
        if (!result?.applicationClientId) return;

        const state = randomString();

        setCookie(AVITO_AUTH_STATE, state);
        windowOpen(getAvitoAuthUrl(result.applicationClientId, state), "_self");
      })
      .catch(() => {
        toaster.error({ title: "Ошибка создания приложения в авито" });
      })
      .finally(() => setLoading(false));
  };

  const authAvito = async (code: string) => {
    setLoading(true);

    await dispatch(authUserAvito({ authCode: code }))
      .unwrap()
      .then((result) => {
        if (!result) return;

        dispatch(
          setAuthInfo({ ...user, avitoAccountId: result.avitoAccountId })
        );
        windowHistoryReplaceState({}, "", window.location.pathname);

        toaster.success({ title: "Авторизации в Avito завершена" });
      })
      .catch(() => {
        toaster.error({ title: "Ошибка авторизации в Avito" });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (code && state === getCookie(AVITO_AUTH_STATE)) {
      authAvito(code);
    }
  }, []);

  return (
    <div className="profile-avito">
      <Title type="h4"> Авторизация приложения Avito</Title>

      {avitoAccountId ? (
        <div>Вы авторизованы в Авито</div>
      ) : (
        <Button loading={loading} onClick={goAvitoAuth}>
          Войти как сотрудник
        </Button>
      )}
    </div>
  );
};

import { ReactNode } from "react";
import { ReplyNewSource, Source } from "graphql/types/types";
import { RabotutIcon, HhIcon, AvitoIcon } from "components/UI/icons";

export const SOURCE_TYPE: Record<Source, string> = {
  [Source.Website]: "Rabotut.ru",
  [Source.Claim]: "Заявка",
  [Source.Reply]: "Отклик",
  [Source.Telegram]: "Telegram",
  [Source.Hh]: "HeadHunter",
  [Source.Avito]: "Avito.Работа",
  [Source.Vk]: "Вконтакте",
  [Source.Nerab]: "Нераб",
  [Source.Bcr]: "BCR",
  [Source.Mobile]: "Mobile",
  [Source.Vp]: "Ваш Персонал",
  [Source.Bluecollar]: "Bluecollar",
  [Source.Saleads]: "Saleads",
  [Source.Unicom]: "Unicom",
  [Source.Cityads]: "Cityads",
  [Source.Leadsu]: "Leadsu",
  [Source.Workle]: "Workle",
  [Source.Admitad]: "Admitad",
  [Source.Arketa]: "Arketa",
  [Source.Reactivation]: "Реактивация",
};
export const REPLY_SOURCE_TYPE: Record<ReplyNewSource, ReactNode> = {
  [ReplyNewSource.Website]: <RabotutIcon />,
  [ReplyNewSource.Claim]: "CLAIM",
  [ReplyNewSource.Telegram]: "TELEGRAM marketing",
  [ReplyNewSource.Hh]: <HhIcon />,
  [ReplyNewSource.Avito]: <AvitoIcon />,
  [ReplyNewSource.Vk]: "VK marketing",
  [ReplyNewSource.Nerab]: "NERAB",
  [ReplyNewSource.Bcr]: 'CPA "BCR"',
  [ReplyNewSource.Mobile]: "MOBILE",
  [ReplyNewSource.Vp]: "VP",
  [ReplyNewSource.Bluecollar]: 'CPA "BLUECOLLAR"',
  [ReplyNewSource.Saleads]: 'CPA "SALEADS"',
  [ReplyNewSource.Unicom]: 'CPA "UNICON"',
  [ReplyNewSource.Cityads]: 'CPA "CITYADS"',
  [ReplyNewSource.Leadsu]: 'CPA "LEADSU"',
  [ReplyNewSource.Workle]: 'CPA "WORKLE"',
  [ReplyNewSource.Admitad]: 'CPA "ADMITAD"',
  [ReplyNewSource.Arketa]: "ARKETA",
};

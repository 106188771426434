import { CombinedReplyStatus } from "common/const/status";
import { ReplyNewClientType, VacancyType } from "graphql/types/types";

export const getBadgeLabel = (
  status: CombinedReplyStatus,
  isExpired: ReplyNewClientType["isExpired"],
  isThirtyMinutes: ReplyNewClientType["isThirtyMinutes"],
  lastModifiedBy: ReplyNewClientType["lastModifiedBy"]
): string | null => {
  switch (true) {
    case status === CombinedReplyStatus.New && isExpired:
      return "Просрочен";

    case status === CombinedReplyStatus.New && isThirtyMinutes:
      return "30 мин";

    case status === CombinedReplyStatus.Processed && lastModifiedBy === 0:
      return "Система";

    default:
      return null;
  }
};

export const getDisplayProject = (vacancy: VacancyType): string => {
  const project = vacancy?.callCenterInfo?.project ?? "";
  return project.split(",")[0];
};

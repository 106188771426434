import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "services/store/store";
import { changeUserPassword } from "services/store/modules/user/action";
import toaster from "components/UI/Notifications/Notification";
import { Button } from "components/UI/Button";
import { Title } from "components/UI/Title";
import { FormInput } from "components/UI/Form/FormInput";
import { Form } from "components/UI/Form";
import { renderPasswordSuffix } from "./helpers";
import { schema } from "./validation";
import { UserType } from "graphql/types/types";

import "./styles.scss";

export interface SecurityState {
  password: string;
  confirmPassword: string;
}

interface Props {
  userId: UserType["id"];
}

export const Security: React.FC<Props> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, watch, reset } = useForm<SecurityState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const passwordValue = watch("password");
  const confirmPasswordValue = watch("confirmPassword");
  const isFieldsEmpty: boolean = !passwordValue && !confirmPasswordValue;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const onSubmit = async (data: SecurityState) => {
    try {
      dispatch(
        changeUserPassword({
          id: userId,
          password: data.password,
        })
      ).unwrap();
      toaster.success({ title: "Пароль успешно изменён" });
      reset();
    } catch (e) {
      toaster.error({ title: e.message });
    }
  };

  return (
    <div className="profile-security">
      <div className="profile-security-wrapper">
        <Title type="h3">Изменение пароля</Title>
        <Form
          className="profile-security-form"
          onFinish={handleSubmit(onSubmit)}
        >
          <Controller
            name="password"
            control={control}
            rules={{ required: "Введите новый пароль" }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Введите новый пароль"
                required
                type={passwordVisible ? "text" : "password"}
                error={error?.message}
                suffix={renderPasswordSuffix(passwordVisible, () =>
                  setPasswordVisible((prev) => !prev)
                )}
                {...field}
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Введите подтверждение пароля",
              validate: (value) =>
                value === passwordValue || "Пароли не совпадают",
            }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Введите подтверждение пароля"
                required
                type={confirmPasswordVisible ? "text" : "password"}
                error={error?.message}
                suffix={renderPasswordSuffix(confirmPasswordVisible, () =>
                  setConfirmPasswordVisible((prev) => !prev)
                )}
                {...field}
              />
            )}
          />

          <div className="profile-security-button-box">
            <Button onClick={() => reset()} disabled={isFieldsEmpty}>
              Отмена
            </Button>
            <Button type="submit">Сохранить</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

import { lazy } from "react";
import { Redirect, RouteProps } from "react-router-dom";
import Auth from "./views/Auth/Auth";
import Authorizer from "./views/Auth/Authorizer";
import { CandidatesPage } from "views/redezign/CandidatesPage";
import { VacanciesPage } from "views/redezign/VacanciesPage";
import { VacancyPage } from "views/redezign/VacancyPage";

import { CandidatePage } from "views/redezign/CandidatePage";
import { RecommendedPage } from "./views/redezign/RecommendedPage";
import { ReportsPage } from "views/redezign/ReportsPage";
import { AdminSettingPage } from "./views/redezign/AdminSettingPage";
import { ReportPage } from "views/redezign/ReportPage";
import { PositionsMapPage } from "views/settings/PositionsMapPage";
import { ClaimPage } from "views/redezign/ClaimPage";
import { PlansPage } from "./views/redezign/PlansPage";
import { PlanPage } from "./views/redezign/PlanPage";
import { RepliesPage } from "./views/redezign/RepliesPage";
import { ReplyPage } from "./views/redezign/ReplyPage";
import { CreateCandidatePage } from "./views/redezign/CreateCandidatePage";
import { CandidateForAdaptationListPage } from "./views/redezign/CandidateForAdaptationListPage";
import { CandidateForAdaptationPage } from "views/redezign/CandidateForAdaptationPage";
import { ColdSearchPage } from "views/redezign/ColdSearchPage";
import { ColdSearchVacancyPage } from "views/redezign/ColdSearchVacancyPage";
import { ReactivationVacanciesPage } from "views/redezign/ReactivationVacanciesPage";
import { SystemSettingPage } from "views/settings/SystemSettingPage";
import { DictionaryPage } from "views/settings/DictionaryPage";
import { ProfilePage } from "views/ProfilePage";

export type RouteItem = RouteProps & {
  privateRoute?: boolean;
  breadcrumbs?: Array<{ text: string; url?: string }>;
};

const Invitation = lazy(() => import("./views/Auth/Invitation"));
const VacancyView = lazy(
  () => import("./views/Vacancies/VacancyView/VacancyView")
);
const VacancyList = lazy(
  () => import("./views/Vacancies/VacancyList/VacancyList")
);
const Page404 = lazy(() => import("./views/Page404"));
const CompanyProfile = lazy(
  () => import("./views/CompanyProfile/CompanyProfile")
);
const ClaimList = lazy(() => import("./views/Claims/ClaimList"));
const Departments = lazy(() => import("./views/Departments/Departments"));
const UserView = lazy(() => import("./views/Users/UserView/UserView"));

export const ROUTE = {
  VACANCY: "/vacancy-view",
  VACANCIES: "/vacancies-view",
  CANDIDATE: "/candidate",
  CANDIDATES: "/candidates",
  RECOMMENDED: "/recommended",
  REPORTS: "/reports",
  REPORT: "/report",
  CLAIM: "/claim",
  PLANS: "/plans",
  PLAN: "/plan",
  REPLIES: "/replies",
  REPLY: "/reply",
  ADAPTATION_LIST: "/adaptation-list",
  ADAPTATION: "/adaptation",
  CREATE_CANDIDATE: "/create-candidate",
  COLD_SEARCH: "/cold-search",
  COLD_SEARCH_VACANCY: "/cold-search-vacancy",
  REACTIVATION_VACANCY: "/reactivation-vacancy",
  REACTIVATION_VACANCIES: "/reactivation-vacancies",
  SETTINGS: "/settings",
  SETTINGS_DICTIONARY: "/settings/dictionary/:type?",
  SETTINGS_POSITIONS_MAP: "/settings/positions-map",
} as const;

export type Route = (typeof ROUTE)[keyof typeof ROUTE];

export const routes: RouteItem[] = [
  {
    path: "/login",
    exact: true,
    component: () => <Auth />,
  },
  {
    path: "/authorizer",
    exact: true,
    component: () => <Authorizer />,
  },
  {
    path: "/",
    exact: true,
    privateRoute: true,
    component: () => <Redirect to={"/vacancies"} />,
  },
  {
    path: "/users",
    exact: true,
    privateRoute: true,
    component: () => <Invitation />,
    breadcrumbs: [{ text: "Список сотрудников", url: "/users" }],
  },
  {
    path: ROUTE.VACANCIES,
    exact: true,
    privateRoute: true,
    component: () => <VacanciesPage />,
  },
  {
    path: "/vacancies/:id?",
    exact: true,
    privateRoute: true,
    component: () => <VacancyList />,
  },

  {
    path: "/admin-setting",
    exact: true,
    privateRoute: true,
    component: () => <AdminSettingPage />,
  },
  {
    path: "/vacancies/create",
    exact: true,
    privateRoute: true,
    component: () => <Redirect to="/vacancies/new" />,
  },
  {
    path: "/vacancy/new",
    exact: true,
    privateRoute: true,
    component: () => <VacancyView />,
    breadcrumbs: [
      { text: "Список вакансий", url: "/vacancies" },
      { text: "Создание вакансии" },
    ],
  },
  {
    path: "/vacancy/:id",
    privateRoute: true,
    exact: true,
    component: () => <VacancyView />,
    breadcrumbs: [
      { text: "Список вакансий", url: ROUTE.VACANCIES },
      { text: "Редактирование вакансии" },
    ],
  },
  {
    path: `${ROUTE.VACANCY}/:id`, // TODO оставить /vacancy/:id после перехода на новый дизайн
    privateRoute: true,
    exact: true,
    component: () => <VacancyPage />,
  },
  {
    path: "/profile/:tab?",
    exact: true,
    privateRoute: true,
    component: () => <ProfilePage />,
    breadcrumbs: [{ text: "Профиль" }],
  },
  {
    path: "/company/:tab?",
    exact: true,
    privateRoute: true,
    component: () => <CompanyProfile />,
    breadcrumbs: [{ text: "Компания" }],
  },
  {
    path: ROUTE.REPLIES,
    exact: true,
    privateRoute: true,
    component: () => <RepliesPage />,
  },
  {
    path: ROUTE.ADAPTATION_LIST,
    exact: true,
    privateRoute: true,
    component: () => <CandidateForAdaptationListPage />,
  },
  {
    path: ROUTE.CREATE_CANDIDATE,
    exact: true,
    privateRoute: true,
    component: () => <CreateCandidatePage />,
  },
  {
    path: `${ROUTE.ADAPTATION}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <CandidateForAdaptationPage />,
  },
  {
    path: `${ROUTE.REPLY}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ReplyPage />,
  },
  {
    path: `${ROUTE.CLAIM}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ClaimPage />,
  },
  {
    path: ROUTE.CANDIDATES,
    exact: true,
    privateRoute: true,
    component: () => <CandidatesPage />,
  },
  {
    path: `${ROUTE.CANDIDATE}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <CandidatePage />,
  },
  {
    path: `${ROUTE.RECOMMENDED}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <RecommendedPage />,
  },
  {
    path: ROUTE.REPORTS,
    exact: true,
    privateRoute: true,
    component: () => <ReportsPage />,
  },
  {
    path: `${ROUTE.REPORT}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ReportPage />,
  },
  {
    path: ROUTE.PLANS,
    exact: true,
    privateRoute: true,
    component: () => <PlansPage />,
  },
  {
    path: `${ROUTE.PLAN}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <PlanPage />,
  },
  {
    path: ROUTE.COLD_SEARCH,
    exact: true,
    privateRoute: true,
    component: () => <ColdSearchPage />,
  },
  {
    path: `${ROUTE.COLD_SEARCH_VACANCY}/:id`,
    exact: true,
    privateRoute: true,
    component: () => <ColdSearchVacancyPage />,
  },
  {
    path: ROUTE.REACTIVATION_VACANCIES,
    exact: true,
    privateRoute: true,
    component: () => <ReactivationVacanciesPage />,
  },
  {
    path: "/claims",
    exact: true,
    privateRoute: true,
    // @ts-ignore
    component: () => <ClaimList />,
    breadcrumbs: [{ text: "Заявки" }],
  },
  {
    path: "/departments",
    exact: true,
    privateRoute: true,
    component: () => <Departments />,
    breadcrumbs: [{ text: "Департаменты" }],
  },
  {
    path: "/user/:id",
    exact: true,
    privateRoute: true,
    component: () => <UserView />,
    breadcrumbs: [
      { text: "Компания", url: "/company" },
      { text: "Сотрудники", url: "/company/users" },
      { text: "Редактирование" },
    ],
  },
  {
    path: ROUTE.SETTINGS,
    exact: true,
    privateRoute: true,
    component: () => <SystemSettingPage />,
    breadcrumbs: [{ text: "Системные настройки" }],
  },
  {
    path: ROUTE.SETTINGS_DICTIONARY,
    exact: true,
    privateRoute: true,
    component: () => <DictionaryPage />,
    breadcrumbs: [
      { text: "Системные настройки", url: ROUTE.SETTINGS },
      { text: "Редактирование словаря" },
    ],
  },
  {
    path: ROUTE.SETTINGS_POSITIONS_MAP,
    exact: true,
    privateRoute: true,
    component: () => <PositionsMapPage />,
    breadcrumbs: [
      { text: "Системные настройки", url: ROUTE.SETTINGS },
      { text: "Справочник должностей" },
    ],
  },
  {
    path: "/invitation",
    exact: true,
    privateRoute: true,
    component: () => <Invitation />,
    breadcrumbs: [
      { text: "Сотрудники", url: "/company/users" },
      { text: "Добавление сотрудника" },
    ],
  },
  { path: "*", component: () => <Page404 /> },
];

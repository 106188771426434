import { gql } from "@apollo/client";

export const CANDIDATE_FOR_ADAPTATION_LIST = gql`
  query candidateForAdaptationList(
    $filter: CandidateAdaptationFilterType
    $pagination: PaginationType
  ) {
    candidateForAdaptationList(filter: $filter, pagination: $pagination) {
      ... on CandidateAdaptationListSuccess {
        data {
          cityVacancy
          dateTransfer
          fullNameCandidate
          fullNameMa
          fullNameMpp
          phoneCandidate
          status
          vacancyId
          candidateId
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;

import { Breadcrumb } from "antd";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { routes } from "routes";

import "./styles.scss";

export const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const matchedRoute = routes.find((route) => matchPath(pathname, route));

  if (!matchedRoute || !matchedRoute.breadcrumbs) {
    return null;
  }

  return (
    <Breadcrumb className="breadcrumbs">
      <Breadcrumb.Item>
        <Link to="/">Главная</Link>
      </Breadcrumb.Item>
      {matchedRoute.breadcrumbs.map(({ text, url }, id) => (
        <Breadcrumb.Item key={id}>
          <a href={url}>{text}</a>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

import React from "react";
import { Controller, Control } from "react-hook-form";

import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { FormInput } from "components/UI/Form/FormInput";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { FormSelect } from "components/UI/Form/FormSelect";
import { OPTION_CITIZENSHIP, OPTION_GENDER } from "common/const/options";
import FormText from "components/UI/Form/FormText";
import { FiltersFormState } from "../types";

import "./styles.scss";

interface CandidateInfoProps {
  badge: string;
  control: Control<FiltersFormState>;
  disabledForm: boolean;
}

const CandidateInfo: React.FC<CandidateInfoProps> = ({
  control,
  badge,
  disabledForm,
}) => {
  return (
    <div className="form-candidate-details">
      <Title type="h3" color="blue">
        Личные данные
      </Title>

      <RowLayout
        leftComponent={
          <Controller
            name="secondName"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Фамилия"
                placeholder="Укажите фамилию"
                required={true}
                disabled={disabledForm}
                error={error?.message}
                {...field}
              />
            )}
          />
        }
        centerComponent={
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <FormInput
                label="Имя"
                placeholder="Укажите имя"
                required={true}
                disabled={disabledForm}
                error={error?.message}
                {...field}
              />
            )}
          />
        }
        rightComponent={
          <Controller
            name="patronymic"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput
                label="Отчество"
                placeholder="Укажите отчество"
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormDatePicker
                label="Дата рождения"
                style={{ width: "100%" }}
                format="DD.MM.YYYY"
                defaultValue={field.value}
                picker="date"
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
        centerComponent={
          <Controller
            name="age"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormInput
                label="Возраст"
                placeholder="Не указан"
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
        rightComponent={
          <Controller
            name="sex"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <FormSelect
                label={"Пол"}
                options={OPTION_GENDER}
                required={true}
                error={error?.message}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
      />
      <RowLayout
        leftComponent={
          <Controller
            name="russianCitizenship"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <FormSelect
                label={"Гражданство"}
                options={OPTION_CITIZENSHIP}
                style={{ width: "80%" }}
                disabled={disabledForm}
                {...field}
              />
            )}
          />
        }
        centerComponent={
          <FormText
            label={"Бейдж"}
            text={`ID ${badge}`}
            style={{ color: "#0057AC", fontSize: "14px" }}
          />
        }
      />
    </div>
  );
};

export default CandidateInfo;

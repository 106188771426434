import React from "react";
import { IconType } from "common/commonTypes/types";

export const RabotutIcon: React.FC<IconType> = ({
  width = 15,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3764 5.16128C14.3831 5.41327 14.2926 5.63236 14.1276 5.82953C13.245 6.88505 12.1543 7.70445 10.8968 8.33452C10.6263 8.47001 10.3496 8.59336 10.0676 8.70594C10.0242 8.72336 10.0058 8.74647 9.99807 8.78954C9.90784 9.29293 9.80592 9.79436 9.72739 10.2995C9.71527 10.3782 9.704 10.4571 9.68917 10.5352C9.68019 10.5824 9.69188 10.6002 9.74744 10.6093C10.5917 10.7436 11.3989 10.9881 12.17 11.336C12.3574 11.4206 12.5422 11.5103 12.7233 11.6058C13.3137 11.9175 13.5433 12.5171 13.2849 13.1054C13.0737 13.5865 12.6932 13.8802 12.1314 13.9403C11.8582 13.9695 11.6057 13.8968 11.3682 13.7727C10.7101 13.4285 10.0116 13.1886 9.26706 13.0753C8.34599 12.9349 7.43808 12.9936 6.54875 13.2609C5.62726 13.5377 4.8079 13.9896 4.05956 14.5598C3.66064 14.8637 3.3108 15.2135 2.96409 15.5679C2.7051 15.8328 2.3797 15.991 1.98809 15.9997C1.56243 16.0089 1.21217 15.8524 0.951306 15.5405C0.655978 15.1873 0.538808 14.7918 0.688143 14.3491C0.757066 14.1447 0.91204 13.992 1.05344 13.8326C1.88387 12.8967 2.88347 12.1554 4.02196 11.5753C4.07209 11.5498 4.10822 11.5177 4.13621 11.4707C4.51111 10.8399 4.75506 10.1646 4.91588 9.46072C4.92842 9.40551 4.93238 9.34775 4.95285 9.29547C4.98335 9.21774 4.94283 9.20169 4.87411 9.18818C3.96474 9.00824 3.10966 8.69321 2.29887 8.2703C1.93921 8.08273 1.69861 7.81018 1.63511 7.42055C1.51857 6.70492 2.08834 6.01534 2.81977 5.92997C3.10549 5.89669 3.3699 5.95464 3.61782 6.08406C4.27343 6.4265 4.96643 6.67066 5.70809 6.78814C5.98378 6.8318 6.26157 6.85373 6.54123 6.86293C6.9412 6.87605 7.34012 6.88701 7.73821 6.8318C8.11833 6.77913 8.47632 6.66596 8.81864 6.50423C9.17287 6.33703 9.53879 6.19234 9.8809 6.00202C10.6432 5.57793 11.3233 5.06162 11.9035 4.43176C12.1182 4.19876 12.3628 4.00473 12.6844 3.91525C13.2755 3.75078 13.886 3.99416 14.1924 4.51458C14.3108 4.71566 14.382 4.92888 14.3764 5.16148V5.16128Z"
        fill="#A6ACB9"
      />
      <path
        d="M7.02969 5.27427C8.58334 5.27427 9.84282 4.09358 9.84282 2.63712C9.84282 1.18066 8.58334 -3.05176e-05 7.02969 -3.05176e-05C5.47603 -3.05176e-05 4.21655 1.18066 4.21655 2.63712C4.21655 4.09358 5.47603 5.27427 7.02969 5.27427Z"
        fill="#E4244B"
      />
    </svg>
  );
};

export const OPTION_TERMINATION_REASON = [
  {
    label: "По собственному желанию",
    value: "own_accord",
  },
  {
    label: "По инициативе работодателя",
    value: "employer_initiative",
  },
  {
    label: "Автоувольнение",
    value: "self_dismissal",
  },
];

import React, { useCallback, useEffect } from "react";

import { useAppSelector } from "services/store/store";
import { useAppDispatch } from "services/store/store";
import { PaginationPayload } from "components/UI/Pagination";
import { RepliesTable } from "./components/RepliesTable";
import { QueryReplyNewListArgs } from "graphql/types/types";
import { PAGINATION } from "common/const/pagination";
import { getReplyList as fetchReplyList } from "services/store/modules/replies/actions";
import {
  selectRepliesListData,
  selectRepliesListLoading,
  selectRepliesListMeta,
  selectRepliesListPagination,
} from "services/store/modules/replies/selectors";
import { authInfo } from "services/store/modules/auth";
import { ReplyFiltersInitialState } from "services/store/modules/replies/types";
import { parseReplyFilters, prepareFilter } from "./helpers";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { INITIAL_STATE_FILTERS_REPlY } from "common/const/filters";

import "./styles.scss";

type Props = {
  className?: string;
};

export const Replies: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const replies = useAppSelector(selectRepliesListData);
  const meta = useAppSelector(selectRepliesListMeta);
  const pagination = useAppSelector(selectRepliesListPagination);
  const repliesLoading = useAppSelector(selectRepliesListLoading);
  const userData = useAppSelector(authInfo);
  const [replyFilters, setReplyFilters] =
    useLocalStorage<ReplyFiltersInitialState>(
      "replyFilters",
      INITIAL_STATE_FILTERS_REPlY,
      parseReplyFilters
    );

  const filters = prepareFilter(replyFilters);
  const getReplies = useCallback(
    (payload: QueryReplyNewListArgs) => {
      dispatch(fetchReplyList(payload));
    },
    [dispatch]
  );
  const getReplyList = useCallback(
    (
      pagination: PaginationPayload = PAGINATION.REPLIES,
      filters: ReplyFiltersInitialState
    ) => {
      const filter = prepareFilter(filters);
      getReplies({ pagination, filter });
    },
    [getReplies]
  );

  useEffect(() => {
    getReplies({ pagination, filter: filters });
  }, []);

  return (
    <div className="replies-container">
      {replies && meta && userData && (
        <RepliesTable
          replies={replies}
          meta={meta}
          loading={repliesLoading}
          onChangePagination={getReplyList}
          userData={userData}
          filters={replyFilters}
          setReplyFilters={setReplyFilters}
        />
      )}
    </div>
  );
};

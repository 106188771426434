import { TableProps } from "antd";
import cn from "classnames/dedupe";

import { getTrueName } from "common/helpers/string";
import { VacancyStatus } from "components/VacancyStatus";
import {
  PositionCategoryType,
  PositionType,
  VacancyType,
} from "graphql/types/types";
import {
  getPositionCategoryName,
  getVacancyPositionsName,
} from "services/store/modules/vacanciesList/getters";

type GetColumnsPayload = {
  positionCategories: PositionCategoryType[];
  vacancyPositions: PositionType[];
};

export const getColumns = ({
  positionCategories,
  vacancyPositions,
}: GetColumnsPayload): TableProps<VacancyType>["columns"] => [
  {
    title: "Категория вакансии",
    dataIndex: "position",
    key: "position",
    render: (position) => (
      <div className="nowrap">
        {getPositionCategoryName(positionCategories, position)}
      </div>
    ),
  },
  {
    title: "Вакансия",
    dataIndex: "positionId",
    key: "positionId",
    width: 200,
    render: (positionId, vacancy) => (
      <div className="nowrap vacancies-container-category-name">
        {getVacancyPositionsName(vacancyPositions, positionId) ??
          vacancy.position?.name}
      </div>
    ),
  },
  {
    title: "Торговая точка",
    dataIndex: "callCenterInfo",
    key: "callCenterInfo",
    render: (callCenterInfo) => {
      const project = callCenterInfo?.project ?? "";
      return (
        <div
          className={cn("nowrap", "replies-table-column-address")}
          title={project}
        >
          {project}
        </div>
      );
    },
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
    width: 240,
    render: (address) => (
      <div
        className={cn("nowrap", "replies-table-column-address")}
        title={address.value}
      >
        {address.value}
      </div>
    ),
  },
  {
    title: "Зарплата",
    dataIndex: "salary",
    key: "salary",
    render: (salary) => <span>{salary.to ? `${salary.to} ₽` : ""}</span>,
  },
  {
    title: "Работодатель предлагает свой транспорт",
    dataIndex: "medicalBook",
    key: "medicalBook",
    render: (medicalBook) => <span>{getTrueName(medicalBook)}</span>,
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (status) => <VacancyStatus status={status} className="nowrap" />,
  },
];

import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { Props as TableProps, Table } from "components/UI/Table";
import { VacancyStatus } from "components/VacancyStatus";
import { ROUTE } from "routes";
import {
  PositionCategoryType,
  ReplyNewClientType,
  VacancySuitableType,
  VacancyType,
} from "graphql/types/types";
import { selectPositionCategories } from "services/store/modules/vacanciesList/selectors";
import { getSessionIds, setSessionIds } from "common/utils/cookies";
import { stringifyQuery } from "common/utils/query";
import { RecommendedModeType } from "../../index";

import "./styles.scss";

type Props = {
  recommendedVacancies: VacancySuitableType[];
  vacancyId?: VacancyType["id"];
  mode?: RecommendedModeType;
  replyId?: ReplyNewClientType["id"];
};

const getDuration = (duration: number) => {
  if (duration > 60) {
    return `${(duration / 60).toFixed(1)} ч.`;
  }

  return `${duration} мин.`;
};

export const getCategoryName = (list: PositionCategoryType[], categoryId = 0) =>
  list.find(({ id }) => categoryId === id)?.name ?? "";

export const RecommendTable: React.FC<Props> = ({
  recommendedVacancies,
  vacancyId,
  mode,
  replyId,
}) => {
  const history = useHistory();
  const positionCategories = useAppSelector(selectPositionCategories);
  const columns: TableProps<VacancySuitableType>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <div className="nowrap" title={id}>
          {id}
        </div>
      ),
    },
    {
      title: "Торговая точка",
      dataIndex: "callCenterInfo",
      key: "callCenterInfo",
      render: (callCenterInfo) => {
        const { project = "" } = callCenterInfo || {};
        return (
          <div className="nowrap" title={project}>
            {project}
          </div>
        );
      },
    },
    {
      title: "Категория",
      dataIndex: "position",
      key: "position",
      render: (position) => (
        <div
          className="nowrap"
          title={getCategoryName(positionCategories, position?.categoryId)}
        >
          {getCategoryName(positionCategories, position?.categoryId)}
        </div>
      ),
    },
    {
      title: "Вакансия",
      dataIndex: "name",
      key: "name",
      width: 240,
      render: (name) => (
        <div className="nowrap recommended-container-vacansy-name" title={name}>
          {name}
        </div>
      ),
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 240,
      render: (address) => (
        <div className="nowrap" title={address?.value || ""}>
          {address?.value || ""}
        </div>
      ),
    },
    {
      title: "Общественный траспорт",
      dataIndex: "transitDuration",
      key: "transitDuration",
      width: 150,
      render: (transitDuration) => (
        <div className="nowrap" title={transitDuration}>
          {transitDuration ? getDuration(transitDuration) : "Нет информации"}
        </div>
      ),
    },
    {
      title: "Пешком",
      dataIndex: "walkingDuration",
      key: "walkingDuration",
      width: 150,
      render: (walkingDuration) => (
        <div className="nowrap" title={walkingDuration}>
          {walkingDuration ? getDuration(walkingDuration) : "Нет информации"}
        </div>
      ),
    },
    {
      title: "Зарплата",
      dataIndex: "salary",
      key: "salary",
      render: (salary) => <span>{salary.to ? `${salary.to} ₽` : ""}</span>,
    },
    {
      title: "Приоритет",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => (
        <span className="vacancies-container-status-refused">
          {priority ? "Критичный" : ""}
        </span>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status) => <VacancyStatus status={status} />,
    },
  ];

  const openedRecommendVacancy = useMemo(
    () => getSessionIds("openedRecommendVacancy"),
    []
  );

  const handleRowClick = (vacancyId: number) => {
    setSessionIds("openedRecommendVacancy", String(vacancyId));

    const searchParams = {
      fromRecommended: true,
      ...(replyId &&
        (mode === "reply" || mode === "recommended") && { fromReply: replyId }),
    };

    history.push({
      pathname: `${ROUTE.VACANCY}/${vacancyId}`,
      search: stringifyQuery(searchParams),
    });
  };

  return (
    <>
      <Table
        tableLayout="fixed"
        onRow={(vacancy) => ({
          onClick: () => handleRowClick(vacancy.id),
          className: cn({
            "recommend-table-highlighted-row": openedRecommendVacancy.includes(
              String(vacancy.id)
            ),
          }),
        })}
        dataSource={recommendedVacancies}
        columns={columns}
        rowClassName={({ id }) =>
          cn({ "recommend-table-highlighted-selected": vacancyId === id })
        }
      />
    </>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CandidateForAdaptationListResponse,
  CandidateForAdaptationResponse,
} from "./types";
import { client as apollo } from "graphql/apollo";
import { AsyncThunkConfig } from "services/store/types";
import { CANDIDATE_FOR_ADAPTATION_LIST } from "graphql/requests/query/candidateForAdaptationList";
import {
  QueryCandidateForAdaptationListArgs,
  QueryCandidateForAdaptationArgs,
} from "graphql/types/types";
import { CANDIDATE_FOR_ADAPTATION } from "graphql/requests/query/candidateForAdaptation";

export const fetchCandidateForAdaptationList = createAsyncThunk<
  CandidateForAdaptationListResponse,
  QueryCandidateForAdaptationListArgs,
  AsyncThunkConfig
>(
  "candidatesForAdaptation/fetchCandidateForAdaptationList",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<CandidateForAdaptationListResponse>({
        query: CANDIDATE_FOR_ADAPTATION_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchCandidateForAdaptation = createAsyncThunk<
  CandidateForAdaptationResponse,
  QueryCandidateForAdaptationArgs,
  AsyncThunkConfig
>(
  "candidatesForAdaptation/fetchCandidateForAdaptation",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<CandidateForAdaptationResponse>({
        query: CANDIDATE_FOR_ADAPTATION,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

import { IDirectoryTreeItem } from "services/store/modules/directory/types";

export const getAreaTreeOptions = (
  list: IDirectoryTreeItem[],
  disable = false,
  level = 0
) =>
  list.map(({ name, children, id }) => ({
    value: name,
    title: name,
    id,
    disabled: disable ? level < 2 : false,
    level,
    ...(children?.length
      ? {
          children: getAreaTreeOptions(
            children as IDirectoryTreeItem[],
            disable,
            level + 1
          ),
        }
      : {}),
  }));

import React, { useCallback } from "react";
import {
  Control,
  Controller,
  FormState,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormReset,
  useWatch,
} from "react-hook-form";
import { Moment } from "moment/moment";

import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import { FormRangePicker } from "components/UI/Form/FormRangePicker";
import { Filters } from "components/Filters";
import {
  CityType,
  DepartmentType,
  VacancySource,
  VacancyStatusesType,
} from "graphql/types/types";
import {
  filterOption,
  getSelectOptions,
  getSelectOptionsByCity,
  getSelectOptionsByName,
} from "components/UI/Select/helpers";
import {
  COUNT_CANDIDATES_OPTIONS,
  PLATFORM_VACANCIES_OPTIONS,
} from "common/const/options";
import { INITIAL_STATE_FILTERS_VACANCY } from "common/const/filters";
import { VacancyStatus as VacancyStatusType } from "graphql/types/types";
import { useAppSelector } from "services/store/store";
import { selectProfessions } from "services/store/modules/directory";
import { userList } from "services/store/modules/user/selectors";
import { getDirtFieldLength } from "./helpers";

export type VacancyFiltersState = {
  vacancyId: string;
  vacancyName?: string[]; // reactivation
  creationDateRange: [Moment | null, Moment | null];
  publishingDateRange: [Moment | null, Moment | null];
  status: VacancyStatusType[];
  countCandidate?: string; // vacancies
  city: string;
  departmentId: string;
  platforms: VacancySource[];
  managers: number[];
};

type Props = {
  loading: boolean;
  vacancyStatuses: VacancyStatusesType[];
  department: DepartmentType[];
  cities: CityType[];
  isReactivation: boolean;
  control: Control<VacancyFiltersState>;
  formState: FormState<VacancyFiltersState>;
  className?: string;
  onFilter: (filtersData: VacancyFiltersState) => void;
  handleSubmit: UseFormHandleSubmit<VacancyFiltersState, undefined>;
  reset: UseFormReset<VacancyFiltersState>;
};

export const VacancyFilters: React.FC<Props> = ({
  loading,
  vacancyStatuses,
  department,
  cities,
  control,
  formState,
  className,
  isReactivation,
  onFilter,
  handleSubmit,
  reset,
}) => {
  const professionsOptions = useAppSelector(selectProfessions);
  const managerList = useAppSelector(userList);

  const status = useWatch({
    control,
    name: "status",
  });

  const onSubmit = useCallback<SubmitHandler<VacancyFiltersState>>(
    (filtersData: VacancyFiltersState) => {
      onFilter(filtersData);
    },
    [onFilter]
  );

  const handleReset = () => {
    reset(INITIAL_STATE_FILTERS_VACANCY);
    onFilter(INITIAL_STATE_FILTERS_VACANCY);
  };

  return (
    <Filters
      className={className}
      isDirty={true}
      isError={Boolean(Object.values(formState.errors).length)}
      count={
        Object.values(formState.dirtyFields).length ||
        getDirtFieldLength(
          (formState.defaultValues || {}) as VacancyFiltersState
        )
      }
      loading={loading}
      onSubmit={handleSubmit(onSubmit)}
      onReset={handleReset}
    >
      <Controller
        name="vacancyId"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            type={"number"}
            label="ID вакансии"
            placeholder="Введите ID вакансии"
            error={fieldState.error?.message}
            {...field}
          />
        )}
      />
      {isReactivation && (
        <Controller
          name="vacancyName"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Название вакансии"
              showSearch
              placeholder="Введите название вакансии"
              optionFilterProp="children"
              filterOption={filterOption}
              options={getSelectOptionsByName(professionsOptions)}
              {...field}
            />
          )}
        />
      )}
      <Controller
        name="creationDateRange"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormRangePicker
            label="Дата создания"
            placeholder={["c", "по"]}
            format="DD-MM-YYYY"
            style={{ width: "370px" }}
            disabled={!(status.length === 0 || (status.length || 0) > 1)}
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="publishingDateRange"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormRangePicker
            label="Дата публикации"
            placeholder={["c", "по"]}
            format="DD-MM-YYYY"
            style={{ width: "370px" }}
            disabled={
              !(
                status.length === 1 && status[0] === VacancyStatusType.Published
              )
            }
            error={error?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Статус вакансии"
            placeholder="Выберите статус(ы)"
            mode="multiple"
            options={getSelectOptions(vacancyStatuses)}
            {...field}
          />
        )}
      />
      <Controller
        name="platforms"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Площадки"
            placeholder="Выберите площадку"
            mode="multiple"
            options={PLATFORM_VACANCIES_OPTIONS}
            {...field}
          />
        )}
      />
      <Controller
        name="managers"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Менеджеры"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            mode="multiple"
            error={error?.message}
            options={getSelectOptions(managerList)}
            {...field}
          />
        )}
      />
      {!isReactivation && (
        <Controller
          name="countCandidate"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Количество кандидатов"
              placeholder="Выберите диапазон"
              options={COUNT_CANDIDATES_OPTIONS}
              {...field}
            />
          )}
        />
      )}
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Город"
            showSearch
            filterOption={filterOption}
            optionFilterProp="children"
            placeholder="Выберите город"
            options={getSelectOptionsByCity(cities)}
            {...field}
          />
        )}
      />
      <Controller
        name="departmentId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Департамент"
            placeholder="Выберите департамент"
            options={getSelectOptions(department)}
            {...field}
          />
        )}
      />
    </Filters>
  );
};

import { ReplyFiltersInitialState } from "services/store/modules/replies/types";
import { VacancyFiltersState } from "components/redezign/Vacancies/components/VacancyFilters";

export const INITIAL_STATE_FILTERS_REPlY: ReplyFiltersInitialState = {
  creationDateRange: [null, null],
  status: [],
  userId: null,
  departmentId: null,
};

export const INITIAL_STATE_FILTERS_VACANCY: VacancyFiltersState = {
  vacancyId: "",
  vacancyName: [],
  creationDateRange: [null, null],
  publishingDateRange: [null, null],
  status: [],
  countCandidate: "",
  city: "",
  departmentId: "",
  managers: [],
  platforms: [],
};

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Title } from "components/UI/Title";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  cities,
  deleteRefusal,
  positions,
  refusalReasons,
  deletePosition,
  deleteCity,
} from "services/store/modules/directory";
import { RouteParams } from "common/commonTypes/types";
import { Card } from "components/UI/Card";
import { RefusalReasonType, RefusalType } from "graphql/types/types";
import { Delete } from "components/UI/icons";
import { AREAS, DECLINE_TYPES } from "common/const/dictionary";
import { DictionaryForm } from "./components/DictionaryForm";
import { Modal } from "components/UI/Modal";
import Text from "components/UI/Text";
import { Button } from "components/UI/Button";
import toaster from "components/UI/Notifications/Notification";
import { CitiesForm } from "./components/CitiesForm";

import "./styles.scss";

export const Dictionary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { type } = useParams<RouteParams>();

  const refusalReasonsList = useAppSelector(refusalReasons);
  const positionsList = useAppSelector(positions);
  const citiesList = useAppSelector(cities);

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<RefusalReasonType | null>(null);

  const listByAreaType = {
    [DECLINE_TYPES[AREAS.REPLY]]: refusalReasonsList,
    [DECLINE_TYPES[AREAS.VACANCY]]: refusalReasonsList,
    [DECLINE_TYPES[AREAS.CLAIM]]: refusalReasonsList,
    [DECLINE_TYPES[AREAS.ALL]]: refusalReasonsList,
    [AREAS.POSITIONS]: positionsList,
    [AREAS.CITIES]: citiesList,
  };

  const formByType = {
    [DECLINE_TYPES[AREAS.REPLY]]: (
      <DictionaryForm type={RefusalType.Reply} list={refusalReasonsList} />
    ),
    [DECLINE_TYPES[AREAS.VACANCY]]: (
      <DictionaryForm type={RefusalType.Vacancy} list={refusalReasonsList} />
    ),
    [DECLINE_TYPES[AREAS.CLAIM]]: (
      <DictionaryForm type={RefusalType.Claim} list={refusalReasonsList} />
    ),
    [DECLINE_TYPES[AREAS.ALL]]: (
      <DictionaryForm type={RefusalType.All} list={refusalReasonsList} />
    ),
    [AREAS.POSITIONS]: (
      <DictionaryForm type={AREAS.POSITIONS} list={positionsList} />
    ),
    [AREAS.CITIES]: <CitiesForm list={citiesList} />,
  };

  const handleOpen = (item: RefusalReasonType) => {
    setItem(item);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const getDeleteAction = () => {
    if (!item?.id) {
      return Promise.reject();
    }

    if (type === AREAS.POSITIONS) {
      return dispatch(deletePosition({ id: item.id }));
    }

    if (type === AREAS.CITIES) {
      return dispatch(deleteCity({ id: item.id }));
    }

    return dispatch(deleteRefusal({ id: item.id }));
  };

  const handleOkClick = () => {
    getDeleteAction()
      .then(() => {
        toaster.success({ title: "Успешно удалено" });
      })
      .catch(() => {
        toaster.error({ title: "Ошибка удаления" });
      });
  };

  return (
    <div className="dictionary">
      <Title type="h4">Редактирование</Title>

      <div className="dictionary-content">
        <div className="dictionary-content-items">
          {listByAreaType[type].map((item) => (
            <Card
              key={item.id}
              mode="list"
              className="dictionary-content-item"
              withBorder={true}
              iconTrigger={<Delete color="#f5222d" />}
              title={item.city || item.name || ""}
              onIconClick={() => handleOpen(item)}
            />
          ))}
        </div>
        {formByType[type]}
      </div>

      <Modal
        title="Удаление"
        open={open}
        footer={
          <div className="dictionary-modal-actions">
            <Button variant="primary" onClick={handleCancel}>
              Отмена
            </Button>
            <Button onClick={handleOkClick}>OK</Button>
          </div>
        }
        onCancel={handleCancel}
      >
        <Text>Подтверждение удаление "{item?.name || ""}"</Text>
      </Modal>
    </div>
  );
};

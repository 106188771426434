import React, { useMemo, useState } from "react";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import cn from "classnames";

import Portal from "components/UI/Portal";
import {
  Close,
  Logout,
  Setting,
  Company,
  UserCircle,
  FileTextOutlined,
  Edit,
} from "components/UI/icons";
import { IUser } from "services/store/modules/user/types";
import { UserRole } from "graphql/types/types";
import { ROLE } from "common/const/role";
import { logout } from "common/helpers/auth";
import {
  hasEditAccess,
  hasModerationAccess,
  isAdmin,
  isOwner,
} from "common/helpers/role";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { ROUTE } from "routes";
import { getActiveMenuKey } from "common/helpers/location";

import "./styles.scss";

export interface SidePanelProps {
  isVisible: boolean;
  onClose: () => void;
  user: IUser;
}

export const getPanelMenuItems = (userRole: UserRole) => [
  {
    path: "/profile/edit",
    icon: <UserCircle width={20} height={20} />,
    label: "Профиль",
    visible: true,
  },
  {
    path: isOwner(userRole) ? "/company/edit" : "/company/users",
    icon: <Company width={20} height={20} />,
    label: "Компания",
    visible: hasEditAccess(userRole),
  },
  {
    path: ROUTE.SETTINGS,
    icon: <Setting width={20} height={20} />,
    label: "Системные настройки",
    visible: isAdmin(userRole),
  },
  {
    path: "/admin-setting",
    icon: <FileTextOutlined width={20} height={20} />,
    label: "Панель администратора",
    visible: isAdmin(userRole),
  },
  {
    path: ROUTE.REPORTS,
    icon: <FileTextOutlined width={20} height={20} />,
    label: "Отчеты",
    visible: hasModerationAccess(userRole),
  },
  {
    path: ROUTE.PLANS,
    icon: <Edit width={20} height={20} color="#A6ACB9" />,
    label: "Планы",
    visible: hasEditAccess(userRole),
  },
  {
    path: "/logout",
    icon: <Logout color="#E62249" />,
    label: "Выйти",
    visible: true,
  },
];

export const SidePanel: React.FC<SidePanelProps> = ({
  isVisible,
  onClose,
  user,
}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const [selectedKey, setSelectedKey] = useState(
    getActiveMenuKey(pathname, search)
  );

  const menuItems = useMemo(() => getPanelMenuItems(user.role), [user.role]);
  const title = useMemo(
    () => menuItems.find((item) => item.path === pathname)?.label || null,
    [menuItems, pathname]
  );

  useChangeTitle({ title });

  const onClick: MenuClickEventHandler = ({ key }) => {
    if (key === "/logout") {
      logout();
      return;
    }

    setSelectedKey(getActiveMenuKey(key, search));
    history.push({ pathname: key });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <div className="sidePanel">
        <div className="sidePanel-header">
          <div>
            <div className="sidePanel-header-name">
              {`${user.firstName || ""} ${user.secondName || ""}`}
            </div>
            <div className="sidePanel-header-role">
              {ROLE[user.role] || "Роль не указана"}
            </div>
          </div>
          <span className="sidePanel-header-close-icon" onClick={onClose}>
            <Close color="#0057AC" height={20} width={20} />
          </span>
        </div>
        <div className="sidePanel-separator" />
        <div className="sidePanel-content">
          <Menu
            selectedKeys={[selectedKey]}
            className="sidePanel-menu"
            onClick={onClick}
          >
            {menuItems.map(({ path, icon, label, visible }) =>
              visible ? (
                <Menu.Item
                  key={path}
                  icon={icon}
                  className={cn("sidePanel-menu-item", {
                    "sidePanel-menu-item__logout": path === "/logout",
                  })}
                >
                  {label}
                </Menu.Item>
              ) : null
            )}
          </Menu>
        </div>
      </div>
    </Portal>
  );
};

import { UserRole } from "graphql/types/types";

export const ROLE: Record<UserRole, string> = {
  [UserRole.Admin]: "Суперадминистратор",
  [UserRole.Manager]: "Менеджер",
  [UserRole.Owner]: "Владелец",
  [UserRole.Moderator]: "Супермодератор",
  [UserRole.Hrbp]: "HR BP",
  [UserRole.EmployeeCc]: "Сотрудник КЦ",
  [UserRole.EmployeeMa]: "Сотрудник МА",
};

const ADMIN = [UserRole.Admin];

export const ROLES: Record<UserRole, UserRole[]> = {
  ADMIN,
  HRBP: [...ADMIN, UserRole.Hrbp],
  MODERATOR: [...ADMIN, UserRole.Moderator],
  EMPLOYEE_CC: [...ADMIN, UserRole.EmployeeCc],
  EMPLOYEE_MA: [...ADMIN, UserRole.EmployeeMa],
  OWNER: [...ADMIN, UserRole.Owner],
  MANAGER: [...ADMIN, UserRole.Manager],
};

import React from "react";

export const NewReplies: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 9C15.75 7.66498 15.3541 6.35994 14.6124 5.2499C13.8707 4.13987 12.8165 3.27471 11.5831 2.76382C10.3497 2.25292 8.99252 2.11925 7.68314 2.3797C6.37377 2.64015 5.17104 3.28303 4.22703 4.22703C3.28303 5.17104 2.64015 6.37377 2.3797 7.68314C2.11925 8.99252 2.25292 10.3497 2.76382 11.5831C3.27471 12.8165 4.13987 13.8707 5.2499 14.6124C6.35994 15.3541 7.66498 15.75 9 15.75M2.70007 6.75H15.3001M2.70007 11.25H9.00007M8.68353 2.25C7.42003 4.27471 6.75018 6.6134 6.75018 9C6.75018 11.3866 7.42003 13.7253 8.68353 15.75M9.375 2.25C10.6642 4.31625 11.25 6.657 11.25 9M13.5 10.5V15.75M13.5 15.75L11.25 13.5M13.5 15.75L15.75 13.5"
        stroke="#0057AC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";

import { Tooltip } from "components/UI/Tooltip";
import { IUser } from "services/store/modules/user/types";
import { getManagersByIds } from "components/VacancyInfo/helpers";
import { Button, Variant } from "components/UI/Button";

type ButtonProps = {
  text: string;
  className: string;
  visible?: boolean;
  disabled?: boolean;
  variant?: Variant;
  onClick: () => void;
};

interface Props {
  actionButtons: ButtonProps[];
  managersIds?: number[] | null;
  managerList?: IUser[];
  className?: string;
}

const renderButton = ({ text, ...props }: ButtonProps) => (
  <Button key={text} {...props}>
    {text}
  </Button>
);

export const VacancyActions: React.FC<Props> = ({
  actionButtons,
  managersIds,
  managerList,
}) => {
  return (
    <>
      {actionButtons.map((button, ind) => {
        if (!button.visible) return null;

        if (ind === 0 && managersIds?.length) {
          return (
            <Tooltip
              key="tooltip"
              placement="bottomLeft"
              title={getManagersByIds(managersIds, managerList).map(
                (managerName) => (
                  <div key={managerName}>{managerName}</div>
                )
              )}
            >
              {renderButton(button)}
            </Tooltip>
          );
        }
        return renderButton(button);
      })}
    </>
  );
};

import React from "react";
import classNames from "classnames/dedupe";

import { Title } from "components/UI/Title";
import { DailyPlanType, PositionCategoryType } from "graphql/types/types";
import { getDateFormat } from "common/utils/date";

import "./styles.scss";

type Props = {
  className?: string;
  title?: string;
  dailyPlanList: DailyPlanType[];
  positionCategories: PositionCategoryType[];
  activeCategory: number | null;
  onClick: (categoryId: number) => void;
};

const TITLE = "План и факт на день";
const rows = [
  {
    name: <div style={{ width: "160px" }}>Направление</div>,
    isHead: true,
  },
  {
    name: "Вакансий",
    planField: "peopleCount",
  },
  {
    name: "Критично",
    planField: "criticalPeopleCount",
  },
  {
    name: `Закрыто за ${getDateFormat(new Date(), "DD.MM.YY")}`,
    planField: "criticalPeopleCountClosed",
  },
];

export const PlanTable: React.FC<Props> = ({
  dailyPlanList,
  positionCategories,
  activeCategory,
  title,
  onClick,
}) => (
  <>
    <Title type="h5">{title || TITLE}</Title>

    <table className="plan-table">
      <tbody>
        {rows.map(({ name, planField, isHead }, ind) => (
          <tr key={ind}>
            <td>{name}</td>
            {dailyPlanList.map((planItem) => (
              <td
                key={planItem.categoryId}
                className={classNames({
                  "plan-table-td-active":
                    isHead && activeCategory === planItem.categoryId,
                })}
              >
                {planField ? planItem[planField] : null}
                {isHead ? (
                  <div
                    className="plan-table-name"
                    onClick={() => onClick(planItem.categoryId)}
                  >
                    {positionCategories.find(
                      (item) => item.id === planItem.categoryId
                    )?.name ?? ""}
                  </div>
                ) : null}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

import * as Yup from "yup";

export const schema = Yup.object().shape({
  phone: Yup.string().required("Необходимо ввести телефон"),
  email: Yup.string()
    .email("Некорректный формат эл.почты")
    .required("Необходимо ввести эл.почту"),
  firstName: Yup.string().required("Необходимо ввести имя"),
  secondName: Yup.string().required("Необходимо ввести фамилию"),
  city: Yup.string().required("Необходимо ввести город"),
});

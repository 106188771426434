import { stringifyQuery } from "common/utils/query";
import { getUrl } from "common/utils/routes";

const AVITO_AUTH_HOST = "avito.ru/oauth";
const SCOPE =
  "job:applications,job:write,job:cv,job:vacancy,job:negotiations,messenger:read,messenger:write,user_balance:read,user_operations:read,user:read,items:info,items:apply_vas,stats:read";

export const getAvitoAuthUrl = (clientId: string, state: string) => {
  const query = stringifyQuery({
    response_type: "code",
    client_id: clientId,
    scope: SCOPE,
    state,
  });

  return getUrl(AVITO_AUTH_HOST, query);
};

import React from "react";
import { useAppSelector } from "services/store/store";
import { authInfo } from "services/store/modules/auth";
import { ContentLayout } from "layouts/ContentLayout";
import { Reports } from "components/redezign/Reports";

export const ReportsPage: React.FC = () => {
  const authData = useAppSelector(authInfo);

  return (
    <ContentLayout
      centralColumn={{
        content: authData?.role ? <Reports userRoles={authData.role} /> : null,
      }}
    />
  );
};

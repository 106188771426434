import React from "react";

import { CandidatesTable } from "components/CandidatesTable";
import { SearchResumeFilters } from "../SearchResumeFilters";
import { useAppSelector } from "services/store/store";
import { selectCandidateSearchData } from "services/store/modules/candidates/selectors";

import "./styles.scss";

export const SearchResume: React.FC = () => {
  const candidatesList = useAppSelector(selectCandidateSearchData);

  return (
    <div className="search-resume">
      <SearchResumeFilters />
      <CandidatesTable mode="recommended" candidatesList={candidatesList} />
    </div>
  );
};

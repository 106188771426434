import React from "react";

import { Recommended } from "components/Recommended";
import { ReplyInfo } from "./components/ReplyInfo";
import { IUser } from "services/store/modules/user/types";
import {
  ReplyNewClientType,
  ReplyNewStatuses,
  VacancyType,
} from "graphql/types/types";
import { isStatusValid } from "./helpers";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  reply: ReplyNewClientType;
  authData: IUser;
}

export const Reply: React.FC<Props> = ({ reply, vacancy, authData }) => {
  return (
    <div className="reply-container">
      <ReplyInfo
        className="reply-container-info"
        authData={authData}
        vacancy={vacancy}
        reply={reply}
      />

      {reply.candidate && isStatusValid(reply.status) ? (
        <Recommended
          candidate={reply.candidate}
          vacancyId={vacancy.id}
          mode="reply"
          replyId={reply.id}
          statusInWork={reply.status !== ReplyNewStatuses.Processed}
        />
      ) : null}
    </div>
  );
};

import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { Claim } from "components/redezign/Claim";
import { useParams } from "react-router-dom";
import { RouteParams } from "common/commonTypes/types";
import {
  selectClaim,
  selectClaimData,
} from "services/store/modules/claimsList/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { getClaim } from "services/store/modules/claimsList/actions";
import { CLAIMS_CATEGORY_TYPE } from "common/const/category";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";

export const ClaimPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const claim = useAppSelector(selectClaimData);
  const { loading, error } = useAppSelector(selectClaim);

  const { id: claimId } = useParams<RouteParams>();

  const title = claim?.category
    ? `Заявка на ${CLAIMS_CATEGORY_TYPE[claim.category]}`
    : null;

  useChangeTitle({ title });

  useEffect(() => {
    dispatch(getClaim({ id: claimId }));
  }, [dispatch, claimId]);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {claim && !loading ? (
              <Claim claim={claim} />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Ошибка загрузки заявки"
              />
            )}
          </>
        ),
      }}
    />
  );
};

import { UserType, VacancyRelevance, VacancyType } from "graphql/types/types";
import { HHState } from ".";
import { getContacts, getDescription, getSalary } from "../helpers";
import { Platform } from "common/const/platform";
import { PublicationStatus } from "common/const/avito";
import { getNationalities } from "../RabotutForm/helpers";
import { IDirectoryTreeItem } from "services/store/modules/directory/types";

const getLeafDirectoryItemById = (
  elements: IDirectoryTreeItem[],
  targetId: string
): { id: string; name: string } => {
  for (const element of elements) {
    if (!element.children || element.children.length === 0) {
      if (element.id === targetId) {
        return { id: element.id, name: element.name };
      }
    } else {
      const found = getLeafDirectoryItemById(
        element.children as IDirectoryTreeItem[],
        targetId
      );
      if (found && found.id !== "") {
        return found;
      }
    }
  }
  return { id: "", name: "" };
};

export const getInitialState = (
  vacancy: VacancyType,
  professionalRoleDirectory: IDirectoryTreeItem[],
  areasOptions: IDirectoryTreeItem[]
): HHState => ({
  name: vacancy.name ?? "",
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  salaryFrom: vacancy.salary.from ?? 0,
  salaryTo: vacancy.salary.to ?? 0,
  experience: vacancy.additionalHh?.experience ?? "",
  managerId: vacancy?.manager?.id,
  driverLicense: vacancy.additionalHh?.driverLicense ?? [],
  languages: vacancy.additionalHh?.languages ?? "",
  area: getLeafDirectoryItemById(
    areasOptions,
    vacancy.additionalHh?.area ?? ""
  ),
  professionalRoles: getLeafDirectoryItemById(
    professionalRoleDirectory,
    vacancy.additionalHh?.professionalRoles
      ? vacancy.additionalHh?.professionalRoles[0]
      : ""
  ),
  acceptHandicapped: vacancy.additionalHh?.acceptHandicapped ?? false,
  acceptKids: vacancy.additionalHh?.acceptKids ?? false,
  acceptTemporary: vacancy.additionalHh?.acceptTemporary ?? false,
  employment: undefined,
  workScheduleByDays: [],
});

export const getPayload = (
  {
    name,
    experience,
    salaryFrom,
    salaryTo,
    managerId,
    description,
    shortDescription,
    terms,
    claims,
    driverLicense,
    languages,
    area,
    professionalRoles,
    acceptHandicapped,
    acceptKids,
    acceptTemporary,
    employment,
    workScheduleByDays,
  }: HHState,
  managerList: UserType[]
) => ({
  description: getDescription({ description, shortDescription, terms, claims }),
  employment_form: { id: employment },
  name,
  salary: getSalary(salaryFrom, salaryTo),
  contacts: getContacts(managerId, managerList),
  experience: { id: experience },
  area: { id: area.id },
  professional_roles: [professionalRoles.id],
  accept_handicapped: acceptHandicapped,
  accept_kids: acceptKids,
  accept_temporary: acceptTemporary,
  ...(languages ? { languages } : {}),
  ...(driverLicense.length ? { driver_license: driverLicense } : {}),
  ...(workScheduleByDays.length
    ? { work_schedule_by_days: workScheduleByDays }
    : {}),
});

export const getButtontext = (
  publishItems: Platform[],
  publicationStatus?: PublicationStatus
) => {
  const name =
    publicationStatus === PublicationStatus.Active
      ? "Обновить"
      : "Опубликовать";

  return publishItems.includes("avito") ? `${name} и продолжить` : name;
};
export const getPayloadUpdateVacancy = (
  {
    acceptHandicapped,
    acceptKids,
    acceptTemporary,
    area,
    driverLicense,
    experience,
    languages,
    name,
    professionalRoles,
    workScheduleByDays,
  }: HHState,
  vacancy: VacancyType
) => ({
  id: vacancy.id,
  form: {
    additionalHh: {
      acceptHandicapped,
      acceptKids,
      acceptTemporary,
      area: area?.id || "",
      driverLicense,
      experience,
      languages,
      name,
      professionalRoles: professionalRoles?.id ? [professionalRoles.id] : [],
    },
    address: vacancy.address,
    description: vacancy.description ?? "",
    claims: vacancy.claims ?? "",
    terms: vacancy.terms ?? "",
    salary: vacancy.salary,
    contacts: vacancy.contacts,
    callCenterInfo: vacancy.callCenterInfo,
    employerId: vacancy.employer?.id ?? 0,

    employmentId: vacancy.employment?.id ?? 0,
    age: vacancy.age,
    metro: vacancy.metro,
    additionalAvito: vacancy.additionalAvito,
    city: vacancy.city,
    kladrId: vacancy.kladrId,
    region: vacancy.region,
    subdivision: vacancy.subdivision,
    counterparty: vacancy.counterparty,
    route: vacancy.route,
    priority: vacancy.priority,
    tradePoint: vacancy.tradePoint,
    shiftStart: vacancy.shiftStart,
    shiftEnd: vacancy.shiftEnd,
    peopleCount: vacancy.peopleCount,
    schedule: vacancy.schedule,
    reason: vacancy.reason,
    responsiblePerson: vacancy.responsiblePerson,
    phonePresence: vacancy.phonePresence,
    medicalBook: vacancy.medicalBook,
    citizenshipId: vacancy.citizenship?.id,
    rate: vacancy.rate,
    nationalityId: getNationalities(vacancy.nationalities || []),
    relevance: vacancy.relevance
      ? VacancyRelevance.Relevant
      : VacancyRelevance.NotRelevant,
    positionId: vacancy.positionId ?? 0,
    platformTags: vacancy.platformTags,
    name: vacancy.name,
    showContacts: vacancy.showContacts,
    shortDescription: vacancy.shortDescription,
    tagKey: vacancy.tag?.key || null,
  },
});

import React from "react";
import { Controller, Control } from "react-hook-form";

import { Form } from "components/UI/Form";
import { FormInput } from "components/UI/Form/FormInput";
import { FormInputMask } from "components/UI/Form/FormInputMask";
import { PHONE_MASK } from "common/const/masks";
import { Filters } from "components/Filters";

import "./styles.scss";

type Props = {
  control: Control<FiltersState>;
  isDirty: boolean;
  isError: boolean;
  onReset: () => void;
  onSubmit: () => Promise<void>;
};

export type FiltersState = {
  firstName: string;
  secondName: string;
  phone: string;
  badge: string;
  id: string;
};

export const INITIAL_STATE: FiltersState = {
  firstName: "",
  secondName: "",
  phone: "",
  badge: "",
  id: "",
};

export const CandidatesFilters: React.FC<Props> = ({
  control,
  isDirty,
  onSubmit,
  onReset,
}) => {
  return (
    <Filters isDirty={isDirty} onSubmit={onSubmit} onReset={onReset}>
      <Form>
        <Controller
          name="secondName"
          control={control}
          render={({ field }) => (
            <FormInput label="Фамилия" placeholder="Фамилия" {...field} />
          )}
        />

        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <FormInput label="Имя" placeholder="Имя" {...field} />
          )}
        />

        <Controller
          name="badge"
          control={control}
          render={({ field }) => (
            <FormInput label="ID бейдж" placeholder="ID бейдж" {...field} />
          )}
        />

        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <FormInput label="ID CRM" placeholder="ID CRM" {...field} />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <FormInputMask
              placeholder="+7"
              label="Номер телефона"
              mask={PHONE_MASK}
              {...field}
            />
          )}
        />
      </Form>
    </Filters>
  );
};

import React from "react";
import cn from "classnames";

import { Filters as FiltersIcon } from "components/UI/icons";
import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import { FiltersCount } from "components/UI/FiltersCount";
import { useToggle } from "common/hooks/useToggle";

import "./styles.scss";

type Props = {
  count?: number;
  isDirty?: boolean;
  isError?: boolean;
  loading?: boolean;
  className?: string;
  onReset?: () => void;
  resetForm?: () => void;
  onSubmit?: () => Promise<void>;
};

export const Filters: React.FC<Props> = ({
  count,
  isDirty,
  isError,
  loading,
  className,
  onReset,
  onSubmit,
  children,
}) => {
  const [open, toggleOpen] = useToggle(false);

  const handleClick = async () => {
    await onSubmit?.();
    toggleOpen();
  };

  return (
    <div className={cn(className, "filters")}>
      <FiltersCount count={count} onOpen={toggleOpen} onReset={onReset} />

      <Modal
        className="filtersModal"
        open={open}
        size="small"
        onCancel={toggleOpen}
        footer={null}
      >
        <div className="filtersModal-filters">
          <FiltersIcon />
        </div>

        {children}

        <Button
          className="filtersModal-btn"
          loading={loading}
          disabled={isError}
          onClick={handleClick}
        >
          Применить
        </Button>
        <Button
          className="filtersModal-btn"
          variant="primary"
          disabled={!isDirty}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onReset?.();
          }}
        >
          Очистить все фильтры
        </Button>
      </Modal>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import { ReportName, titleByReportName } from "common/const/reports";
import { Title } from "components/UI/Title";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { IUser } from "services/store/modules/user/types";
import { FormInput } from "components/UI/Form/FormInput";
import FormTextArea from "components/UI/Form/FormTextArea";
import { sendReport } from "services/store/modules/reports/actions";
import { schema } from "./validation";
import { useAppDispatch } from "services/store/store";
import toaster, {
  Notification,
} from "components/UI/Notifications/Notification";

import "./styles.scss";

type Props = {
  open: boolean;
  managerList: IUser[];
  usersById: Record<number, IUser>;
  reportName: ReportName;
  reportUrl?: string;
  onCancel: () => void;
};

type FormState = {
  managerIds: number[];
  reportText: string;
  reportUrl: string;
};

export const SendReportModal: React.FC<Props> = ({
  open,
  reportName,
  managerList,
  usersById,
  reportUrl = "",
  onCancel,
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const {
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: {
      managerIds: [],
      reportText: `Добрый день. Во вложении отчет «${titleByReportName[reportName]}»`,
      reportUrl: "",
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    setError(null);
    const { reportText, reportUrl, managerIds } = getValues();

    dispatch(
      sendReport({
        reportText,
        reportUrl,
        emails: managerIds.map((managerId) => usersById[managerId].email),
      })
    )
      .then(() => {
        toaster.success({ title: "Письмо отправлено" });
        onCancel();
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => setValue("reportUrl", reportUrl), [reportUrl, setValue]);

  return (
    <Modal
      className="sendReportModal"
      open={open}
      size="small"
      onCancel={onCancel}
      footer={null}
    >
      <Title type="h3">Отправить отчет по E-mail</Title>

      <Controller
        name="managerIds"
        control={control}
        rules={{ required: true }}
        render={({
          field,
          formState: {
            errors: { managerIds },
          },
        }) => (
          <FormSelect
            label="Кому"
            showSearch
            mode="multiple"
            required={true}
            optionFilterProp="children"
            error={errors.managerIds?.message || managerIds?.message}
            filterOption={filterOption}
            options={managerList.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="reportText"
        control={control}
        render={({ field }) => <FormTextArea label="Комментарий" {...field} />}
      />

      <Controller
        name="reportUrl"
        control={control}
        render={({ field }) => <FormInput label="Ссылка" {...field} />}
      />

      <Button
        className="sendReportModal-btn"
        loading={loading}
        disabled={Boolean(Object.values(errors).length)}
        onClick={handleSubmit}
      >
        Отправить
      </Button>

      {error && <Notification type="error" title={error} />}
    </Modal>
  );
};

import { createSlice, combineReducers } from "@reduxjs/toolkit";

import { getInitialState } from "../../helpers";

const coldSearchListSlice = createSlice({
  name: "coldSearchList",
  initialState: getInitialState<[]>(),
  reducers: {},
});

const coldSearchVacancySlice = createSlice({
  name: "coldSearchVacancySlice",
  initialState: getInitialState(),
  reducers: {},
});

export const coldSearchReducer = combineReducers({
  list: coldSearchListSlice.reducer,
  vacancy: coldSearchVacancySlice.reducer,
});

import React from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { SystemSetting } from "components/settings/SystemSettings";

export const SystemSettingPage: React.FC = () => (
  <ContentLayout
    centralColumn={{
      content: <SystemSetting />,
    }}
  />
);

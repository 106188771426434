import { createSelector } from "reselect";

import { PAGINATION } from "common/const/pagination";
import { RootState } from "services/store/rootReducer";

const selectReplies = (state: RootState) => state.repliesNew;

export const selectRepliesList = createSelector(
  selectReplies,
  (state) => state.repliesList
);

export const selectRepliesListMeta = createSelector(
  selectRepliesList,
  (state) => state.meta
);
export const selectRepliesListPagination = createSelector(
  selectRepliesListMeta,
  (state) =>
    state
      ? {
          offset: state.offset,
          limit: state.limit,
        }
      : PAGINATION.REPLIES
);

export const selectRepliesListData = createSelector(
  selectRepliesList,
  (state) => state.data
);
export const selectRepliesListLoading = createSelector(
  selectRepliesList,
  (state) => state.loading
);

export const selectReply = createSelector(
  selectReplies,
  (state) => state.reply.data
);
export const selectReplyLoading = createSelector(
  selectReplies,
  (state) => state.reply.loading
);

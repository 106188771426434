import React, { useMemo, useCallback } from "react";
import { useHistory } from "react-router";
import cn from "classnames";

import { useViewMode } from "common/hooks/useViewMode";
import { Card } from "components/UI/Card";
import { ViewModeToggle } from "components/ViewModeToggle";
import { ROUTE } from "routes";
import { ReportName } from "common/const/reports";
import { getReportCards } from "./helpers";
import { UserRole } from "graphql/types/types";

import "./styles.scss";

interface Props {
  userRoles: UserRole;
}

export const Reports: React.FC<Props> = ({ userRoles }) => {
  const history = useHistory();

  const { mode, handleChangeMode } = useViewMode("grid");

  const handleClick = useCallback(
    (reportName: ReportName) => {
      history.push(`${ROUTE.REPORT}/${reportName}`);
    },
    [history]
  );

  const reportCards = useMemo(
    () => getReportCards(handleClick, userRoles),
    [handleClick, userRoles]
  );
  return (
    <div className="reports">
      <div className="reports-header">
        <ViewModeToggle mode={mode} onChangeMode={handleChangeMode} />
      </div>

      <div className={cn("reports-cards", `reports-cards--${mode}`)}>
        {reportCards.map(({ title, icon, isVisible, onClick }) => (
          <>
            {isVisible ? (
              <Card
                className={cn("reports-card", `reports-card--${mode}`)}
                mode={mode}
                title={title}
                icon={icon}
                onClick={onClick}
              />
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as apollo } from "../../../../graphql/apollo";
import {
  PublishVacancyRequestResponse,
  UpdateVacancyRequestResponse,
  VacancyPayload,
  VacancyRequestResponse,
  HideVacancyRequestResponse,
  ChangeVisibleVacancyResponse,
} from "./types";
import { AsyncThunkConfig } from "services/store/types";
import { VACANCY } from "graphql/requests/query/vacancy";
import { UPDATE_VACANCY } from "graphql/requests/mutation/updateVacancy";
import {
  MutationPublishVacancyArgs,
  MutationUpdateVacancyArgs,
  MutationHideVacancyArgs,
  MutationDeclineVacancyArgs,
  VacancyTypeResponse,
  MutationChangeVacancyStatusArgs,
  MutationDeleteVacancyArgs,
  MutationChangeVisibleVacancyArgs,
  MutationTransferVacancyArgs,
  MutationPublishVacancyOnAvitoArgs,
  VacancyType,
  MutationUpdateVacancyOnAvitoArgs,
  MutationManageVacancyReactivationArgs,
} from "graphql/types/types";
import { PUBLISH_VACANCY } from "graphql/requests/mutation/publishVacancy";
import { HIDE_VACANCY } from "graphql/requests/mutation/hideVacancy";
import { DECLINE_VACANCY } from "graphql/requests/mutation/declineVacancy";
import { CHANGE_VACANCY_STATUS } from "graphql/requests/mutation/changeVacancyStatus";
import { DELETE_VACANCY } from "graphql/requests/mutation/deleteVacancy";
import { CHANGE_VISIBLE_VACANCY } from "graphql/requests/mutation/changeVisibleVacancy";
import { TRANSFER_VACANCY } from "graphql/requests/mutation/transferVacancy";
import { PUBLISH_VACANCY_ON_AVITO } from "graphql/requests/mutation/publishVacancyOnAvito";
import { UPDATE_VACANCY_ON_AVITO } from "graphql/requests/mutation/updateVacancyOnAvito";
import { MANAGE_VACANCY_REACTIVATION } from "graphql/requests/mutation/ manageVacancyReactivation";

export const fetchVacancy = createAsyncThunk<
  VacancyRequestResponse,
  VacancyPayload,
  AsyncThunkConfig
>("vacancy/fetchVacancy", async ({ vacancyId }, { rejectWithValue }) => {
  try {
    const res = await apollo.query<VacancyRequestResponse>({
      query: VACANCY,
      variables: {
        id: vacancyId,
      },
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateVacancy = createAsyncThunk<
  UpdateVacancyRequestResponse,
  MutationUpdateVacancyArgs,
  AsyncThunkConfig
>("vacancy/updateVacancy", async ({ form, id }, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<UpdateVacancyRequestResponse>({
      mutation: UPDATE_VACANCY,
      variables: {
        form,
        id,
      },
    });
    if (!res.data) {
      return rejectWithValue("No data received from server");
    }
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const publishVacancy = createAsyncThunk<
  PublishVacancyRequestResponse,
  MutationPublishVacancyArgs,
  AsyncThunkConfig
>("vacancy/publishVacancy", async ({ id }, { rejectWithValue }) => {
  try {
    const res = await apollo.query<PublishVacancyRequestResponse>({
      query: PUBLISH_VACANCY,
      variables: {
        id,
      },
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const hideVacancy = createAsyncThunk<
  HideVacancyRequestResponse,
  MutationHideVacancyArgs,
  AsyncThunkConfig
>("vacancy/hideVacancy", async ({ id }, { rejectWithValue }) => {
  try {
    const res = await apollo.query<HideVacancyRequestResponse>({
      query: HIDE_VACANCY,
      variables: {
        id,
      },
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const declineVacancy = createAsyncThunk<
  VacancyTypeResponse | null | undefined,
  MutationDeclineVacancyArgs,
  AsyncThunkConfig
>("vacancy/declineVacancy", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<VacancyTypeResponse>({
      mutation: DECLINE_VACANCY,
      variables: payload,
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeVacancyStatus = createAsyncThunk<
  VacancyTypeResponse | null | undefined,
  MutationChangeVacancyStatusArgs,
  AsyncThunkConfig
>("vacancy/changeVacancyStatus", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<VacancyTypeResponse>({
      mutation: CHANGE_VACANCY_STATUS,
      variables: payload,
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeVacancyVisible = createAsyncThunk<
  ChangeVisibleVacancyResponse | null | undefined,
  MutationChangeVisibleVacancyArgs,
  AsyncThunkConfig
>("vacancy/changeVacancyVisible", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<ChangeVisibleVacancyResponse>({
      mutation: CHANGE_VISIBLE_VACANCY,
      variables: payload,
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const deleteVacancy = createAsyncThunk<
  VacancyTypeResponse | null | undefined,
  MutationDeleteVacancyArgs,
  AsyncThunkConfig
>("vacancy/deleteVacancy", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<VacancyTypeResponse>({
      mutation: DELETE_VACANCY,
      variables: payload,
    });

    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const transferVacancy = createAsyncThunk<
  VacancyTypeResponse | null | undefined,
  MutationTransferVacancyArgs,
  AsyncThunkConfig
>("vacancy/transferVacancy", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<VacancyTypeResponse>({
      mutation: TRANSFER_VACANCY,
      variables: payload,
    });
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const publishVacancyOnAvito = createAsyncThunk<
  VacancyType | null | undefined,
  MutationPublishVacancyOnAvitoArgs,
  AsyncThunkConfig
>("vacancy/publishVacancyOnAvito", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<{ publishVacancyOnAvito: VacancyType }>({
      mutation: PUBLISH_VACANCY_ON_AVITO,
      variables: payload,
    });
    return res.data?.publishVacancyOnAvito;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const updateVacancyOnAvito = createAsyncThunk<
  VacancyType | null | undefined,
  MutationUpdateVacancyOnAvitoArgs,
  AsyncThunkConfig
>("vacancy/publishVacancyOnAvito", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<{ updateVacancyOnAvito: VacancyType }>({
      mutation: UPDATE_VACANCY_ON_AVITO,
      variables: payload,
    });
    return res.data?.updateVacancyOnAvito;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const manageVacancyReactivation = createAsyncThunk<
  VacancyType | null | undefined,
  MutationManageVacancyReactivationArgs,
  AsyncThunkConfig
>("vacancy/manageVacancyReactivation", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.mutate<{ manageVacancyReactivation: VacancyType }>(
      {
        mutation: MANAGE_VACANCY_REACTIVATION,
        variables: payload,
      }
    );
    return res.data?.manageVacancyReactivation;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

import React from "react";
import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { CallTimePicker } from "./components/CallTimePicker";
import { SmsTextInput } from "./components/SmsText";
import { MultiVacancyDistribution } from "./components/MultiVacancyDistribution";
import { useAppSelector } from "services/store/store";
import { companySettings } from "services/store/modules/company";

import "./styles.scss";

export const AdminSetting: React.FC = () => {
  const company = useAppSelector(companySettings);

  return (
    <div className="admin-container">
      <Title type="h4">Панель администратора</Title>
      {company && (
        <RowLayout
          leftComponent={
            <>
              <CallTimePicker />
              <SmsTextInput />
            </>
          }
          leftSpan={12}
          centerComponent={<MultiVacancyDistribution />}
          centerSpan={12}
        />
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames/dedupe";

import { Table, Props as TableProps } from "components/UI/Table";
import {
  CandidateCommentType,
  CandidateType,
  PageInfoType,
  Source,
  VacancyType,
} from "graphql/types/types";
import { Tooltip } from "components/UI/Tooltip";
import { Copy } from "components/UI/icons";
import { useCopyToClipboard } from "common/hooks/useCopyToClipboard";
import { FieldName } from "./components/FieldName";
import { FieldStatus } from "./components/FieldStatus";
import { FieldPhone } from "./components/FieldPhone";
import { CommentColumn } from "./components/CommentColumn";
import { getSessionIds } from "common/utils/cookies";
import { FieldPosition } from "./components/FieldPosition";
import { SOURCE_TYPE } from "common/const/source";
import { CandidateModals } from "../CandidateModals";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { setErrorCalls } from "services/store/modules/calls";
import { fetchCandidateSuitableList } from "services/store/modules/candidates/action";
import { PAGINATION } from "common/const/pagination";
import { authInfo } from "services/store/modules/auth";
import { isOwner } from "common/helpers/role";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { CandidateStatus } from "components/CandidateStatus";

import "./styles.scss";

export type CandidatesTableMode = "vacancy" | "recommended" | "reply" | "claim";

type Props = {
  className?: string;
  tableClassName?: string;
  mode?: CandidatesTableMode;
  vacancyId?: VacancyType["id"];
  candidatesList: CandidateType[];
  emptyText?: string;
  callsError?: string | null;
  loading?: boolean;
  meta?: PageInfoType;
  handleRowClick?: (id: CandidateType["id"]) => void;
  onChangePagination?: (payload: PaginationPayload) => void;
};

export const CandidatesTable: React.FC<Props> = ({
  className,
  tableClassName,
  vacancyId,
  mode = "vacancy",
  candidatesList,
  handleRowClick,
  emptyText,
  callsError,
  loading,
  meta,
  onChangePagination,
}) => {
  const dispatch = useAppDispatch();

  const [, copy] = useCopyToClipboard();

  const { role } = useAppSelector(authInfo) || {};

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isDisableByRole = role ? isOwner(role) : false;

  const openedCandidatesIds = useMemo(
    () => getSessionIds("openedVacancyCandidateIds"),
    []
  );

  const columns: TableProps<CandidateType>["columns"] = [
    ...(mode !== "reply"
      ? [
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
            className: "vacancy-container-id",
            render: (id) => (
              <Tooltip
                placement="bottomLeft"
                title={
                  <div
                    className="vacancy-container-copyTooltip"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="vacancy-container-copy"
                      onClick={() => copy(id)}
                    >
                      <Copy />
                    </div>
                    {id}
                  </div>
                }
              >
                <div className="vacancy-container-short">{id}</div>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      className: "candidates-table-name",
      render: (secondName, candidate) =>
        mode === "recommended" ? (
          secondName
        ) : (
          <FieldName
            type="secondName"
            name={secondName}
            candidate={candidate}
            disable={isDisableByRole}
          />
        ),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      className: "candidates-table-name",
      render: (firstName, candidate) =>
        mode === "recommended" ? (
          firstName
        ) : (
          <FieldName
            type="firstName"
            name={firstName}
            candidate={candidate}
            disable={isDisableByRole}
          />
        ),
    },
    {
      title: "Отчество",
      dataIndex: "patronymic",
      key: "patronymic",
      className: "candidates-table-name",
      render: (patronymic, candidate) =>
        mode === "recommended" ? (
          patronymic
        ) : (
          <FieldName
            type="patronymic"
            name={patronymic}
            candidate={candidate}
            disable={isDisableByRole}
          />
        ),
    },
    {
      title: "Возраст",
      dataIndex: "age",
      key: "age",
      render: (age) => <div>{age ?? ""}</div>,
    },
    {
      title: "Адрес проживания",
      dataIndex: "actualAddress",
      key: "actualAddress",
      className: "candidates-table-address",
      render: (actualAddress, candidate) =>
        mode !== "recommended" ? (
          <FieldName
            type="actualAddress"
            address={actualAddress}
            candidate={candidate}
            mode={mode}
            disable={isDisableByRole}
          />
        ) : (
          <div className="vacancy-container-short">
            {actualAddress?.value || ""}
          </div>
        ),
    },
    {
      title: "Телефон",
      dataIndex: "contacts",
      key: "contacts",
      className: "vacancy-container-phone",
      render: (contacts, candidate) => (
        <FieldPhone contacts={contacts} candidate={candidate} />
      ),
    },
    ...(mode !== "reply"
      ? [
          {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            className: "candidates-table-status",
            render: (status, candidate) =>
              mode === "vacancy" ? (
                <FieldStatus
                  key={candidate.id}
                  candidate={candidate}
                  vacancyId={vacancyId}
                  setIsModalOpen={setIsModalOpen}
                  disable={isDisableByRole}
                />
              ) : (
                <CandidateStatus
                  className="vacancy-container-short"
                  status={status}
                />
              ),
          },
        ]
      : []),
    ...(mode === "vacancy"
      ? [
          {
            title: (
              <div className="vacancy-container-duration">
                <div>Расстояние, мин.</div>
                <div>Общественный траспорт</div>
              </div>
            ),
            dataIndex: "transitDuration",
            key: "transitDuration",
            className: "vacancy-container-duration",
          },
          {
            title: (
              <div className="vacancy-container-duration">
                <div className="vacancy-container-duration-sub">
                  Расстояние, мин.
                </div>
                <div className="vacancy-container-duration-main">Пешком</div>
              </div>
            ),
            dataIndex: "walkingDuration",
            key: "walkingDuration",
            className: "vacancy-container-duration",
          },
        ]
      : []),

    ...(mode !== "reply"
      ? [
          {
            title: "Ожидаемая ЗП",
            dataIndex: "salaryExpectation",
            key: "salaryExpectation",
            render: (salaryExpectation) => (
              <div>{salaryExpectation ? `${salaryExpectation} ₽` : ""}</div>
            ),
          },
          {
            title: "Справочно: зарплата на прошлом месте работы",
            dataIndex: "lastShiftRate",
            key: "lastShiftRate",
            className: "vacancy-container-duration",
            render: (lastShiftRate) => (
              <div>{lastShiftRate ? `${lastShiftRate} ₽` : ""}</div>
            ),
          },
        ]
      : []),

    ...(mode === "reply"
      ? [
          {
            title: "Источник",
            dataIndex: "source",
            key: "source",
            render: (source: Source) => SOURCE_TYPE[source],
          },
        ]
      : []),
    {
      title: "Комментарий",
      dataIndex: "comments",
      key: "comments",
      render: (comments: CandidateCommentType[] | null, candidate) => (
        <CommentColumn
          comments={comments}
          candidate={candidate}
          vacancyId={vacancyId}
          canAddComment={mode === "vacancy" || mode === "reply"}
          disable={isDisableByRole}
        />
      ),
    },
    {
      title: "Вакансии, которые рассматривает кандидат",
      dataIndex: "positionsNew",
      key: "positionsNew",
      className: "candidates-table-positions",
      render: (positionsNew, candidate) =>
        positionsNew ? (
          <FieldPosition
            mode={mode}
            positions={positionsNew}
            candidate={candidate}
            disable={isDisableByRole}
          />
        ) : undefined,
    },
    {
      title: "Гражданство",
      dataIndex: "russianCitizenship",
      key: "russianCitizenship",
      render: (russianCitizenship) => (
        <div>{russianCitizenship ? `РФ` : "Не РФ"}</div>
      ),
    },

    ...(mode !== "reply"
      ? [
          {
            title: "Наличие Автомобиля",
            dataIndex: "haveCar",
            key: "haveCar",
            render: (haveCar) => <div>{haveCar ? `Есть` : "Нет"}</div>,
          },
          {
            title: "Наличие прав категории",
            dataIndex: "driverLicense",
            key: "driverLicense",
            render: (driverLicense) => (
              <>
                {driverLicense && driverLicense.length ? (
                  <div className="vacancy-container-short">
                    {driverLicense.join(", ")}
                  </div>
                ) : null}
              </>
            ),
          },
        ]
      : []),
  ];

  const handleClose = () => {
    dispatch(setErrorCalls({ error: null }));
    setIsModalOpen(false);
  };

  const handleClick = () => {
    dispatch(
      fetchCandidateSuitableList({
        pagination: PAGINATION.CANDIDATES,
        filter: { vacancyId: String(vacancyId) },
      })
    );
    dispatch(setErrorCalls({ error: null }));
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (callsError) {
      setIsModalOpen(true);
    }
  }, [callsError]);

  return (
    <>
      <div className={cn("candidates-table", className)}>
        <Table
          className={tableClassName}
          draggableScroll
          dataSource={candidatesList}
          columns={columns}
          loading={loading}
          onRow={(candidate) => ({
            onClick: () => {
              handleRowClick?.(candidate.id);
            },
            className: cn({
              "candidates-table-row--highlighted":
                mode === "vacancy" &&
                openedCandidatesIds.includes(candidate.id),
              "candidates-table-row--didnt-work":
                mode === "vacancy" && candidate.didntWork,
            }),
          })}
          locale={{ emptyText }}
        />

        {meta && (
          <Pagination
            className="candidates-table-pagination"
            total={meta.total}
            limit={meta.limit}
            offset={meta.offset}
            onChange={onChangePagination}
          />
        )}
      </div>
      <CandidateModals
        isOpen={isModalOpen}
        onClick={callsError ? handleClick : undefined}
        onClose={handleClose}
        title={callsError ? "Звонок невозможен" : undefined}
        text={callsError ?? undefined}
        mode="vacancy"
      />
    </>
  );
};

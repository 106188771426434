import React from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { Dictionary } from "components/settings/Dictionary";
import { useAppSelector } from "services/store/store";
import { isDictionaryLoading } from "services/store/modules/directory";
import { NotificationStatus } from "components/UI/Notifications/Notification";

export const DictionaryPage: React.FC = () => {
  const loading = useAppSelector(isDictionaryLoading);

  return (
    <ContentLayout
      centralColumn={{
        content: loading ? (
          <NotificationStatus
            loading={loading}
            error={null}
            notFoundText="Словарь не найден"
          />
        ) : (
          <Dictionary />
        ),
      }}
    />
  );
};

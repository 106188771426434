import React from "react";
import cn from "classnames/dedupe";

import "./styles.scss";

interface Props {
  countOpen?: number | null;
  countClose?: number | null;
  className?: string;
}

export const PeopleCount: React.FC<Props> = ({
  countOpen,
  countClose,
  className,
}) => {
  return (
    <div className={cn(className, "people-count")}>
      <div className="column">
        <div>Потребность</div>
        <div>{countOpen}</div>
      </div>
      <div className="column">
        <div>Закрыто</div>
        <div>{countClose}</div>
      </div>
    </div>
  );
};

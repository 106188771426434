import Cookies, { CookieAttributes } from "js-cookie";

export const getSessionIds = (name: string): string[] => {
  const ids = getCookie(name);

  return ids ? JSON.parse(ids) : [];
};

export const setSessionIds = (name: string, id: string) => {
  const sessionIds = getSessionIds(name);
  const ids = new Set(sessionIds);

  ids.add(id);

  setCookie(name, JSON.stringify(Array.from(ids)), {
    expires: 0.5,
  });
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const setCookie = (
  name: string,
  value: string,
  attributes?: CookieAttributes
) => {
  Cookies.set(name, value, attributes);
};

export const removeCookie = (name: string, options?: CookieAttributes) => {
  Cookies.remove(name, options);
};

export const initializeDomainLinker = () => {
  const host = window.location.hostname;
  const cookieDomain = "auto";
  const cookieFlags = "secure; samesite=none"; // Необходимо для работы на HTTPS
  const cookieConfig = `domains=${host}; cookieflags=${cookieFlags}; cookieSecure=${
    cookieFlags.includes("secure") ? "true" : "false"
  }`;

  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=${cookieDomain}; path=/`;
  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=.${cookieDomain}; path=/`;
};

export interface JwtPayload {
  exp: number;
  iat: number;
  sub: string;
}

export const parseJwt = (token: string): JwtPayload => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};
